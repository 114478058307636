import * as React from 'react';
import { connect } from 'react-redux';
import { loginUser } from '../actions/session';
import LoginForm from '../components/login/login-form';
import { setTitle } from '../actions/title';
import { setActiveYard } from '../actions/context';
import { AlertPanel, AlertPanelTheme, AlertStyleKind } from '../components/common';

type ReduxActionDispatcher = Function;

function mapStateToProps(state): ILoginPageState {
    return {
        session: state.session
    };
}

function mapDispatchToProps(dispatch): ILoginPageDispatch {
    return {
        login: () => dispatch(loginUser()),
        setTitle: (options) => dispatch(setTitle(options)),
        setActiveYard: (args) => dispatch(setActiveYard(args))
    };
}

// these are "optional" as they are automatically defined or provided above by the map to props
export interface ILoginPageState {
    session?: ISessionState;
}

interface ISessionState {
    loginProgressMessage?: string
    errorDetails?: string
    hasError: boolean
    isLoading: boolean
}

interface ILoginPageDispatch {
    setActiveYard?: (args) => void;
    setTitle?: ReduxActionDispatcher;
    login?: ReduxActionDispatcher;
}

interface ILoginPageProps {
    isTokenExpired?: boolean;
    children?: any;
}

type LoginPageProps = ILoginPageProps & ILoginPageState & ILoginPageDispatch;

@connect(mapStateToProps, mapDispatchToProps)
export default class LoginPage extends React.Component<LoginPageProps, any> {
    componentDidMount() {
        const { setTitle, setActiveYard } = this.props;
        setTitle && setTitle({
            app: "Login",
            document: "TWU Yard App"
        });
        setActiveYard && setActiveYard(null);
    }
    render() {
        const { session, login, isTokenExpired } = this.props;
        if (!session || !login) return <>Loading...</>;
        return (
            <>
            {isTokenExpired && <AlertPanel kind={AlertStyleKind.Bootstrap} theme={AlertPanelTheme.Info}>
                Your current session has expired. Please login again
            </AlertPanel>}
            <LoginForm onSubmit={login as any} />
            </>
        );
    }
};
