import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import session, { ISessionState } from "./session";
import title, { ITitleState } from "./title";
import yards from "./yards";
import yardDetails from "./yard-details";
import tasks from "./tasks";
import memberDetails from "./member-details";
import contactDetails from "./contact-details";
import nonMemberDetails from "./nonmember-details";
import taskDetails from "./task-details";
import visit, { IVisitState } from "./visit";
import context, { IContextState } from "./context";
import { IReducerBranchStateImmutable } from './utils';


export interface IReducerRoot {
    yardDetails: IReducerBranchStateImmutable<{}>;
    session: ISessionState
    visit: IVisitState
    context: IContextState
    title: ITitleState
}
const rootReducer = combineReducers<Readonly<IReducerRoot>>({
    title,
    session,
    form: formReducer,
    yards,
    yardDetails,
    tasks,
    memberDetails,
    contactDetails,
    nonMemberDetails,
    taskDetails,
    visit,
    context
});

export default rootReducer;