import { 
    SET_TITLE
} from '../constants';

export interface ITitleState {
    app: null | string
}
const INITIAL_STATE: ITitleState = {
    app: null
};



export default function titleReducer(state = INITIAL_STATE, action: { type: string, payload?: any }) {
    
    switch (action.type) {
        case SET_TITLE:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}