import {
    IChangeAction,
    IImmutableObjectWithChanges
} from "./twu-contracts";
import { getDataManagerAsync } from "./data-manager";
import { EditableObjectType } from "./change-utils";

export async function savePendingChanges<T extends (IImmutableObjectWithChanges & { id: string|number })>(obj: T, type: EditableObjectType, changes: IChangeAction[]): Promise<T> {
    const manager = await getDataManagerAsync();
    return manager.storePendingChangesAsync(obj, type, changes);
}