import * as React from "react";

import { connect } from 'react-redux';
import { setTitle } from '../actions/title';
import {
    InputGroup,
    FormControl,
    Button
} from "react-bootstrap";
import { findMembersAsync } from '../api/yards';
import { MemberList } from '../components/member-list';
import groupBy = require("lodash.groupby");
import { Link } from 'react-router-dom';
import debounce = require("lodash.debounce");
import { ReactNode, useState, useEffect } from 'react';
import { useIsMounted } from './yards-page';
import useRouter from 'use-react-router';
import { parse, stringify } from 'query-string';
import { Icons } from "../components/icons";

function mapStateToProps(state) {
    return {
        routing: state.routing
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setTitle: (args) => dispatch(setTitle(args))
    };
}

export interface IMemberSearchPageProps {
    routing: any;
    setTitle: (args) => void;
}


function MemberSearchPage(props: IMemberSearchPageProps) {
    const isMounted = useIsMounted();
    
    
    const { history, location}= useRouter();
    const parsed = parse(location.search);
    
    const [textFilter, setTextFilter] = useState<string | null>(null);
    const [ memberMatches, setMemberMatches ] = useState<null | {}>(null);
    const [isLoading, setIsLoading] = useState(false);


    const updateHash = debounce((filter: string | null) => {
        if (isMounted.current) {

            const search = { ...parsed, search: filter }; 
            history.replace({ pathname: location.pathname, search: stringify(search) });
            
        }
    }, 250);
    const onClearTextFilter = (e) => {
        updateHash("");
        setTextFilter(null);
            setMemberMatches(null);
        
    }
    const onSearchWithFilter = (e) => {
        
        setIsLoading(true);
        updateHash(textFilter);
        if (!textFilter) {
            setMemberMatches(null);
            return;
        }
        findMembersAsync(textFilter).then(r => {
            setIsLoading(false)
            setMemberMatches(groupBy(r, m => m.yardDesc) );
        });
    }
    const updateTextFilter = (e) => {
        setTextFilter(e.target.value);
    }
    const onSearchKeyPress = (e) => {
        if (e.key == "Enter") {
            onSearchWithFilter(e);
        }
    }

    useEffect(() => {
        props.setTitle({ app: "Member Search" });
        
        
        if (parsed.search) {
            const text = parsed.search;
            const s =typeof(text) == 'string' ? text : text[0];
            setTextFilter(s);
            setIsLoading(true);
        
            findMembersAsync(s).then(r => {
                setIsLoading(false)
                setMemberMatches(groupBy(r, m => m.yardDesc));
            });
        }
    }, []);
    return <div>
        <div className="member-search-panel">
            <InputGroup>
                <FormControl type="text" placeholder="Search members by name" className="yard-text-filter-input inline-filter" value={textFilter || ""} onChange={updateTextFilter} onKeyPress={onSearchKeyPress} />
                <InputGroup.Button>
                    <Button bsStyle="primary" disabled={(textFilter || "").length == 0} onClick={onSearchWithFilter}>{Icons.SEARCH}</Button>
                    <Button bsStyle="danger" disabled={(textFilter || "").length == 0} onClick={onClearTextFilter}>{Icons.CLOSE}</Button>
                </InputGroup.Button>
            </InputGroup>
        </div>
        {(() => {
            if (isLoading) {
                return <div className="alert alert-info">
                    Loading matches ...
                    </div>;
            } else {
                if (memberMatches) {
                    const matches: ReactNode[] = [];
                    const yards = Object.keys(memberMatches).sort();
                    for (const yard of yards) {
                        //If we get here, the children of the group must be at least size >= 1 whose
                        //yard ids shoud all be the same
                        const yardId = memberMatches[yard][0].yardId;
                        matches.push(<div key={yard}>
                            <h3><Link to={`/yard/${yardId}`}>{yard}</Link></h3>
                            <MemberList members={memberMatches[yard]} />
                        </div>);
                    }
                    return <div>{matches}</div>;
                } else {
                    return <div className="alert alert-info">
                        <p>Enter a search query above to search members by name</p>
                        <p><strong>NOTE:</strong> If you have not downloaded any yards, this search function does nothing</p>
                    </div>;
                }
            }
        })()}
    </div>;

}



export default connect(mapStateToProps, mapDispatchToProps)(MemberSearchPage);