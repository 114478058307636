import * as React from "react";
import { connect } from "react-redux";
import { EditableField } from "../components/editable-field";
import { MemberDetailsPageComponent } from "../components/pages/member-details";
import { DelayLoadContainer } from '../components/delay-load-container';
import { setTitle } from '../actions/title';
import { fetchMemberDetails, fetchTasksForMember } from '../actions/member';
import { IMember, TaskStatus, ITask } from '../api/twu-contracts';
import { EditableObjectType } from "../api/change-utils";
import { collectItemChangesAsync } from "../actions/editable";
import { assignNewTask } from "../actions/tasks";

import * as DisplayUtils from "../utils/display";
import { push } from "../api/auto-push";
import { notifySuccess, notifyError } from '../utils/notify';
import { ISessionState } from '../reducers/session';
import useRouter from 'use-react-router';
import { useEffect } from 'react';

function mapStateToProps(state): IMemberDetailsPageState {
    return {
        memberDetails: state.memberDetails,
        session: state.session
    };
}

function mapDispatchToProps(dispatch): IMemberDetailsPageDispatch {
    return {
        setTitle: (args) => dispatch(setTitle(args)),
        fetchDetails: (id) => dispatch(fetchMemberDetails(id)),
        fetchTasks: (args) => dispatch(fetchTasksForMember(args)),
        assignNewTask: (args) => dispatch(assignNewTask(args))
    };
}

interface IMemberDetailsPageState {

    session?: ISessionState;
    memberDetails: any;
}

interface IMemberDetailsPageDispatch {
    setTitle: (args) => void;
    fetchDetails: (id) => void;
    fetchTasks: (args) => void;
    assignNewTask: (args) => void;
}

type IMemberDetailsPageProps = IMemberDetailsPageState & IMemberDetailsPageDispatch;


function MemberDetailsPage({session, fetchDetails, fetchTasks, assignNewTask, setTitle, memberDetails}: IMemberDetailsPageProps) {
    const router = useRouter<{ id: string }>();


    const recordId = router.match.params.id;
    
    
    if (!session || !session.user || !session.token) return <>No session</>;

    const uid = session.user.details.userid;
    const user = session.user.username;
    const token = session.token;
        

    useEffect(() => {
        setTitle({ app: "Member Details" });
        fetchDetails(recordId);
        fetchTasks({ id: parseInt(recordId, 10), statusFilter: TaskStatus.NotComplete });
    }, [recordId])


    function onSaveChanges(editables: EditableField[], callback: (success: any, saveError?: Error) => void): void {
        const member: IMember = memberDetails.model.member;
        collectItemChangesAsync<IMember>({
            editableObject: member,
            editableFields: editables,
            type: EditableObjectType.Member,
            idSelector: obj => obj.id
        }).then(res => {
            callback(res);
        }).catch(err => {
            callback(null, err);
        });
    }
    function onFilterStatus(status: TaskStatus | null) {
        fetchTasks({ id: recordId, statusFilter: status });
    }
    function onAssignNewTask(task: ITask) {
        assignNewTask({
            task: task,
            onComplete: (savedTask, error) => {
                if (savedTask != null) {
                    notifySuccess("Assign Task", "Task saved", DisplayUtils.DEFAULT_UI_NOTIFICATION_SETTINGS);
                    push({ token });
                } else if (error != null) {
                    notifyError("Assign Task", `Failed to save new task ${error.message}`, DisplayUtils.DEFAULT_UI_NOTIFICATION_SETTINGS);
                }
            }
        });
    }
    

    function renderModel(model: any): JSX.Element {
        return <MemberDetailsPageComponent model={model}
            session={token!}
            currentUserId={uid}
            currentUserDisplayName={user}
            onFilterStatusChange={onFilterStatus}
            onSaveChanges={onSaveChanges}
            onAssignNewTask={onAssignNewTask} />;
    }

    return <DelayLoadContainer
        name="Member Details"
        branch={memberDetails}
        onRenderModel={renderModel} />;

}

export default connect(mapStateToProps, mapDispatchToProps)(MemberDetailsPage)