

export interface IReducerAction<TType, TPayload> {
    type: TType;
    payload?: TPayload;
}

export type GenericReducerAction =  IReducerAction<string, any>;

export type ReducerFunction<T> = (state: IReducerBranchStateImmutable<T>, action: GenericReducerAction) => IReducerBranchStateImmutable<T>;

export interface IReducerBranchState<T> {
    model: T | null;
    hasError: boolean;
    errorDetails: Error|null;
    isLoading: boolean;
}

export type IReducerBranchStateImmutable<T> = Readonly<IReducerBranchState<T>>;

export function setupInitialState<T>() : IReducerBranchStateImmutable<T> {
    return {
        model: null,
        hasError: false,
        errorDetails: null,
        isLoading: false
    };
}

export interface IReducerActionMergeLifecycle<TImmutable, TArg> {
    beforeMerge?: (currentState: IReducerBranchStateImmutable<TImmutable>, arg: TArg) => IReducerBranchStateImmutable<TImmutable>;
    afterMerge?: (currentState: IReducerBranchStateImmutable<TImmutable>, arg: TArg) => IReducerBranchStateImmutable<TImmutable>;
}

export interface IReducerActionBranch<TImmutable, TArg> extends IReducerActionMergeLifecycle<TImmutable, TArg> {
    key: string;
}

export interface IReducerConfiguration<T> {
    initialState: IReducerBranchStateImmutable<T>;
    pending: IReducerActionBranch<T, GenericReducerAction>;
    error: IReducerActionBranch<T, Error>;
    success: IReducerActionBranch<T, GenericReducerAction>;
    default?: (currentState: IReducerBranchStateImmutable<T>, action: GenericReducerAction) => IReducerBranchStateImmutable<T>;
}

export function buildReducerFunction<T>(config: IReducerConfiguration<T>): ReducerFunction<T> {
    return (state = config.initialState, action: { type: string, payload?: any }) => {
        switch (action.type) {
            case config.error.key:
                {
                    let newState = state;
                    if (config.error.beforeMerge != null) {
                        newState = config.error.beforeMerge(state, action.payload);
                    }
                    newState = { 
                        ...state,
                        hasError: true,
                        isLoading: false,
                        errorDetails: action.payload
                    };

                    if (config.error.afterMerge != null) {
                        newState = config.error.afterMerge(state, action.payload);
                    }
                    return newState;
                }
            case config.pending.key:
                {
                    let newState = state;
                    if (config.pending.beforeMerge != null) {
                        newState = config.pending.beforeMerge(state, action);
                    }
                    newState = {
                        ...state,
                        hasError: false,
                        isLoading: true
                    };

                    if (config.pending.afterMerge != null) {
                        newState = config.pending.afterMerge(state, action);
                    }
                    return newState;
                }
            case config.success.key:
                {
                    let newState = state;
                    if (config.success.beforeMerge != null) {
                        newState = config.success.beforeMerge(state, action);
                    }
                    newState = { ...state,
                        model: action.payload,
                        hasError: false,
                        isLoading: false
                    };
                    
                    if (config.success.afterMerge != null) {
                        newState = config.success.afterMerge(state, action);
                    }
                    return newState;
                }
            default:
                {
                    if (config.default != null) {
                        return config.default(state, action);
                    } else {
                        return state;
                    }
                }
        }
    };
}