import * as React from "react";
import { IMember, ITask, TaskStatus, ITaskDetails } from "../../api/twu-contracts";
import { DisplayField } from "../display-field";
import { EditableFieldContainer, EditableContainerSaveFunc } from "../editable-field-container";
import { EditDataType, buildEditableField, ADDRESS_PROPERTY_SET } from "../editable-field";
import { TaskList } from "../task-list";
import * as ChangeUtils from "../../api/change-utils";
import * as DisplayUtils from "../../utils/display";
import { TaskDetails } from "../task-details";
import { push } from "../../api/auto-push";
import { ReactNode } from 'react';
import { BottomDrawer, ModalContent } from '../modal-common';
import { Icons } from "../icons";
import { BsLoadSpinner, ContentSection, ContentSectionDisplayStyle } from "../common";

interface IMemberDetailsPageComponentProps {
    model: any;
    session: string;
    currentUserId: number;
    currentUserDisplayName: string;
    onFilterStatusChange: (status: TaskStatus|null) => void;
    onSaveChanges: EditableContainerSaveFunc;
    onAssignNewTask: (task: ITask) => void;
}

export class MemberDetailsPageComponent extends React.Component<IMemberDetailsPageComponentProps, any> {
    private fnOpenTaskModal: (e) => void;
    private fnCloseTaskModal: (e?) => void;
    private fnTaskSaved: (task) => void;
    private fnRequestPush: () => void;
    constructor(props) {
        super(props);
        this.fnOpenTaskModal = this.openTaskModal.bind(this);
        this.fnCloseTaskModal = this.closeTaskModal.bind(this);
        this.fnTaskSaved = this.onTaskSaved.bind(this);
        this.fnRequestPush = this.onRequestPush.bind(this);
        this.state = {
            isCreatingNewTask: false,
            newTaskDetails: null
        };
    }
    private onRequestPush() {
        push({ token: this.props.session });
    }
    private openTaskModal(e) {
        this.setState({
            isCreatingNewTask: true,
            newTaskDetails: {
                //instruct the UI to treat this as a new instance
                assignedToMember: this.props.model.member,
                title: DisplayUtils.getFullName(this.props.model.member), // `${DisplayUtils.getFullName(this.props.model.member)}; ${this.props.currentUserDisplayName}`,
                description: null,
                status: TaskStatus.New,
                dueOn: DisplayUtils.utcNowAtTimeString(9, 0)
            } 
        });
    }
    private closeTaskModal(e?) {
        this.setState({ isCreatingNewTask: false, newTaskDetails: null });
    }
    private onTaskSaved(task: ITaskDetails) {
        const { onAssignNewTask } = this.props;
        let newTask: ITask = {
            id: task.id,
            forMember: task.assignedToMember.id,
            title: task.title,
            description: task.description,
            dueOn: task.dueOn,
            status: task.status,
            changes: [],
            notes: []
        }
        if (task.assignedToUser) {
            newTask.assignedToUser = `${task.assignedToUser}`;
        }
        onAssignNewTask(newTask);
        this.closeTaskModal();
    }
    render(): JSX.Element {
        const { model, onFilterStatusChange, onSaveChanges } = this.props;
        //const { model } = this.state;
        const member: IMember = model.member;
        const tasks: ITask[] = model.tasks;
        const taskStatus: TaskStatus = model.taskStatus;
        //Build our list of editable fields
        const editableFields: ReactNode[] = [];
        const changeDict = ChangeUtils.collectAttachedPendingChanges(member);
        editableFields.push(buildEditableField(member, ADDRESS_PROPERTY_SET, "editAddress", "icon", "address", "Address", changeDict, EditDataType.Address));
        editableFields.push(buildEditableField(member, "phone", "editPhone", "icon", "phone", "Phone", changeDict));
        editableFields.push(buildEditableField(member, "mobile", "editMobile", "icon", "mobile", "Mobile", changeDict));
        editableFields.push(buildEditableField(member, "email", "editEmail", "icon", "mail", "Email", changeDict));
        

        return (
            <div className="container-fluid">
                <br/>
                <ContentSection displayStyle={ContentSectionDisplayStyle.Fieldset} title={DisplayUtils.getFullName(member)} iconKind="icon" iconClass="user">
                    <div className="well">
                        <div className="form-horizontal">
                            <DisplayField iconKind="icon" iconClass="tag" label="Member Number" value={member.memberNumber} />
                            <EditableFieldContainer autoCommit={true} onSaveChanges={onSaveChanges} requestPush={this.fnRequestPush}>
                                {editableFields}
                            </EditableFieldContainer>
                            <DisplayField iconKind="icon" iconClass="dollar" label="Arrears" value={member.arrears} />
                            <DisplayField iconKind="icon" iconClass="dollar" label="Previous Arrears" value={member.arrearsPrv} />
                            <DisplayField iconKind="icon" iconClass="dollar" label="YTD" value={member.ytd} />
                        </div>
                    </div>
                </ContentSection>
                {this.state.isCreatingNewTask&&<BottomDrawer open={this.state.isCreatingNewTask}>
                    <ModalContent>
                            <TaskDetails enableActions={true} model={this.state.newTaskDetails} onSaveNew={this.fnTaskSaved} onCancel={this.fnCloseTaskModal} currentUserId={this.props.currentUserId} />
                    </ModalContent>
                </BottomDrawer>}
                <ContentSection displayStyle={ContentSectionDisplayStyle.Fieldset} title="Assigned Tasks" iconKind="icon" iconClass="ok">
                    {tasks != null 
                    ? <TaskList tasks={tasks} showFilterUI={true} onFilterStatusChange={onFilterStatusChange} statusFilter={taskStatus} />
                    : <BsLoadSpinner message="Loading Assigned Tasks" />}
                </ContentSection>
                <ContentSection displayStyle={ContentSectionDisplayStyle.Fieldset} title="Actions">
                    <button type="button" className="btn btn-primary" onClick={this.fnOpenTaskModal}>{Icons.TICK} Create Task</button>
                </ContentSection>
            </div>
        );
    }
}