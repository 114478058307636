import { OUR_ERROR_REGEX, attemptLocalLogin, getLocalEncryptionKey, persistDetails } from '../store/persistence';
import * as Sentry from "@sentry/browser";
import { IApiContext } from './twu-contracts';
import { callApi, getFullUrl } from './call';
const jwt = require('jwt-simple');

const LOGIN_ERR_MSG = `
  The username or password you have entered is invalid.
`;

interface LogOnResult {
    token: string;
}

interface INormalUserLogin {
    username: string;
    password: string;
}

function serialize(data) {
    return Object.keys(data).map((keyName) => encodeURIComponent(keyName) + '=' + encodeURIComponent(data[keyName])).join('&');
}

function getEncryptionKey(token: IApiContext): Promise<string> {
    return callApi<string>(token, '/api/offlineKey', 'GET');
}

async function performRemoteLogin(args: INormalUserLogin) {
    const loginBody = { userName: args.username, password: args.password };
    const endpoint = getFullUrl('/api/login');
    const loginErrMessage = LOGIN_ERR_MSG;
    const r = await fetch(endpoint, {
        body: serialize(loginBody),
        method: 'POST',
        headers: {
            'Accept': 'application/json, *.*',
            'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Requested-With': 'XMLHttpRequest'
        }
    });

    if (!r.ok) {
        throw new Error(loginErrMessage + " (112)");
    }

    const txt = await r.json();
    let res;
    //Step 2: JWT unpacking
    // txt will contain the JWT
    const context: IApiContext = { token: txt.token };
    try {
        const userInfo = jwt.decode(context.token, null, true);
        if (!userInfo && !userInfo.username) {
            throw new Error(LOGIN_ERR_MSG + '(106)');
        }
        res = {
            token: context.token,
            user: {
                username: userInfo.username,
                details: userInfo,
                key: context
            }
        };
    } catch (e) {
        throw new Error(LOGIN_ERR_MSG + '(107)');
    }

    const key = await getEncryptionKey(res);
    
    res.user.key = key;
    persistDetails(args.username, args.password, res);

    return res;
}

async function loginCommon(args: INormalUserLogin): Promise<any> {
    const localLoginFunc = () => attemptLocalLogin(args.username, args.password);
    const remoteLoginFunc = () => performRemoteLogin(args);
    try {
        console.log('Attempting regular (remote) login');
        const res = await remoteLoginFunc();

        return res;
    } catch (e) {
        // If it's because we gave bad credentials or anything not suggesting the 
        // endpoint was unreachable (this error is legit), rethrow. The key giveaway
        // will be the error message ends with a numerical code.
        if (e instanceof Error && e.message.match(OUR_ERROR_REGEX)) {
            throw e;
        }

        // Otherwise fall back to local login
        console.log('Exception due to un-reachable endpoint. Assuming offline. Falling-back to local login');

        // Slight code copypasta since attemptLocalLogin already does this, but what we want to throw
        // something different than "No encryption key (116)" because if we're in this state then we're definitely
        // offline and we don't have a means to start a local login attempt.
        const encryptedKey = getLocalEncryptionKey(args.username);
        if (!encryptedKey) {
            throw new Error("Could not login. You are either not online or the endpoint is down");
        }

        const result = await localLoginFunc();
        return result;
    }
}

/**
 * 
 */
export async function login(userName: string, password: string): Promise<any> {
    const res = await loginCommon({ username: userName, password: password });
    console.log("Establishing user context for Sentry tracking", res);
    Sentry.setUser({
        name: res.username,
        email: res.details?.email
    });
    return res;
}