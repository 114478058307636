import { DataManager } from "./data-manager";
import { push, pull } from "./sync";
import { IApiContext } from "./twu-contracts";

/**
 * The SyncAgent class brokers synchronisation between the app and the remote sync endpoint
 */
export class SyncAgent {
    private manager: DataManager;
    constructor(manager: DataManager) {
        this.manager = manager;
    }
    /**
     * Pushes all pending changes. If there's nothing to push, null is returned
     * @param token The API token
     */
    public push(token: IApiContext): Promise<any> {
        return this.manager.collectChanges().then(changes => {
            return push(token, {
                Changes: changes
            });
        });
    }
    public pull(token: IApiContext): Promise<any> {
        return pull(token, {});
    }
}