import { IdNamePair } from '../api/twu-contracts';
import * as Constants from '../constants';

export interface IContextState {
	activeYard: null | IdNamePair;
}

const INITIAL_STATE: IContextState = {
	activeYard: null
};

export default function contextReducer(state = INITIAL_STATE, action: { type: string; payload?: IdNamePair }) {
	switch (action.type) {
		case Constants.SET_ACTIVE_YARD:
			return {
				activeYard: action.payload,
			};
		default:
			return state;
	}
}
