import * as Constants from "../constants";
import { setupInitialState, buildReducerFunction, IReducerBranchStateImmutable, IReducerConfiguration } from "./utils";
import { ITaskListModelImmutable } from "../api/twu-contracts";

const INITIAL_STATE: IReducerBranchStateImmutable<ITaskListModelImmutable> = setupInitialState<ITaskListModelImmutable>();

const config: IReducerConfiguration<ITaskListModelImmutable> = {
    initialState: INITIAL_STATE,
    pending: {
        key: Constants.FETCH_TASKS_PENDING
    },
    error: {
        key: Constants.FETCH_TASKS_ERROR
    },
    success: {
        key: Constants.FETCH_TASKS_SUCCESS
    }
};

const tasksReducer = buildReducerFunction(config);

export default tasksReducer;