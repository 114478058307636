import {
    IApiContext,
    IPullOperationOptions,
    IPushOperationOptions,
    IPushResult,
    ISyncPayload
} from "./twu-contracts";
import { getDataManagerAsync } from "./data-manager";
import { callApi } from "./call";
import * as uuid from "node-uuid";
import * as Sentry from "@sentry/browser";

export async function pull(token: IApiContext, options: IPullOperationOptions): Promise<ISyncPayload> {
    // This id is to easily correlate logged exceptions on both sides of the call boundary
    const syncId = uuid.v4();
    console.log(`Sync id for this pull operation is: ${syncId}`);
    let payload: ISyncPayload | undefined;
    try {
        Sentry.addBreadcrumb({
            message: "Starting /api/pull request",
            data: {
                token: token.token
            }
        });
        payload = await callApi<ISyncPayload>(token, "/api/pull", "POST", options, {
            ["x-sync-id"]: syncId
        });
    } catch (e) {
        Sentry.withScope(scope => {
            scope.setTag("Operation", "pull");
            scope.setTag("SyncRequestId", syncId);
            Sentry.captureException(e);
        });
        throw e;
    }
    const manager = await getDataManagerAsync();
    const saveSuccess = await manager.update(payload, true);
    if (saveSuccess === true) {
        manager.markNewPullDate();
        return payload;
    }
    throw new Error("Failed to save pull result (118)");
}

function emptyPushResult(): null {
    return null;
}

/**
 * Pushes all pending changes specified. If there's nothing to push, null is returned
 * @param token 
 * @param options 
 */
export async function push(token: IApiContext, options: IPushOperationOptions): Promise<IPushResult|null> {
    if (options.Changes.length == 0) {
        console.warn("No pending changes to push");
        return emptyPushResult();
    } else {
        // This id is to easily correlate logged exceptions on both sides of the call boundary
        const syncId = uuid.v4();
        console.log(`Sync id for this push operation is: ${syncId}`);
        let payload: IPushResult | undefined;
        try {
            Sentry.addBreadcrumb({
                message: "Starting /api/push request",
                data: {
                    token: token.token
                }
            });
            payload = await callApi<IPushResult>(token, "/api/push", "POST", options, {
                ["x-sync-id"]: syncId
            });
        } catch (e) {
            Sentry.addBreadcrumb({
                message: "/api/push request failed",
                data: {
                    changes: JSON.stringify(options.Changes)
                }
            })
            Sentry.withScope(scope => {
                scope.setTag("Operation", "push");
                scope.setTag("SyncRequestId", syncId);
                Sentry.captureException(e);
            });
            throw e;
        }
        const manager = await getDataManagerAsync();
        const saveSuccess = await manager.update(payload, false);
        if (saveSuccess === true) {
            const clearSuccess = await manager.clearPendingChanges();
            if (clearSuccess === true) {
                manager.markNewPushDate();
                return payload;
            }
            throw new Error("Failed to clear pending changes after successful push (120)");
        }
        throw new Error("Failed to save push result (119)");
    }
}