import * as React from "react";
import { connect } from "react-redux";
import {parse, stringify} from 'query-string';
import { IYardQueryOptions, IYardListModelImmutable, SortDirection, YardSortType } from "../api/twu-contracts";
import { YardsPageComponent } from "../components/pages/yards";
import { setTitle } from '../actions/title';
import { fetchYards } from '../actions/yards';
import { DelayLoadContainer } from '../components/delay-load-container';
import useRouter from "use-react-router";
import { useRef, useEffect } from 'react';
import { logInfo } from "../utils/logger";

function mapStateToProps(state) {
    return {
        yards: state.yards,
        routing: state.routing
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setTitle: (args) => dispatch(setTitle(args)),
        fetchYards: (args?: IYardQueryOptions) => dispatch(fetchYards(args))
    };
}

interface IYardsPageProps {
    yards: any;
    routing: any;
    setTitle: (args) => void;
    fetchYards: (args?: IYardQueryOptions) => void;
}

export function useIsMounted() {
    
    // keep track of 
    const mountedRef = useRef(false);

    useEffect(() => {
        mountedRef.current = true;
        return () => { mountedRef.current = false;}
    }, []);

    return mountedRef;
}




function YardsPageInner(props: IYardsPageProps) {
    const isMounted = useIsMounted();

    const { history, location}= useRouter();
    const parsed = parse(location.search);

    const onUpdateSearchText = (text: string) => {
        if (!isMounted.current) {
            logInfo("Not updating URL with search text. Yards page already unmounted");
            return;
        }
        const search = { ...parsed, search: text }; 
        history.replace({ pathname: location.pathname, search: stringify(search) });
    }
    const onScrollChanged = (scroll: number) => {
        if (!isMounted.current) {
            logInfo("Not updating URL with scroll changed. Yards page already unmounted");
            return;
        }
        const search = { ...parsed, scroll }; 
        history.replace({ pathname: location.pathname, search: stringify(search) });
    }
    useEffect(()=> {
        props.setTitle({ app: "Yards" });
        props.fetchYards({ sortOn: [ YardSortType.Name ], sortDirection: SortDirection.Asc });
    }, []);

    function renderModel(model: IYardListModelImmutable): JSX.Element {
        
        let scroll = 0;
        let text = "";
        
            if (typeof(parsed.search) == 'string') {
                text = parsed.search;
            }
            if (typeof(parsed.scroll) == 'string') {
                scroll = parseInt(parsed.scroll, 10);
            }
        
        return <YardsPageComponent
            onSearchTextChanged={onUpdateSearchText}
            onScrollChanged={onScrollChanged}
            scroll={scroll}
            searchText={text}
            yards={model.list}
            sort={model.sort}
            sortDir={model.sortDir} />;
    }


    
        return <DelayLoadContainer 
                    name="Yards" 
                    branch={props.yards}
                    onRenderModel={renderModel} />;
    
}


export const YardsPage = connect(mapStateToProps, mapDispatchToProps)(YardsPageInner);

export default YardsPage;