import * as React from "react";
import { connect } from "react-redux";
import { DelayLoadContainer } from '../components/delay-load-container';
import { ITaskDetails, ITaskNote } from '../api/twu-contracts';
import { setTitle } from '../actions/title';
import { fetchTaskDetails, addNote, updateTaskStatus, updateTaskDueOn } from '../actions/tasks';
import { TaskDetails } from '../components/task-details';
import { TaskNoteList } from '../components/task-note-list';
import { AddTaskNote } from '../components/add-task-note';
import * as DisplayUtils from "../utils/display";
import { push } from '../api/auto-push';
import { createNewNote } from "../api/tasks";
import { notifyInfo, notifySuccess, notifyError } from '../utils/notify';
import { ISessionState } from '../reducers/session';
import { useState, useEffect } from 'react';
import useRouter from 'use-react-router';
import { ContentSection, ContentSectionDisplayStyle } from "../components/common";


function mapStateToProps(state): ITaskDetailsPageState {
    return {
        taskDetails: state.taskDetails,
        session: state.session
    };
}

function mapDispatchToProps(dispatch): ITaskDetailsPageDispatch {
    return {
        setTitle: (args) => dispatch(setTitle(args)),
        fetchTaskDetails: (id) => dispatch(fetchTaskDetails(id)),
        addNote: (id, note, cb) => dispatch(addNote(id, note, cb)),
        updateTaskStatus: (options) => dispatch(updateTaskStatus(options)),
        updateTaskDueOn: (options) => dispatch(updateTaskDueOn(options))
    };
}

interface ITaskDetailsPageState {
    taskDetails?: any;
    session?: ISessionState;
}

interface ITaskDetailsPageDispatch {
    setTitle: (args) => void;
    fetchTaskDetails: (id) => void;
    addNote: (id, note, cb) => void;
    updateTaskStatus: (options) => void;
    updateTaskDueOn: (options) => void;
}

type ITaskDetailsPageProps = ITaskDetailsPageState & ITaskDetailsPageDispatch;


function TaskDetailsPage(props: ITaskDetailsPageProps) {

    const [ isFrozen, setIsFrozen ] = useState(false);
    const { match, history }= useRouter<{id: string}>();

    
    const recordId = match.params.id;
    const session = props.session;
    
    if (!session || !session.user || !session.token) return <>No session</>;

    const token = session.token;
    const userid = session.user.details.userid;

    useEffect(() => {
        const { setTitle, fetchTaskDetails } = props;
        setTitle({ app: "Task Details" });
        fetchTaskDetails(recordId);
    }, [recordId]);


    
    const onAddNote = (note, cb?) => {
        const { addNote } = props;
        addNote(recordId, note, () => {
            push({ token });
        });
    }
    const onTaskStatusChange = (newStatus, notes: string) => {
        const { updateTaskStatus } = props;
        setIsFrozen(true);
        notifyInfo("Update Task Status", "Updating task status ...", DisplayUtils.DEFAULT_UI_NOTIFICATION_SETTINGS);
        updateTaskStatus({ 
            id: recordId,
            status: newStatus,
            note: createNewNote(notes),
            onComplete: (result, error) => {
                if (result != null) {
                    notifySuccess("Update Task Status", "Task status updated", DisplayUtils.DEFAULT_UI_NOTIFICATION_SETTINGS);
                    onCancel();
                    setIsFrozen(false);
                    push({ token: props.session!.token!  });
                } else if (error != null) {
                    notifyError("Update Task Status", `Error updating task status: ${error.message}`, DisplayUtils.DEFAULT_UI_NOTIFICATION_SETTINGS);
                    setIsFrozen(false);
                }
            }
        });
    }
    const onReScheduleTask = (dueOn: string) => {
        const { updateTaskDueOn } = props;
        setIsFrozen(true);
        notifyInfo("Update Task Due Date", "Updating task due date ...", DisplayUtils.DEFAULT_UI_NOTIFICATION_SETTINGS);
        updateTaskDueOn({ 
            id: recordId,
            dueOn: dueOn,
            onComplete: (result, error) => {
                if (result != null) {
                    notifySuccess("Update Task Due Date", "Task due date updated", DisplayUtils.DEFAULT_UI_NOTIFICATION_SETTINGS);
                    onCancel();
                    //setState({ isFrozen: false });
                    push({ token: props.session!.token!  });
                } else if (error != null) {
                    notifyError("Update Task Due Date", `Error updating task due date: ${error.message}`, DisplayUtils.DEFAULT_UI_NOTIFICATION_SETTINGS);
                    setIsFrozen(false);
                }
            }
        });
    }
    const onCancel = () => {
        history.goBack();
    }
    
    const renderModel = (model: any): JSX.Element => {
        const mdl = model;
        // const { isFrozen } = state;
        const task: ITaskDetails = mdl.task;
        const notes: ITaskNote[] = mdl.notes;
        return <div className="container-fluid">
            <br />
            <div className="row">
                <div className="col-md-6">
                    <TaskDetails model={task}
                                 onTaskStatusChange={onTaskStatusChange}
                                 onReScheduleTask={onReScheduleTask}
                                 onCancel={onCancel}
                                 currentUserId={userid}
                                 enableActions={!isFrozen}
                                 viewOnly={true} />
                    <br />
                </div>
                <div className="col-md-6">
                    <ContentSection title={`Notes`} displayStyle={ContentSectionDisplayStyle.Fieldset} iconKind="icon" iconClass="pencil">
                        <TaskNoteList notes={notes} />
                        {(() => {
                            if (!isFrozen) {
                                return <AddTaskNote onAddNote={onAddNote} />;
                            }
                        })()}
                    </ContentSection>
                </div>
            </div>
        </div>;
    }
    
        return <DelayLoadContainer 
                    name="Task Details" 
                    branch={props.taskDetails}
                    onRenderModel={renderModel} />;
    
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskDetailsPage)