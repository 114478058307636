import {
    IContact
} from "./twu-contracts";
import { getDataManagerAsync } from "./data-manager";
export function getContactDetails(id): Promise<[IContact|undefined, string[]]> {
    return getDataManagerAsync().then(manager => {
        const contact = manager.getContactById(id);
        const titles = manager.getContactTitles().map(c => c.name);
        return Promise.all([ contact, titles ]);
    });
}


export function getNonMemberDetails(id): Promise<IContact|undefined> {
    return getDataManagerAsync().then(manager => {
        const nm = manager.getNonMemberById(id);
        return nm;
    });
}