import * as Constants from '../constants';

export interface ISessionState {
        token?: string;
        user?: {
            username: string;
            details: {
                userid: number;
            } 
        };
        loginProgressMessage: null;
        hasError: boolean;
        isLoading: boolean;
        online: boolean;
        errorDetails: null;
    
}

const INITIAL_STATE: ISessionState = {
    loginProgressMessage: null,
    hasError: false,
    isLoading: false,
    online: true,
    errorDetails: null
};

function sessionReducer(state = INITIAL_STATE, action: { type: string, payload?: any }) {
    switch (action.type) {
        case Constants.NAVIGATOR_OFFLINE:
            return {...state, online: false };
        case Constants.NAVIGATOR_ONLINE:
            return {...state, online: true };
        case Constants.LOGIN_USER_PENDING:
            return {
                ...state,
                token: null,
                user: {},
                username: null,
                key: null,
                hasError: false,
                isLoading: true,
                errorDetails: null
            };

        case Constants.LOGIN_USER_SUCCESS:

            return {
                ...state,
                token: action.payload.token,
                username: action.payload.username,
                user: action.payload.user,
                key: action.payload.key,
                hasError: false,
                isLoading: false,
                errorDetails: null
            };
        case Constants.LOGIN_USER_ERROR:
            return {...state,
                hasError: true,
                isLoading: false,
                errorDetails: action.payload
            };
        case Constants.LOGIN_PROGRESS_MESSAGE:
            return {...state,
                loginProgressMessage: action.payload
            };
        case Constants.LOGOUT_USER:
            return INITIAL_STATE;
        default:
            return state;
    }
}

export default sessionReducer;
