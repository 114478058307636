import * as Constants from '../constants';
import * as moment from 'moment';

export interface IVisitState {
    start: null | string;
    end: null | string
    elapsed: number;
    interval: null;
    data: {
        visitId: number
        yardIds: number[]
    };
}

const INITIAL_STATE = {
    start: null,
    end: null,
    elapsed: 0,
    interval: null,
    data: { }
};

export default function visitReducer(state = INITIAL_STATE, action: any = { type: '', payload: null }) {
    switch (action.type) {
        case Constants.VISIT_START:
            {
                return { ...state,
                    interval: action.payload.interval,
                    start: action.payload.start,
                    elapsed: 0,
                    data: {
                        visitId: action.payload.visitId,
                        yardIds: action.payload.yardIds
                    }
                };
            }
        case Constants.VISIT_RESUME:
            {
                return { ...state,
                    interval: action.payload.interval
                };
            }
        case Constants.VISIT_POSTPONE:
        case Constants.VISIT_STOP:
            {
                return INITIAL_STATE;
              
            }
        case Constants.VISIT_TICK:
            {
                const start = state.start;
                const end = moment.utc();
                const elapsed = start ? end.diff(moment.utc(start), "seconds") : -1;
                return { ...state,
                    end: end.toISOString(),
                    elapsed: elapsed
                };
            }
        default:
            return state;
    }
}