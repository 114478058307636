import * as Constants from "../constants";
import { IReducerBranchStateImmutable } from './utils';
import { ITask } from '../api/twu-contracts';

const INITIAL_STATE: IReducerBranchStateImmutable<{

    tasks: ITask[]

}|null> = {
    model: null,
    hasError: false,
    errorDetails: null,
    isLoading: false
};

export default function memberReducer(state = INITIAL_STATE, action: { type: string, payload?: any }) {
    switch (action.type) {
        case Constants.FETCH_TASKS_FOR_MEMBER_PENDING:
        case Constants.FETCH_MEMBER_DETAILS_PENDING:
            return { ...state,
                hasError: false,
                isLoading: true
            };
        case Constants.FETCH_TASKS_FOR_MEMBER_ERROR:
        case Constants.FETCH_MEMBER_DETAILS_ERROR:
            return { ...state,
                hasError: true,
                isLoading: false,
                errorDetails: action.payload
            };
        case Constants.FETCH_MEMBER_DETAILS_SUCCESS:
            {
                let currentModel = state.model
                if (currentModel == null) {
                    currentModel = action.payload;
                } else {
                    currentModel = {...currentModel, ...action.payload};
                }
                return { ...state,
                    model: currentModel,
                    hasError: false,
                    isLoading: false
                };
            }
        case Constants.FETCH_TASKS_FOR_MEMBER_SUCCESS:
            {
                let currentModel = state.model;
                if (currentModel == null) {
                    currentModel = action.payload;
                } else {
                    currentModel = {...currentModel, ...action.payload};
                }
                return { ...state,
                    model: currentModel,
                    hasError: false,
                    isLoading: false
                };
            }
        case Constants.ASSIGN_TASK_SUCCESS:
            {
                let currentModel = state.model
                if (currentModel != null) {
                    //Prepend the newly assigned task to the tasks list
                    let currentTasks = currentModel.tasks;
                    currentModel =  {
                        ...currentModel,
                        tasks: [ action.payload, ...currentTasks ]
                    };
                    return { ...state,
                        model: currentModel
                    };
                }
                return state;
            }
        default:
            return state;
    }
}