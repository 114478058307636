import * as React from "react";
import { connect } from "react-redux";
import * as ChangeUtils from "../api/change-utils";
import { collectItemChangesAsync } from "../actions/editable";
import { YardDetailsPageComponent } from "../components/pages/yard-details";

import { setTitle } from '../actions/title';
import { setActiveYard } from '../actions/context';
import { fetchYardDetails, fetchTasksForYard } from '../actions/yards';
import { DelayLoadContainer } from '../components/delay-load-container';
import { TaskStatus } from "../api/twu-contracts";
import { EditableField } from "../components/editable-field";
import { EditableContainerSaveFunc } from "../components/editable-field-container";
import { IYard, IYardDetails, ICreateScheduledVisit, IScheduledVisit } from "../api/twu-contracts";
import { DEFAULT_UI_NOTIFICATION_SETTINGS } from "../utils/display";
import { scheduleVisit } from "../api/visit";
import { putLocalScheduledVisit } from "../api/yards";
import SAlert from "react-s-alert";
import { IReducerRoot } from '../reducers';
import { useEffect } from 'react';
import useRouter from 'use-react-router';
import { ISessionState } from '../reducers/session';

function mapStateToProps(state: IReducerRoot) {
    return {
        yardDetails: state.yardDetails,
        session: state.session
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setTitle: (args) => dispatch(setTitle(args)),
        fetchYardDetails: (id) => dispatch(fetchYardDetails(id)),
        fetchTasksForYard: (args) => dispatch(fetchTasksForYard(args)),
        setActiveYard: (args) => dispatch(setActiveYard(args))
    };
}

interface IYardDetailsPageProps {
    session?: ISessionState;
    yardDetails: any;
    setTitle: (args) => void;
    fetchYardDetails: (id) => void;
    fetchTasksForYard: (args) => void;
    setActiveYard: (args) => void;
}



 function YardDetailsPage(props: IYardDetailsPageProps) {
    const { match}= useRouter<{id: string}>();
    const recordId = match.params.id;
    const session = props.session;
    
    if (!session || !session.user || !session.token) return <>No session</>;

    const isOnline = session.online;
    const userid = session.user.details.userid;
    const token = session.token;

    useEffect(() => {
        const { setTitle, fetchYardDetails, fetchTasksForYard, setActiveYard } = props;
        setTitle({ app: "Yard Details" });
        fetchYardDetails(match.params.id);
        fetchTasksForYard({ id: match.params.id, statusFilter: TaskStatus.NotComplete });
    }, [recordId]);


    const onScheduleVisit = (model: ICreateScheduledVisit, callback: (success: boolean, saveError?: Error) => void) => {
        SAlert.info("Saving scheduled visit", DEFAULT_UI_NOTIFICATION_SETTINGS as any);
        scheduleVisit({ token }, model).then(res => {
            SAlert.success("Saved scheduled visit", DEFAULT_UI_NOTIFICATION_SETTINGS as any);
            const sched: IScheduledVisit = {
                id: res.id,
                organiserId: model.organiserId,
                message: model.message,
                scheduledDate: model.scheduledDate
            };
            putLocalScheduledVisit(model.yardId, sched);
            callback(true);
        }).catch(err => {
            SAlert.error(`Error saving scheduled visit: ${err}`, DEFAULT_UI_NOTIFICATION_SETTINGS as any);
            callback(false, err);
        });
    }
    const onFilterStatus = (status: TaskStatus | null) => {
        const { fetchTasksForYard } = props;
        fetchTasksForYard({ id: recordId, statusFilter: status });
    }
    const onSaveChanges = (editables: EditableField[], callback: (success: boolean, saveError?: Error) => void) => {
        const model = props.yardDetails.model;
        const details: IYardDetails = model.yard;

        collectItemChangesAsync<IYard>({
            editableObject: details.yard,
            editableFields: editables,
            type: ChangeUtils.EditableObjectType.Yard,
            idSelector: obj => obj.id
        }).then(res => {
            callback(true);
        }).catch(err => {
            callback(false, err);
        });
    }
    const renderModel = (model: any) => {
        return <YardDetailsPageComponent model={model}
            session={token}
            userId={userid}
            isOnline={isOnline}
            onFilterStatusChange={onFilterStatus}
            onSaveChanges={onSaveChanges}
            onScheduleVisit={onScheduleVisit} />;
    }

    return <DelayLoadContainer
        name="Yard Details"
        branch={props.yardDetails}
        onRenderModel={renderModel} />;

}


export default connect(mapStateToProps, mapDispatchToProps)(YardDetailsPage);