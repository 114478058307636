import * as React from "react";
import * as uuid from "node-uuid";
import * as DisplayUtils from "../utils/display";
import { EditableField } from "./editable-field";
import { notifySuccess, notifyError } from '../utils/notify';


export type EditableContainerSaveFunc = (editables: EditableField[], callback: (result: any, saveError?: Error) => void) => void;

interface IEditableFieldContainerProps extends React.Props<any> {
    /**
     * If true, any change in a child EditableField component is saved immediately
     */
    autoCommit: boolean;
    /**
     * Called when there is a request to save
     */
    onSaveChanges: EditableContainerSaveFunc;
    /**
     * Raised if a push of locally saved changes is requested
     */
    requestPush?: () => void;
}

/**
 * Manages a series of child EditableField components to allow for coordinated and/or automatic saving of object changes
 */
export class EditableFieldContainer extends React.Component<IEditableFieldContainerProps, any> {
    constructor(props: IEditableFieldContainerProps) {
        super(props);
        this.state = {};
    }
    onSaved(success: any, saveError?: Error) {
        if (success != null) {
            notifySuccess("Save Changes", "Changes saved locally", DisplayUtils.DEFAULT_UI_NOTIFICATION_SETTINGS);
            if (this.props.requestPush != null) {
                this.props.requestPush();
            }
        } else if (saveError != null) {
            notifyError("Save Changes", saveError.message, DisplayUtils.DEFAULT_UI_NOTIFICATION_SETTINGS);
        } else {
            notifyError("Save Changes", "Failed to save changes", DisplayUtils.DEFAULT_UI_NOTIFICATION_SETTINGS);
        }
    }
    private onChildEditStateChanged = (e) => {
        const { onSaveChanges, autoCommit } = this.props;
        if (autoCommit && !e.isEditing && !e.isCancelling) {
            onSaveChanges([e.sender], this.onSaved.bind(this));
        }
    }

    saveAllChanges() {
        const { onSaveChanges } = this.props;
        const editables = DisplayUtils.getEditablesOfComponent(this);
        onSaveChanges(editables, this.onSaved.bind(this));
    }
    render(): JSX.Element {
        let children = React.Children.map(this.props.children, (child: any) => {

            //HACK: Type escape hatch
            if (typeof child === 'object' && child.type && child.type.name) {
                return React.cloneElement(child, { ref: uuid.v4() /* Override */, onEditStateChanged: this.onChildEditStateChanged });
            }

            return child;
        });
        return <div>{children}</div>;
    }
}