import * as React from "react";
import { Link } from "react-router-dom";
import { MemberCard } from "./cards";
import { IMember } from "../api/twu-contracts";
import { Icons } from "./icons";
import { AlertPanel, AlertPanelTheme, AlertStyleKind } from "./common";

export interface IMemberListProps {
    members: IMember[];
    emptyMessage?: string;
}

function RTSMemberComparator(a: IMember, b: IMember): number { 
    return MemberCompareBy(a, b, m => m.rts ? "A" : "B", DefaultMemberComparator);
}

export function DelegateLevelComparator(a: IMember, b: IMember): number {
    if (a.delegateLevel == 0 && b.delegateLevel == 0) {
        return RTSMemberComparator(a, b);
    }
    if (!b.delegateLevel) {
        return -1;
    } else { //b != 0
        if (!a.delegateLevel) {
            return 1;
        } else if (a.delegateLevel < b.delegateLevel) {
            return 1;
        } else if (a.delegateLevel > b.delegateLevel!) {
            return -1;
        } else {
            return RTSMemberComparator(a, b);
        }
    }
}

function MemberCompareBy(a: IMember, b: IMember, selector: (m: IMember) => string, subComparator: (a: IMember, b: IMember) => number) {
    const pa = selector(a);
    const pb = selector(b);
    if (!a && b) {
        return 1;
    } else if (a && !b) {
        return -1;
    } else {
        const ret = pa.localeCompare(pb);
        if (ret == 0) {
            return subComparator(a, b);
        } else {
            return ret;
        }
    }
}

function DefaultMemberComparator(a: IMember, b: IMember): number {
    const ret = MemberCompareBy(a, b, m => m.firstName, (ma, mb) => 0);
    if (ret != 0) {
        return ret;
    } else {
        return MemberCompareBy(a, b, m => m.lastName, (ma, mb) => 0);
    }
}

export function MemberListSortComparator(a: IMember, b: IMember): number {
    if (a.delegateLevel != null && b.delegateLevel != null) {
        return DelegateLevelComparator(a, b);
    } else if (a.delegateLevel == null && b.delegateLevel != null) {
        return 1;
    } else if (a.delegateLevel != null && b.delegateLevel == null) {
        return -1;
    } else {
        return RTSMemberComparator(a, b);
    }
}

export const MemberList = (props: IMemberListProps) => {
    const members = props.members.sort(MemberListSortComparator);
    if (members.length > 0) {
        return <div className='list-group'>
            {members.map((result) => (
                    <div key={result.id} className={`list-group-item${(result.delegateLevel != null && result.delegateLevel != 0) ? " delegate-member-list-item" : ""}${result.rts ? " member-list-item-rts" : ""}`}>
                        <Link to={`/member/${result.id}`}>
                            <MemberCard style="Basic" {...result} />
                        </Link>
                    </div>
                ))}
        </div>;
    } else {
        return <AlertPanel theme={AlertPanelTheme.Info} kind={AlertStyleKind.Bootstrap}>
            {Icons.INFO} {props.emptyMessage || "No members found"}
        </AlertPanel>;
    }
};