import * as React from 'react';
import { Switch, Route } from 'react-router-dom'
import AppComponent from '../containers/app';
import HomePage from '../containers/home-page';
import TasksPage from '../containers/tasks-page';
import YardsPage from '../containers/yards-page';
import SyncPage from '../containers/sync-page';
import IncompleteVisitsPage from '../containers/incomplete-visits-page';
import ClearDataPage from '../containers/clear-data-page';
import TaskDetailsPage from '../containers/task-details-page';
import YardDetailsPage from '../containers/yard-details-page';
import MemberDetailsPage from '../containers/member-details-page';
import ContactDetailsPage from '../containers/contact-details-page';
import NonMemberDetailsPage from '../containers/nonmember-details-page';
import NotFoundPage from '../containers/not-found-page';
import PendingChangesPage from '../containers/pending-changes';
import AboutPage from '../containers/about-page';
import YardInteractionsPage from '../containers/yard-interactions-page';
import MemberSearchPage from '../containers/member-search-page';

export const DefaultLayout = ({ component: Component, ...rest }: 
    { component: any, path?: string, exact?: boolean }) => (
    // tslint:disable-next-line:jsx-no-lambda
    <Route {...rest} render={props => (
        <AppComponent>
            <Component {...props} />
        </AppComponent>
    )} />
);

export default (
    <Switch>
        
        <DefaultLayout path="/" exact={true} component={HomePage} />
        <DefaultLayout path="/home" component={HomePage} />
        <DefaultLayout path="/yards" component={YardsPage} />
        <DefaultLayout path="/yard/:id" component={YardDetailsPage} />
        <DefaultLayout path="/yard/:id/interactions" component={YardInteractionsPage} />
        <DefaultLayout path="/tasks" component={TasksPage} />
        <DefaultLayout path="/sync" component={SyncPage} />
        <DefaultLayout path="/visits" component={IncompleteVisitsPage} />
        <DefaultLayout path="/pending" component={PendingChangesPage} />
        <DefaultLayout path="/cleardata" component={ClearDataPage} />
        <DefaultLayout path="/membersearch" component={MemberSearchPage} />
        <DefaultLayout path="/about" component={AboutPage} />
        <DefaultLayout path="/task/:id" component={TaskDetailsPage} />
        <DefaultLayout path="/member/:id" component={MemberDetailsPage} />
        <DefaultLayout path="/contact/:id" component={ContactDetailsPage} />
        <DefaultLayout path="/nonmember/:id" component={NonMemberDetailsPage} />
        <DefaultLayout component={NotFoundPage} />
    
    </Switch>
);