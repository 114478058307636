import { getDataManagerAsync } from "./data-manager";
import { SyncAgent } from "./syncagent";
import * as DisplayUtils from "../utils/display";
import { notifyWarn, notifySuccess } from '../utils/notify';
import type { IApiContext } from "./twu-contracts";

export function push(token: IApiContext) {
    return getDataManagerAsync().then(manager => {
        const agent = new SyncAgent(manager);
        return agent.push(token);
    }).then(res => {
        if (res != null) {
            notifySuccess("Save Changes", "Changes pushed up", DisplayUtils.DEFAULT_UI_NOTIFICATION_SETTINGS);
        }
        return res;
    }).catch(err => {
        notifyWarn("Save Changes", "Could not sync up saved changes. If you are offline, this is normal. You can manually sync up data later on from the 'Synchronise Data' action on the home screen", DisplayUtils.DEFAULT_UI_NOTIFICATION_SETTINGS);    });
}