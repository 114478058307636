import { IVisitStart, IVisitEnd, IVisit, ICreateScheduledVisit, IScheduleVisitResult, IApiContext } from "./twu-contracts";
import { getDataManagerAsync, IIncompleteVisit } from "./data-manager";
import { callApi } from "./call";
import * as uuid from "node-uuid";
import * as Sentry from "@sentry/browser";

export async function addVisit(yardIds: number[], model: IVisitStart): Promise<IVisit> {
    const manager = await getDataManagerAsync();
    return manager.addMultiYardVisit(yardIds, model);
}

export async function completeVisit(visitId: string, model: IVisitEnd) {
    const manager = await getDataManagerAsync();
    return manager.completeMultiYardVisit(visitId, model);
}

export async function scheduleVisit(token: IApiContext, model: ICreateScheduledVisit) {
    const syncId = uuid.v4();
    try {
        const res = callApi<IScheduleVisitResult>(token, "/api/schedulevisit", "POST", model, {
            ["x-sync-id"]: syncId
        });
        return res;
    } catch (e) {
        Sentry.withScope(scope => {
            scope.setTag("Operation", "schedulevisit");
            scope.setTag("SyncRequestId", syncId);
            Sentry.captureException(e);
        });
        throw e;
    }
}

export async function getIncompleteVisits(): Promise<IIncompleteVisit[]> {
    const manager = await getDataManagerAsync();
    return manager.getIncompleteVisits();
}