import * as React from 'react';

const FontelloFontIcon: React.FC<{ name: string }> = (props) => <i className={`icon icon-${props.name}`} />;

/**
 * Default icons used in this app
 */
export const Icons = {
	USER: <FontelloFontIcon name="user" />,
	PASSWORD: <FontelloFontIcon name="lock" />,
	PIN: <FontelloFontIcon name="credit-card" />,
	FACEBOOK: <FontelloFontIcon name="facebook" />,
	EXCLAMATION: <FontelloFontIcon name="attention-circled" />,
	REFRESH: <FontelloFontIcon name="arrows-cw" />,
	REFRESH_SPIN: <i className="animate-spin icon-arrows-cw" />,
	POWER_OFF: <FontelloFontIcon name="off" />,
	SWITCH: <FontelloFontIcon name="exchange" />,
	OPTIONS: <FontelloFontIcon name="cogs" />,
	INFO: <FontelloFontIcon name="info-circled" />,
	TICK: <FontelloFontIcon name="ok" />,
	HOME: <FontelloFontIcon name="home" />,
	LIST: <FontelloFontIcon name="list" />,
	USERS: <FontelloFontIcon name="users" />,
	SEARCH: <FontelloFontIcon name="search" />,
	MAP: <FontelloFontIcon name="map" />,
	LOCATION: <FontelloFontIcon name="location" />,
	SURVEY: <FontelloFontIcon name="edit" />,
	HELP: <FontelloFontIcon name="help-circled" />,
	SCROLL_TO_TOP: <FontelloFontIcon name="up-circled" />,
	BACK: <FontelloFontIcon name="left-circled" />,
	CHEV_RIGHT: <FontelloFontIcon name="right-open" />,
	DOWNLOAD: <FontelloFontIcon name="download-cloud" />,
	UPLOAD: <FontelloFontIcon name="upload-cloud" />,
	COMMENT: <FontelloFontIcon name="comment" />,
	PHONE: <FontelloFontIcon name="phone" />,
	MOBILE: <FontelloFontIcon name="mobile" />,
	CLOSE: <FontelloFontIcon name="cancel" />,
	TRASH: <FontelloFontIcon name="trash" />,
	MALE: <FontelloFontIcon name="male" />,
	FEMALE: <FontelloFontIcon name="female" />,
	PLUS: <FontelloFontIcon name="plus" />,
	ELLIPSIS: <FontelloFontIcon name="ellipsis" />,
	TAG: <FontelloFontIcon name="tag" />,
	KEY: <FontelloFontIcon name="key-1" />,
	CALENDAR: <FontelloFontIcon name="calendar" />,
	CLOCK: <FontelloFontIcon name="clock" />,
	BUILDING: <FontelloFontIcon name="building" />,
	BLOCK: <FontelloFontIcon name="block" />,
};
