import * as React from "react";
import { IContact } from "../../api/twu-contracts";
import * as DisplayUtils from "../../utils/display";
import { DisplayField } from "../display-field";
import { ContentSection, ContentSectionDisplayStyle } from "../common";


interface INonMemberDetailsPageComponentProps {
    model: any;
    session: string;
}

export class NonMemberDetailsPageComponent extends React.Component<INonMemberDetailsPageComponentProps, any> {
    render(): JSX.Element {
        const { model } = this.props;
        const person: IContact = model.nonmember;
        return <div className="container-fluid">
            <br/>
                <ContentSection displayStyle={ContentSectionDisplayStyle.Fieldset} title={DisplayUtils.getFullName(person)} iconKind="icon" iconClass="user">
                    <div className="well">
                        <div className="form-horizontal">
                            <DisplayField iconKind="icon" iconClass="address" label="Address" value={DisplayUtils.getAddressString(person)} />
                            <DisplayField iconKind="icon" iconClass="phone" label="Phone" value={person.phone} />
                            <DisplayField iconKind="icon" iconClass="mobile" label="Mobile" value={person.mobile} />
                            <DisplayField iconKind="icon" iconClass="mail" label="Email" value={person.email} />
                        </div>
                    </div>
                </ContentSection>
        </div>;
    }
}