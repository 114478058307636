;
import * as DisplayUtils from "../utils/display";
import { notifySuccess, notifyWarn } from '../utils/notify';

export function getCurrentLocation(success: PositionCallback, error: PositionErrorCallback, options?: PositionOptions) {
    navigator.geolocation.getCurrentPosition(pos => {
        notifySuccess("Geolocation", "Acquired current position", DisplayUtils.DEFAULT_UI_NOTIFICATION_SETTINGS);
        success(pos);
    }, err => {
        notifyWarn("Geolocation", "Failed to acquire current position", DisplayUtils.DEFAULT_UI_NOTIFICATION_SETTINGS);
        error(err);
    }, options);
}