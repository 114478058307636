import * as React from "react";
import * as moment from "moment";
import * as DisplayUtils from "../utils/display";
import { IVisit } from "../api/twu-contracts";
import {
    buildVisitDateSortComparator
} from "../api/comparators";
import { ReactNode } from 'react';
import { Icons } from "./icons";

interface IVisitListProps {
    visits: IVisit[];
    onVisitSelected?: (visit: IVisit) => void;
}

export const VisitList = (props: IVisitListProps) => {
    let body;
    const visits: ReactNode[] = [];
    
    const sortedVisits = (props.visits || []).sort(buildVisitDateSortComparator(false));
    
    for (const visit of sortedVisits) {
        const inProgress = !DisplayUtils.isVisitCompleted(visit) && (<span className="pull-right label label-success">In Progress</span>);
       
        let multi: ReactNode = null;
        if (visit.yardIds && visit.yardIds.length > 1) {
            multi = <span className="pull-right label label-info">Covers {visit.yardIds.length - 1} other yards</span>;
        }
        let ageComp = (
            <span className="pull-right label label-primary">
                {Icons.CALENDAR} {DisplayUtils.getVisitDateReferenceFromNow(visit)}
            </span>
        );
        
        let duration: ReactNode = null;
        if (DisplayUtils.isVisitCompleted(visit)) {
            const period = DisplayUtils.getVisitDuration(visit);
            duration = (
                <span className="pull-right label label-info">
                    {Icons.CLOCK} {period}
                </span>
            );
        }
        
        const localStart = DisplayUtils.getMomentUtc(visit.startDate + " " + visit.startTime).toDate();
        const vStartDate = moment(localStart).format("MMM Do YYYY");
        const vStartTime = moment(localStart).format("h:mm a");
        
        const handler = ((v) => {
            if (props.onVisitSelected) {
                props.onVisitSelected(v);
            }
        }).bind({}, visit);

        visits.push((
            <div key={`${visit.id}_${visit.serverId}`} className="list-group-item" onClick={handler}>
                {ageComp}
                {duration}
                {multi}
                {inProgress}
                <h4 className="list-group-item-heading">{vStartDate}</h4>
                <p className="list-group-item-text">at {vStartTime}</p>
            </div>
        ));
    }
    
    if (visits.length == 0) {
        visits.push((<div key="unvisted-yard" className="alert alert-info">{Icons.INFO} This yard has not been visited by anyone</div>));
    }
    
    body = (
        <div className="list-group">
            {visits}
        </div>
    );
    
    return body;
};