/**
 * ts
 *
 * UI notification module
 */

import Alert from "react-s-alert";


export function notifyWarn(title: string, message: string, extraOptions?: any) {
        Alert.warning(message, extraOptions);
  
}

export function notifySuccess(title: string, message: string, extraOptions?: any) {
    
        Alert.success(message, extraOptions);
    
}

export function notifyError(title: string, message: string, extraOptions?: any) {
        Alert.error(message, extraOptions);
    
}

export function notifyInfo(title: string, message: string, extraOptions?: any) {
    
        Alert.info(message, extraOptions);
    
}