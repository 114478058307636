import { addTaskNoteAsync, getTaskNotesAsync, getTaskDetailsAsync, getTasksAsync, assignTaskAsync, setTaskStatusAsync, ITaskFetchOptions, SetTaskStatusOptions, updateTaskDueOnAsync } from "../api/tasks";
import { TaskStatus } from "../api/twu-contracts";
import * as Constants from "../constants";

export function fetchTasks(options: ITaskFetchOptions) {
    return (dispatch, getState) => {
        return dispatch({
            types: [
                Constants.FETCH_TASKS_PENDING,
                Constants.FETCH_TASKS_SUCCESS,
                Constants.FETCH_TASKS_ERROR
            ],
            payload: {
                promise: getTasksAsync(options).then(list => {
                    return {
                        list: list,
                        statusFilter: options.statusFilter
                    };
                })
            }
        });
    };
}

export function fetchTaskDetails(id) {
    return (dispatch, getState) => {
        return dispatch({
            types: [
                Constants.FETCH_TASK_DETAILS_PENDING,
                Constants.FETCH_TASK_DETAILS_SUCCESS,
                Constants.FETCH_TASK_DETAILS_ERROR
            ],
            payload: {
                promise: getTaskDetailsAsync(id).then(details => {
                    return Promise.all([ details, getTaskNotesAsync(id) ]);
                }).then(res => {
                    return {
                        task: res[0],
                        notes: res[1]
                    };
                })
            }
        });
    };
}

export function addNote(id, note, callback) {
    return (dispatch, getState) => {
        return dispatch({
            types: [
                Constants.ADD_NOTE_PENDING,
                Constants.ADD_NOTE_SUCCESS,
                Constants.ADD_NOTE_ERROR
            ],
            payload: {
                promise: addTaskNoteAsync(id, note).then(res => {
                    if (callback != null) {
                        callback();
                    }
                    return {
                        note: res
                    };
                }) 
            }
        });
    };
}

export function assignNewTask(options) {
    return (dispatch, getState) => {
        return dispatch({
            types: [
                Constants.ASSIGN_TASK_PENDING,
                Constants.ASSIGN_TASK_SUCCESS,
                Constants.ASSIGN_TASK_ERROR
            ],
            payload: {
                promise: assignTaskAsync(options).then(res => {
                    if (options.onComplete != null) {
                        options.onComplete(res, null);
                    }
                    return res;
                }).catch(err => {
                    if (options.onComplete != null) {
                        options.onComplete(null, err);
                    }
                    throw err;
                })
            }
        });
    };
}

export function updateTaskStatus(options: SetTaskStatusOptions) {
    return (dispatch, getState) => {
        return dispatch({
            types: [
                Constants.UPDATE_TASK_STATUS_PENDING,
                Constants.UPDATE_TASK_STATUS_SUCCESS,
                Constants.UPDATE_TASK_STATUS_ERROR
            ],
            payload: {
                promise: setTaskStatusAsync(options).then(res => {
                    //If note was included, dispatch the appropriate action to update state
                    if (res.note) {
                        dispatch({
                            type: Constants.ADD_NOTE_SUCCESS,
                            payload: {
                                note: res.note
                            }
                        });
                    }
                    if (options.onComplete != null) {
                        options.onComplete(res, null);
                    }
                    return res;
                }).catch(err => {
                    if (options.onComplete != null) {
                        options.onComplete(null, err);
                    }
                    throw err;
                })
            }
        });
    };
}

export function updateTaskDueOn(options) {
    return (dispatch, getState) => {
        return dispatch({
            types: [
                Constants.UPDATE_TASK_DUE_PENDING,
                Constants.UPDATE_TASK_DUE_SUCCESS,
                Constants.UPDATE_TASK_DUE_ERROR
            ],
            payload: {
                promise: updateTaskDueOnAsync(options).then(res => {
                    if (options.onComplete != null) {
                        options.onComplete(res, null);
                    }
                    return res;
                }).catch(err => {
                    if (options.onComplete != null) {
                        options.onComplete(null, err);
                    }
                    throw err;
                })
            }
        });
    };
}