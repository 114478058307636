import * as React from "react";
import { connect } from "react-redux";
import { setTitle } from '../actions/title';

function mapStateToProps(state) {
    return { };
}

function mapDispatchToProps(dispatch) {
    return {
        setTitle: (args) => dispatch(setTitle(args))
    };
}

interface INotFoundPageProps {
    setTitle: (args) => void;
}

@connect(mapStateToProps, mapDispatchToProps)
export default class NotFoundPage extends React.Component<INotFoundPageProps, any> {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        this.props.setTitle({ app: "Page not found" });
    }
    render(): JSX.Element {
        return <div className="container-fluid">
            <br/>
            <div className="alert alert-danger">
                <strong>The page you were navigating to could not be found</strong>
            </div>
        </div>;
    }
}