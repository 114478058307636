const createLogger = require('redux-logger');
import * as Constants from '../constants';

const logger = createLogger({
  collapsed: true,
  stateTransformer: (state) => {
    return state;
  },
  predicate: (getState, { type }) => {
    return type !== 'redux-form/BLUR' &&
           type !== 'redux-form/CHANGE' &&
           type !== 'redux-form/FOCUS' &&
           type !== 'redux-form/TOUCH' &&
           type !== Constants.VISIT_TICK;
  },
});


export default logger;
