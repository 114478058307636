import * as React from "react";
import { getDataManagerAsync } from "../api/data-manager";
import { IYard, IdNamePair } from "../api/twu-contracts";
import { YardPickList } from "./yard-pick-list";
import { startVisit, endVisit, resumeVisit, postponeEndVisit } from '../actions/visit';
import { TwuIcons } from "./twu-icons";
import { formatElapsedTime } from "../utils/display";
import * as Notify from "../utils/notify";
import { getCurrentLocation } from "./geolocation-helper";
import { push } from "../api/auto-push";
import * as DisplayUtils from "../utils/display";
import { ModalBody, ModalContent, ModalFooter, ModalHeader } from "./modal-common";
import { NBSP } from '../constants/index';
import { IReducerRoot } from '../reducers';
import Drawer from 'rc-drawer';
import { useDispatch, useMappedState } from 'redux-react-hook';
import "rc-drawer/assets/index.css";
import { useState, useEffect, useRef } from 'react';
import { Icons } from "./icons";

export interface IVisitSummary {
    notes: string;
    superNotes: string;
}

export interface IVisitSummaryProps {
    onSummaryChanged(summary: IVisitSummary);
}

export interface IVisitSummaryState {
    notes: string;
    superNotes: string;
}

export function VisitSummary(props: IVisitSummaryProps) {
    const [notes, setNotes] = useState('');
    const [superNotes, setSuperNotes] = useState('');
    const initial = useRef(false);

    function sendSummaryChange() {
        props.onSummaryChanged({ notes, superNotes });
    }
    const onVisitNotesChanged = (e) => {
        setNotes(e.target.value);
    }
    const onSuperNotesChanged = (e) => {
        setSuperNotes(e.target.value);
    }
    useEffect(() => {
        // we send the change when notes or superNotes change, but not on initial render
        if (!initial.current) {
            initial.current = true;
            return;
        }
        sendSummaryChange();
    }, [notes, superNotes]);

    return <div>
        <div className="form-group">
            <label className="control-label">Visit Notes</label>
            <textarea className="form-control" rows={3} placeholder="Notes for this visit" value={notes} onChange={onVisitNotesChanged} />
        </div>
        <div className="form-group">
            <label className="control-label">TWUSUPER Notes</label>
            <textarea className="form-control" rows={3} placeholder="Notes for TWUSUPER" value={superNotes} onChange={onSuperNotesChanged} />
        </div>
    </div>;
}

export function VisitTracker() {

    const [isStartingVisit, setIsStartingVisit] = useState(false)
    const [isCompletingVisit, setIsCompletingVisit] = useState(false)
    const [isStoppingVisitLater, setIsStoppingVisitLater] = useState(false)
    const [promptYardSelection, setPromptYardSelection] = useState(false)
    const [chosenYards, setChosenYards] = useState<IdNamePair[]>([]);
    const [availableYards, setAvailableYards] = useState<IYard[]>([]);
    const [visitSummary, setVisitSummary] = useState<IVisitSummary>({ notes: "", superNotes: "" });

    const dispatch = useDispatch();
    
        const { visitBranch, contextBranch, session } = useMappedState((state: IReducerRoot) => ({
            contextBranch: state.context,
            visitBranch: state.visit,
            session: state.session
        }));
    
    

    useEffect(() => {

        if (visitBranch && visitBranch.data
            && visitBranch.data.visitId
            && visitBranch.data.yardIds != null
            && visitBranch.interval == null
            && visitBranch.start != null) {

            dispatch(resumeVisit({}))
            Notify.notifyInfo("Resume Visit", "Resuming visit", DisplayUtils.DEFAULT_UI_NOTIFICATION_SETTINGS);
        }
    }, []);
    useEffect(() => {
        const yard = contextBranch.activeYard;

        setChosenYards(yard ? [yard] : []);

        
        setPromptYardSelection(!yard);

    }, [contextBranch.activeYard]);





    const onBeginStopLater = (e) => {
        setIsStoppingVisitLater(true);
    }
    const onCompleteStopVisitLater = (e) => {
        dispatch(postponeEndVisit({ interval: visitBranch.interval }));
        setIsStoppingVisitLater(false);   
    }
    const onCancelStopVisitLater = (e) => {
        setIsStoppingVisitLater(false);
    }
    function closeStartVisitDialog(bCancel: boolean) {
        if (bCancel === true) {
            //We want to invalidate the chosen yard option, but only if we were prompting for it
            if (promptYardSelection === true) {
                setIsStartingVisit(false);
                setChosenYards([]);
            } else {
                setIsStartingVisit(false);
            }
        } else {
            setIsStartingVisit(false);
        }
    }
    function closeCompleteVisitDialog(bCancel: boolean) {
        setIsCompletingVisit(false);
        setChosenYards([])
        setPromptYardSelection(false)

        if (bCancel === true) {

        } else {
            setVisitSummary({ notes: '', superNotes: '' });
        }
    }
    const onCompleteStartVisit = (e) => {

        if (chosenYards.length) {
            const handler = (pos?: GeolocationPosition) => {
                if (pos) {
                    dispatch(startVisit({ yardIds: [...(chosenYards.map(y => y.Id))], location: { longitude: pos.coords.longitude, latitude: pos.coords.latitude } }));
                } else {
                    dispatch(startVisit({ yardIds: [...(chosenYards.map(y => y.Id))] }));
                }
                if (chosenYards.length == 1) {
                    Notify.notifyInfo("Start Visit", `Starting visit on yard: ${chosenYards[0].Name}`, DisplayUtils.DEFAULT_UI_NOTIFICATION_SETTINGS);
                } else {
                    Notify.notifyInfo("Start Visit", `Starting visit on ${chosenYards.length} yards`, DisplayUtils.DEFAULT_UI_NOTIFICATION_SETTINGS);
                }
                closeStartVisitDialog(false);
            };
            getCurrentLocation(handler, () => handler());
        } else {
            alert("You haven't chosen a yard to visit");
        }
    }
    const onCompleteStopVisit = (e) => {

        const handler = (pos?: GeolocationPosition) => {
            if (pos != null) {
                dispatch(endVisit({ interval: visitBranch!.interval, visitNotes: visitSummary!.notes, twuSuperNotes: visitSummary!.superNotes, location: { longitude: pos.coords.longitude, latitude: pos.coords.latitude } }));
            } else {
                dispatch(endVisit({ interval: visitBranch!.interval, visitNotes: visitSummary!.notes, twuSuperNotes: visitSummary!.superNotes }));
            }
            Notify.notifySuccess("Complete Visit", "Visit completed", DisplayUtils.DEFAULT_UI_NOTIFICATION_SETTINGS);
            closeCompleteVisitDialog(false);
            push({ token: session.token! });
        };
        getCurrentLocation(handler, (err) => handler());
    }
    const onCancelStartVisit = (e) => {
        closeStartVisitDialog(true);
    }
    const onCancelStopVisit = (e) => {
        closeCompleteVisitDialog(true);
    }
    const onBeginStopVisit = (e) => {
        e.preventDefault();
        setIsCompletingVisit(true);
        return false;
    }
    const onBeginStartVisit = (e) => {
        e.preventDefault();

        if (!chosenYards.length) {
            getDataManagerAsync().then(manager => {
                const yards = manager.getYards();

                
                if (yards.length == 0) { //But there's no yards to prompt
                    Notify.notifyError("Start Visit", "No yards found. Cannot start visit. Ensure there were yards downloaded when you synchronised", DisplayUtils.DEFAULT_UI_NOTIFICATION_SETTINGS);
                }
                
                setIsStartingVisit(true);
                setPromptYardSelection(true);
                setAvailableYards(yards);
            });
        } else {
            setIsStartingVisit(true);
        }

        return false;
    }
    const onYardsToVisitSelected = (yard: number[]) => {
        setChosenYards(yard.map(a => ({ Id: a, Name: "Yard" })));
    }
    const onSummaryChanged = (visit: IVisitSummary) => {
        setVisitSummary(visit);
    }

    if (visitBranch == null) {
        return <div />;
    }

    return <div style={{
        position: "fixed",
        right: 0,
        bottom: 0,
        width: visitBranch.interval == null ? 170 : 250,
        border: "1px solid #cccccc",
        background: "#e3e3e3",
        padding: "6px",
        zIndex: 40,
        borderRadius: "3px"
    }}>
        <span className="btn">{Icons.CLOCK}</span>
        {(() => {
            const bSingleYardChosen = (chosenYards != null && chosenYards.length == 1);
            const bPromptForYardSelection = (promptYardSelection === true && availableYards != null && availableYards.length > 0);
            if (isStartingVisit === true && (bSingleYardChosen || bPromptForYardSelection)) {

                return <Drawer placement="bottom" handler={false} level={null} open={isStartingVisit}>
                    <ModalContent>
                        <ModalHeader>Start Visit</ModalHeader>
                        <ModalBody>
                            {(() => {
                                if (bPromptForYardSelection) {
                                    const selectedYardIds = (chosenYards != null) ? chosenYards.map(y => y.Id) : [];
                                    return <div>
                                        <p>Pick one or more from the list below and hit <strong>Start Visit</strong> to start your yard visit</p>
                                        <YardPickList yards={availableYards!} onYardsSelect={onYardsToVisitSelected} selectedYardIds={selectedYardIds!} />
                                    </div>;
                                } else if (bSingleYardChosen) {
                                    return <p>You are about to start a visit on yard <strong>{chosenYards![0].Name}</strong>. Hit <strong>Start Visit</strong> to begin</p>;
                                }
                            })()}
                        </ModalBody>
                        <ModalFooter>
                            <button type="button" className="btn btn-success" disabled={chosenYards == null} onClick={onCompleteStartVisit}>{TwuIcons.PLAY} Start Visit</button>
                            <button type="button" className="btn btn-danger" onClick={onCancelStartVisit}>{Icons.CLOSE} Cancel</button>
                        </ModalFooter>
                    </ModalContent>
                </Drawer>;
            } else if (isCompletingVisit === true) {
                return <Drawer placement="bottom" handler={false} level={null} open={isCompletingVisit}>
                    <ModalContent>
                        <ModalHeader>Stop Visit</ModalHeader>
                        <ModalBody>
                            <VisitSummary onSummaryChanged={onSummaryChanged} />
                            <div className="clearfix" />
                        </ModalBody>
                        <ModalFooter>
                            <button type="button" className="btn btn-success" disabled={visitSummary == null} onClick={onCompleteStopVisit}>{TwuIcons.STOP} Stop Visit</button>
                            <button type="button" className="btn btn-danger" onClick={onCancelStopVisit}>{Icons.CLOSE} Cancel</button>
                        </ModalFooter>
                    </ModalContent>
                </Drawer>;
            } else if (isStoppingVisitLater === true) {

                return <Drawer placement="bottom" handler={false} level={null} open={isStoppingVisitLater}>
                    <ModalContent>
                        <ModalHeader>Stop Visit Later</ModalHeader>
                        <ModalBody>
                            <p>Hit the <strong>Complete Visit Later</strong> button to stop and complete this visit later on</p>
                            <p>You can complete this visit later on from the <strong>Incomplete Visits</strong> screen which is accessible from the home screen</p>
                        </ModalBody>
                        <ModalFooter>
                            <button type="button" className="btn btn-success" onClick={onCompleteStopVisitLater}>Complete Visit Later</button>
                            <button type="button" className="btn btn-danger" onClick={onCancelStopVisitLater}>{Icons.CLOSE} Cancel</button>
                        </ModalFooter>
                    </ModalContent>
                </Drawer>;
            }
        })()}
        {(() => {
            if (visitBranch.interval == null) {
                return <a type="button" className={`btn btn-success ${isStartingVisit === true ? "disabled" : ""}`} title="Start a new visit" onClick={onBeginStartVisit}>
                    {TwuIcons.PLAY} Start Visit
                        </a>;
            } else {
                return [
                    <a key="stop-visit-action" type="button" className={`btn btn-danger ${isCompletingVisit === true ? "disabled" : ""}`} title="Stop the current visit" onClick={onBeginStopVisit}>
                        {TwuIcons.STOP} {formatElapsedTime(visitBranch.elapsed)}
                    </a>,
                    <span key="visit-tracker-nbsp">{NBSP}</span>,
                    <a key="stop-visit-later-action" type="button" className={`btn btn-danger ${isCompletingVisit === true ? "disabled" : ""}`} title="Stop the current visit and complete it later" onClick={onBeginStopLater}>
                        {TwuIcons.STOP} Later
                            </a>
                ];
            }
        })()}
    </div>;


}