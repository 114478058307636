import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { setTitle } from '../actions/title';
import { SyncPanel } from '../components/sync-panel';
import { ISessionState } from '../reducers/session';
import { Icons } from "../components/icons";
import { AlertPanel, AlertPanelTheme, AlertStyleKind } from "../components/common";


function mapStateToProps(state) {
    return { 
        session: state.session
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setTitle: (args) => dispatch(setTitle(args))
    };
}

interface ISyncPageProps {
    session: ISessionState;
    setTitle: (args) => void;
}

@connect(mapStateToProps, mapDispatchToProps)
export default class SyncPage extends React.Component<ISyncPageProps, any> {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        this.props.setTitle({ app: "Sync" });
    }
    render(): JSX.Element {
        const { session } = this.props;
        return <div className="container-fluid">
            <br />
            <SyncPanel session={session.token!} online={true} />
            <br />
            <AlertPanel kind={AlertStyleKind.Bootstrap} theme={AlertPanelTheme.Info}>
                <p>{Icons.INFO} You can review what will be pushed up by viewing the <Link to={`/pending`}>Pending Changes screen</Link></p>
            </AlertPanel>
        </div>;
    }
}