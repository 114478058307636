import {
    IChangeAction,
    ChangeActionType,
    IImmutableObjectWithChanges,
    ISyncable,
    IYard,
    IVisit, 
    ITask,
    ITaskNote,
    PendingChangeDictionary,
    isAddress,
} from "./twu-contracts";
import * as uuid from "node-uuid";
import * as moment from "moment";
import { 
    invertedChangeActionSortComparator,
    changeActionDescendingDateComparator
} from "./comparators";
import { EditableField, SPECIAL_PROP_ADDRESS } from "../components/editable-field";
import { getDataManagerAsync } from "../api/data-manager";

/**
 * A function that returns a property value of the given object
 */
export type PropertyValueSelector<T> = (obj: T) => any;
/**
 * A function that returns an id of the given object
 */
export type IdSelector<T> = (obj: T) => any;

export enum EditableObjectType {
    Yard,
    Contact,
    Member,
    Task
}

function isChangeAction(obj: any): obj is IChangeAction {
    const type = typeof(obj);
    return type != 'string' && type != 'number' && type != 'function' && ('data' in obj);
}

export function collectAttachedPendingChanges<T extends IImmutableObjectWithChanges>(obj: T): PendingChangeDictionary {
    const changesDict: PendingChangeDictionary = {};
    if (obj.changes != null) {
        const sortedChanges = obj.changes
            .filter((ch) => ch.type == ChangeActionType.ObjectPropertyChange)
            .sort(changeActionDescendingDateComparator);
        for (const change of sortedChanges) {
            //In the event of multiple property changes, only the most recent change
            //of that property "wins" due to the sorted order
            let propName = change.data.property;
            //NOTE: Address is a special case, it won't have a property name and its value is an object
            //for editables to properly pick this up, we have to key it on SPECIAL_PROP_ADDRESS
            if (propName == null && isAddress(change.data.value)) {
                propName = SPECIAL_PROP_ADDRESS;
            }
            if (!changesDict[propName]) {
                changesDict[propName] = change;
            }
        }
    }
    return changesDict;
}

export function getMostRecentPropertyChange<T extends IImmutableObjectWithChanges>(obj: T, propName: string): any {
    if (obj.changes == null || obj.changes.length == 0) {
        return null;
    }
    
    const props = obj.changes
                     .filter(chng => chng.type == ChangeActionType.ObjectPropertyChange && chng.data.property == propName)
                     .sort(invertedChangeActionSortComparator);

    if (props.length > 0) {
        return props[0].data.value;
    }
    return null;
}

/**
 * Builds a change action for the given subject
 */
function buildChangeAction<T extends ISyncable>(type: ChangeActionType, obj: T): IChangeAction {
    //NOTE: Even for NewTaskNote, serverid is *allowed*
    let objId = obj.serverId || obj.id;
    return {
        id: uuid.v4(),
        isClientSide: true,
        objectId: objId,
        type: type,
        performedOn: moment.utc().format(),
        data: obj
    };
}
export function collectObjectChange<T>(obj: T, typeName: EditableObjectType, name: string | undefined, idSelector: IdSelector<T>, valueSelector: PropertyValueSelector<T>): IChangeAction {
    //NOTE: property will be null for addresses
    let property = name;
    let value = valueSelector(obj);
    if (isChangeAction(value)) {
        if (value.isClientSide === true) {
            return value;
        } else {
            property = value.data.property;
            value = value.data.value;
        }
    }
    return {
        id: uuid.v4(),
        isClientSide: true,
        objectId: idSelector(obj),
        type: ChangeActionType.ObjectPropertyChange,
        performedOn: moment.utc().format(),
        data: {
            type: EditableObjectType[typeName],
            property: property,
            value: value
        }
    };
}
/**
 * @deprecated
 * OBSOLETE - DO NOT USE
 * @param yard 
 * @param visit 
 */
export function collectNewVisit(yard: IYard, visit: IVisit): IChangeAction {
    const change = buildChangeAction(ChangeActionType.NewVisit, visit);
    change.data = { yardId: yard.id, visit: visit };
    return change;
}
/**
 * @deprecated
 * OBSOLETE - DO NOT USE
 * @param yard 
 * @param visit 
 */
export function collectCompletedVisit(yard: IYard, visit: IVisit): IChangeAction {
    const change = buildChangeAction(ChangeActionType.CompletedVisit, visit);
    change.data = { yardId: yard.id, visit: visit };
    return change;
}
export function collectNewMultiYardVisit(yardIds: number[], visit: IVisit): IChangeAction {
    const change = buildChangeAction(ChangeActionType.NewMultiYardVisit, visit);
    change.data = { yardIds: yardIds, visit: visit };
    return change;
}
export function collectCompletedMultiYardVisit(visit: IVisit): IChangeAction {
    const change = buildChangeAction(ChangeActionType.CompletedMultiYardVisit, visit);
    change.data = { yardIds: [ ...visit.yardIds ], visit: visit };
    return change;
}
export function collectNewTask(task: ITask): IChangeAction {
    const change = buildChangeAction(ChangeActionType.NewTask, task);
    change.data = { task: task };
    return change;
}
export function collectNewTaskNote(task: ITask, note: ITaskNote): IChangeAction {
    const change = buildChangeAction(ChangeActionType.NewTaskNote, task);
    change.data = { note: note };
    return change;
}

export function collectNewTaskDueOn(task: ITask, dueOn: string): IChangeAction {
    const change = buildChangeAction(ChangeActionType.ReScheduleTaskDueDate, task);
    change.data = { newDate: dueOn };
    return change;
}