import * as React from "react";
import { connect } from "react-redux";
import { getDataManagerAsync } from "../api/data-manager";
import { changeActionDescendingDateComparator } from "../api/comparators";
import { setTitle } from '../actions/title';
import { TwuIcons } from "../components/twu-icons";
import { IChangeAction, ChangeActionType, TaskStatus, isAddress } from "../api/twu-contracts";
import * as moment from "moment";
import { Link } from "react-router-dom";
import * as DisplayUtils from "../utils/display";
import { Icons } from "../components/icons";
import { AlertPanel, AlertPanelTheme, AlertStyleKind, BsLoadSpinner } from "../components/common";

function mapStateToProps(state) {
    return {
        
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setTitle: (args) => dispatch(setTitle(args)),
    };
}

interface IPendingChangesProps {
    setTitle: (args) => void;
}

interface IRecordLinkProps { 
    type: string;
    id: string; 
}

export const RecordLink = (props: IRecordLinkProps) => {
    switch (props.type) {
        case "Yard":
            return <Link to={`/yard/${props.id}`}>{props.id}</Link>;
        case "Contact":
            return <Link to={`/contact/${props.id}`}>{props.id}</Link>;
        case "Member":
            return <Link to={`/member/${props.id}`}>{props.id}</Link>;
        case "Task":
            return <Link to={`/task/${props.id}`}>{props.id}</Link>;
    }
    return null;
};

function formatValue(data: any) {
    if (data.type == "Task" && data.property == "status") {
        return TaskStatus[data.value];
    } else if (isAddress(data.value)) {
        return DisplayUtils.getAddressString(data.value);
    }
    return data.value;
}

export const ChangeActionEntry = (props) => {
    const change: IChangeAction = props.change;
    const date = moment.utc(change.performedOn).local().format();

    let icon: any;
    let title;
    let desc: any;
    switch (change.type) {
        case ChangeActionType.ReScheduleTaskDueDate:
            {
                icon = Icons.CLOCK;
                title = "Task due-date re-scheduled";
                desc = <p>Due date changed to: <strong>{DisplayUtils.getLocalDisplay(change.data.newDate)}</strong></p>;
            }
            break;
        case ChangeActionType.ObjectPropertyChange:
            {
                icon = TwuIcons.PENCIL;
                title = `Record Changed (${change.data.type})`;
                //HACK: Address is a special case, it won't have a property name set and its value will be an IAddress
                if (change.data.property == null && typeof change.data.value === 'object') {
                    desc = <p><strong>Address</strong> of <strong>{change.data.type}</strong> (<RecordLink type={change.data.type} id={change.objectId} />) was changed to <strong>{formatValue(change.data)}</strong></p>;
                } else {
                    desc = <p>Field <strong>{change.data.property}</strong> of <strong>{change.data.type}</strong> (<RecordLink type={change.data.type} id={change.objectId} />) was changed to <strong>{formatValue(change.data)}</strong></p>;
                }
            }
            break;
        case ChangeActionType.NewMultiYardVisit:
            {
                icon = Icons.CLOCK;
                title = "Visit Started";
                let coords;
                if (change.data.visit.startLocation != null) {
                    coords = <p>{Icons.MAP} Coordinates: ({change.data.visit.startLocation.longitude} {change.data.visit.startLocation.latitude})</p>;
                }
                desc = <div>
                    <p>A visit was started for <strong>{change.data.yardIds.length}</strong> yards</p>
                    {coords}
                </div>;
            }
            break;
        case ChangeActionType.CompletedMultiYardVisit:
            {
                let duration;
                let yardRef;
                let coords;
                if (change.data.visit != null) {
                    const durVal = DisplayUtils.getVisitDuration(change.data.visit);
                    yardRef = <p>A visit was completed for <strong>{change.data.yardIds.length}</strong> yards</p>;
                    duration = <p>{Icons.CLOCK} Duration: <strong>{durVal}</strong></p>;
                    if (change.data.visit.endLocation != null) {
                        coords = <p>{Icons.MAP} Coordinates: ({change.data.visit.endLocation.longitude} {change.data.visit.endLocation.latitude})</p>;
                    }
                }
                icon = Icons.CLOCK;
                title = "Completed Visit";
                desc = <div>
                {(() => {
                    if (change.data.visit.visitNotes || change.data.visit.twuSuperNotes) {
                        return <div>
                            {yardRef}
                            {duration}
                            {coords}
                            <p><strong>Meeting summary</strong></p>
                            <p>{change.data.visit.visitNotes}</p>
                            <p><strong>TWU Super Notes</strong></p>
                            <p>{change.data.visit.twuSuperNotes}</p>
                        </div>;
                    } else {
                        return <div>
                            {yardRef}
                            {duration}
                            {coords}
                            <p>No meeting summary available</p>
                        </div>;
                    }
                })()}
                </div>;
            }
            break;
        case ChangeActionType.NewVisit:
            {
                icon = Icons.CLOCK;
                title = "Visit Started";
                let coords;
                if (change.data.visit.startLocation != null) {
                    coords = <p>{Icons.MAP} Coordinates: ({change.data.visit.startLocation.longitude} {change.data.visit.startLocation.latitude})</p>;
                }
                desc = <div>
                    <p>A visit was started at Yard <RecordLink type="Yard" id={change.data.yardId} /></p>
                    {coords}
                </div>;
            }
            break;
        case ChangeActionType.CompletedVisit:
            {
                let duration;
                let yardRef;
                let coords;
                if (change.data.visit != null) {
                    const durVal = DisplayUtils.getVisitDuration(change.data.visit);
                    yardRef = <p>Yard: (<RecordLink type="Yard" id={change.data.yardId} />)</p>;
                    duration = <p>{Icons.CLOCK} Duration: <strong>{durVal}</strong></p>;
                    if (change.data.visit.endLocation != null) {
                        coords = <p>{Icons.MAP} Coordinates: ({change.data.visit.endLocation.longitude} {change.data.visit.endLocation.latitude})</p>;
                    }
                }
                icon = Icons.CLOCK;
                title = "Completed Visit";
                desc = <div>
                {(() => {
                    if (change.data.visit.counts != null) {
                        return <div>
                            {yardRef}
                            {duration}
                            {coords}
                            <p>Meeting summary</p>
                            <ul>
                                <li><strong>{change.data.visit.counts.meetings}</strong> Meetings</li>
                                <li><strong>{change.data.visit.counts.conversations}</strong> Conversations</li>
                                <li><strong>{change.data.visit.counts.newMembers}</strong> New Members</li>
                            </ul>
                        </div>;
                    } else {
                        return <div>
                            {yardRef}
                            {duration}
                            {coords}
                            <p>No meeting summary available</p>
                        </div>;
                    }
                })()}
                </div>;
            }
            break;
        case ChangeActionType.NewTask:
            {
                icon = Icons.TICK;
                title = "New Task";
                desc = <div>
                    <p><strong>Title:</strong> {change.data.task.title}</p>
                    <p><strong>Relates to Member:</strong> <RecordLink type="Member" id={change.data.task.forMember} /></p>
                    <p><strong>Description:</strong> {change.data.task.description}</p>
                    <p><strong>Current Status:</strong> {TaskStatus[change.data.task.status]}</p>
                    <p><strong>Due:</strong> {moment.utc(change.data.task.dueOn).local().format()}</p>
                </div>;
            }
            break;
        case ChangeActionType.NewTaskNote:
            {
                icon = TwuIcons.PENCIL;
                title = "Note added to task";
                desc = <p>{change.data.note.notes}</p>;
            }
            break;
    }

    return <div className="timeline-item">
        <div className={`timeline-point ${change.isClientSide === true ? "timeline-point-warning" : "timeline-point-success"}`}>
            {icon}
        </div>
        <div className={`timeline-event ${change.isClientSide === true ? "timeline-event-warning" : "timeline-event-success"}`}>
            <div className="timeline-heading">
                <h4 className="timeline-title">{title}</h4>
            </div>
            <div className="timeline-body">
                {desc}
            </div>
            <div className="timeline-footer">
                <p className="text-right">{Icons.CLOCK} {date}</p>
            </div>
        </div>
    </div>;
};

export const PendingChangesTimeline = (props) => {
    return <div className="timeline timeline-line-dotted">
        {props.changes.map((chng, i) => <ChangeActionEntry key={`${i}`} change={chng} invert={i % 2 == 0} />)}
    </div>;
};

@connect(mapStateToProps, mapDispatchToProps)
export default class PendingChanges extends React.Component<IPendingChangesProps, any> {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            pendingChanges: null
        };
    }
    componentDidMount() {
        this.props.setTitle({ app: "Changes" });
        this.setState({ isLoading: true });
        getDataManagerAsync().then(manager => {
            this.setState({ isLoading: false, pendingChanges: manager.getPendingChanges().sort(changeActionDescendingDateComparator) });
        });
    }
    render(): JSX.Element {
        const { isLoading, pendingChanges } = this.state;
        if (isLoading === true) {
            return <BsLoadSpinner message="Loading pending changes ..." />;
        } else {
            if (pendingChanges.length > 0) {
                return <div className="container">
                    <h4>{pendingChanges.length} Pending Changes</h4>
                    <hr />
                    <PendingChangesTimeline changes={pendingChanges} />
                </div>;
            } else {
                return <AlertPanel kind={AlertStyleKind.Bootstrap} theme={AlertPanelTheme.Info}>
                    {Icons.INFO} No pending changes found
                </AlertPanel>;
            }
        }
    }
}