import LokiIndexedAdapter = require("lokijs/src/loki-indexed-adapter.js");
import crypto = require("crypto-js");
import * as localforage from "localforage";
import { ensureCollections } from "./collections";
import debounce = require('lodash.debounce');
const jwt = require('jwt-simple');
import * as moment from "moment";
import { DataManager } from '../api/data-manager';
import { logInfo, logTimeEnd, logTimeStart } from "../utils/logger";

export type LokiCollection<T extends object> = Collection<T>;
// setup lokijs

interface IAESCfg extends crypto.lib.IBlockCipherCfg {
    format;
}
const JsonFormatter = {
    stringify: function (cipherParams) {
        // create json object with ciphertext
        const jsonObj: any = {
            ct: cipherParams.ciphertext.toString(crypto.enc.Base64)
        };

        // optionally add iv and salt
        if (cipherParams.iv) {
            jsonObj.iv = cipherParams.iv.toString();
        }
        if (cipherParams.salt) {
            jsonObj.s = cipherParams.salt.toString();
        }

        // stringify json object
        return JSON.stringify(jsonObj);
    },

    parse: function (jsonStr) {
        // parse json string
        const jsonObj = JSON.parse(jsonStr);

        // extract ciphertext from json object, and create cipher params object
        const cipherParams = crypto.lib.CipherParams.create({
            ciphertext: crypto.enc.Base64.parse(jsonObj.ct)
        });

        // optionally extract iv and salt
        if (jsonObj.iv) {
            cipherParams.iv = crypto.enc.Hex.parse(jsonObj.iv);
        }
        if (jsonObj.s) {
            cipherParams.salt = crypto.enc.Hex.parse(jsonObj.s);
        }

        return cipherParams;
    }
};

const encryptionConfig: IAESCfg = { format: JsonFormatter };

function encrypt(data, key) {
    return crypto.AES.encrypt(data, key, encryptionConfig).toString();
}

function decrypt(data, key) {
    if (!data) {
        return;
    }
    return crypto.AES.decrypt(data, key, encryptionConfig).toString(crypto.enc.Utf8);
}


class LokiEncryptedAdapter {
    encryptionKey: string;
    inner: LokiIndexedAdapter;
    app: string;

    /** IndexedAdapter - Loki persistence adapter class for indexedDb.
    *     This class fulfills abstract adapter interface which can be applied to other storage methods
    *     Utilizes the included LokiCatalog app/key/value database for actual database persistence.
    * @param {string} appname - Application name context can be used to distinguish subdomains or just 'loki'
    */

    constructor(fileName, encryptionKey: string) {
        this.inner = new LokiIndexedAdapter(fileName);
        this.app = this.inner.app = "CCM";
        this.encryptionKey = encryptionKey;

    }


    // /** checkAvailability - used to check if adapter is available
    //  * @returns {boolean} true if indexeddb is available, false if not.
    //  */
    // checkAvailability() {
    //     return this.inner.checkAvailability();
    // }

    // alias for loadDatabase
    loadKey(dbname: string, callback?: (data: any) => void): void {
        this.loadDatabase(dbname, callback);
    }


    // alias for saveDatabase
    saveKey(dbname: string, dbstring: string, callback?: (err: Error | null) => void): void {
        this.saveDatabase(dbname, dbstring, callback);
    }

    /** deleteDatabase() - Deletes a serialized db from the catalog.
     * @param {string} dbname - the name of the database to delete from the catalog.
     */
    deleteDatabase(dbname: string): void {
        this.inner.deleteDatabase(dbname, () => { });
    }

    // alias for deleteDatabase
    deleteKey(dbname: string): void {
        this.deleteDatabase(dbname);
    }

    // /** getDatabaseList() - Retrieves object array of catalog entries for current app.
    //  * @param {function} callback - should accept array of database names in the catalog for current app.
    //  */
    // getDatabaseList(callback: (names: string[]) => void): void {
    //     this.inner.getDatabaseList(callback);
    // }

    // // alias for getDatabaseList
    // getKeyList(callback: (names: string[]) => void): void {
    //     this.inner.getKeyList(callback);
    // }

    // /** getCatalogSummary - allows retrieval of list of all keys in catalog along with size
    //  * @param {function} callback - (Optional) callback to accept result array.
    //  */
    // getCatalogSummary(callback: (entries: { app: string; key: string; size: number; }) => void): void {
    //     this.inner.getCatalogSummary(callback);
    // }

    /** loadDatabase() - Retrieves a serialized db string from the catalog.
     * @param {string} dbname - the name of the database to retrieve.
     * @param {function} callback - callback should accept string param containing serialized db string.
     */
    loadDatabase(dbname: string, callback?: (data: any) => void): void {
        logInfo('loading encrypted');
        const cb = e => {
            logTimeStart("decrypt idb");
            const decoded = decrypt(e, this.encryptionKey);
            logTimeEnd("decrypt idb");
            if (callback) callback(decoded);
        };

        this.inner.loadDatabase(dbname, cb);
    }

    /** saveDatabase() - Saves a serialized db to the catalog.
     * @param {string} dbname - the name to give the serialized database within the catalog.
     * @param {string} dbstring - the serialized db string to save.
     * @param {function} callback - (Optional) callback passed obj.success with true or false
     */
    saveDatabase(dbname: string, dbstring: string, callback?: (err: Error | null) => void): void {
        logInfo('saving encrypted');
        console.log(JSON.parse(dbstring));

        logTimeStart("encrypt idb");
        const enc = encrypt(dbstring, this.encryptionKey);
        logTimeEnd("encrypt idb");
        this.inner.saveDatabase(dbname, enc, callback);
    }
}

export function getLocalEncryptionKey(username: string) {
    return localStorage[`UserKey${username}`];
}

function setLocalEncryptionKey(username: string, key: string) {
    localStorage["UserKey" + username] = key;
}

export function removeEncryptionKey(username: string) {
    delete localStorage[`UserKey${username}`];
}

export const OUR_ERROR_REGEX = /\(\d+\)$/g

export async function attemptLocalLogin(username: string, password: string): Promise<any> {
    // check if the local storage succeeds
    try {
        const encryptedKey = getLocalEncryptionKey(username);
        if (!encryptedKey) {
            throw new Error("No encryption key (116)");
        }
        const data = decrypt(encryptedKey, password);
        if (!data) {
            throw new Error("Decryption returned nothing (117)");
        }
        logInfo(data);
        const session = JSON.parse(data);
        // ensure we can decode the token without verifying the signature (the server will do this)
        const details = jwt.decode(session.token, null, true);
        const unix = moment.utc().unix();
        if (details.exp < unix) {
            throw new Error("Local encryption key expired (113)");
        }
        await setupStore(username, session.user.key);
        return session;
    } catch (e) {
        if (e instanceof Error && e.message.match(OUR_ERROR_REGEX)) {
            throw e;
        }
        throw new Error(`${(e as any).message} (115)`);
    }
}

export function persistDetails(username: string, password: string, details: any) {
    setLocalEncryptionKey(username, encrypt(JSON.stringify(details), password));
    setupStore(username, details.user.key);
}

let store: PersistenceStore;

class PersistenceStore {
    username: string;
    key: string;


    // todo encryption
    constructor(username: string, key: string) {
        this.username = username.toLocaleLowerCase();
        this.key = key;
    }


    private _collections: { [key: string]: any[] } = {}


    public async loadAsync() {
        const collections = await localforage.getItem<string[]>(this.persistenceName());
        if (!collections) return; // do nothing if we don't have anything stored

        try {
            for (const c of collections) {
                const encryptedJson = await localforage.getItem<string>(this.persistenceCollectionName(c));

                // skip attempting to decrypt if there is no data
                if (!encryptedJson) continue;
                const collectionJson = decrypt(encryptedJson, this.key);

                this._collections[c] = JSON.parse(collectionJson);
            }
        } catch (e) {
            alert('Failed to decrypt the local data');
            throw e;
        }

    }
    private persistenceCollectionName = (c: string) => `E_${this.username}_${c}`;
    private persistenceName = () => `_${this.username}_collections`;
    

    public async saveAllAsync() {
        const collections = this.getCollections();

        for (const c of collections) {
            const collectionJson = JSON.stringify(this._collections[c]);
            const encryptedJson = encrypt(collectionJson, this.key);

            await localforage.setItem(this.persistenceCollectionName(c), encryptedJson);
        }

        // collection list can remain unencrypted
        await localforage.setItem<string[]>(this.persistenceName(), collections);
    }

    private getCollections() {
        return Object.keys(this._collections);
    }

    public getOrAddCollection<T>(name: string) {
        if (!this._collections[name]) {
            this._collections[name] = [];
        }
        return this._collections[name];
    }

    
    public clear() {
        for (const c of this.getCollections()) {
            this._collections[c].length = 0;
        }
    }

}

export function ensureCollectionUnique<T extends object>(name: string, uniques: string | string[]): Promise<T[]> {
    const func = async () => ensureCollection<T>(name);

    return waitForLoki(func);
}


function waitForLoki<T>(func: () => Promise<T>): Promise<T> {
    return new Promise<T>((resolve, reject) => {
        if (ready) {
            resolve(func());
        } else {
            waitingRequests.push(() => resolve(func()));
        }
    });
}


async function ensureCollection<T extends object>(name: string): Promise<T[]> {
    return (await store.getOrAddCollection(name)) as T[];
}

let waitingRequests: Function[] = [];
let ready = false;

export function lokiJsArrayHack<T>(resultSet: any): T[] {
    if (resultSet.data) {
        resultSet = resultSet.data;
    }
    if (resultSet instanceof Array) {
        return <T[]>resultSet;
    } else {
        return resultSet.data();
    }
}

export async function setupStore(username: string, key: string): Promise<any> {
    ready = false;
    waitingRequests = [];


    // encryptionAdapter = new LokiEncryptedAdapter(username, key);

    store = new PersistenceStore(username, key);
    // loki = new Loki(username + "_data", { adapter: encryptionAdapter });
    await store.loadAsync()
    ready = true;
    ensureCollections();
    const saveRequests = waitingRequests;
    waitingRequests = [];

    if (saveRequests && saveRequests.length) {
        for (const req of saveRequests) {
            req();
        }
    }



}

export function clearLocalData(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
        store.clear();
        store.saveAllAsync()
            .then(() => resolve(true))
            .catch(e => reject(e));
    });
}

const SAVE_DEBOUNCE_INTERVAL = 1;

export const save = debounce((onSuccess?: () => void, onFailure?: (e) => void) => {

    const p = store.saveAllAsync();
    if (onSuccess) p.then(onSuccess);

    // //TODO: It would be nice to offload the encryption/serialization to a web worker
    // loki.save(e => {
    //     if (e) {
    //         logError(e);
    //         if (onFailure != null) {
    //             onFailure(e);
    //         }
    //     } else {
    //         if (onSuccess != null) {
    //             onSuccess();
    //         }
    //     }
    // });
}, SAVE_DEBOUNCE_INTERVAL);

window["__DEV_SAVE__"] = save;

export function savePromised(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
        save(() => resolve(true), reject);
    });
}


async function test(yard: number = 72) {
    const yards = [yard];
    const dm = window["dataManager"] as DataManager;
    // begin a visit on yard 72
    const newVisit = dm.addMultiYardVisit(yards, { startDate: "2019-01-01", startTime: "16:22:00" });

    await savePromised();

    dm.completeMultiYardVisit(newVisit.id, { endDate: "2019-02-02", endTime: "16:12:33", twuSuperNotes: "TEST", visitNotes: "AAA" });
    // complete the visit
    // save and
    await savePromised();
}

async function test2(yard: number = 72) {
    const dm = window["dataManager"] as DataManager;
    console.log("YARD", dm.getYardById(yard));
}


window["__DEV_TEST__"] = test;
window["__DEV_TEST2__"] = test2;