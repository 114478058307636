// constants/index.ts
//
// This module contains all the constants used by this application

export const LOGOUT_USER = 'App/LOGOUT_USER';
export const NAVIGATOR_OFFLINE = 'App/NAVIGATOR_OFFLINE';
export const NAVIGATOR_ONLINE = 'App/NAVIGATOR_ONLINE';

export const LOGIN_USER_PENDING = 'App/LOGIN_USER_PENDING';
export const LOGIN_USER_SUCCESS = 'App/LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'App/LOGIN_USER_ERROR';
export const LOGIN_PROGRESS_MESSAGE = 'App/LOGIN_PROGRESS_MESSAGE';

export const FETCH_YARDS_PENDING = 'App/FETCH_YARDS_PENDING';
export const FETCH_YARDS_SUCCESS = 'App/FETCH_YARDS_SUCCESS';
export const FETCH_YARDS_ERROR = 'App/FETCH_YARDS_ERROR';

export const FETCH_TASKS_PENDING = 'App/FETCH_TASKS_PENDING';
export const FETCH_TASKS_SUCCESS = 'App/FETCH_TASKS_SUCCESS';
export const FETCH_TASKS_ERROR = 'App/FETCH_TASKS_ERROR';

export const FETCH_TASK_DETAILS_PENDING = 'App/FETCH_TASK_DETAILS_PENDING';
export const FETCH_TASK_DETAILS_SUCCESS = 'App/FETCH_TASK_DETAILS_SUCCESS';
export const FETCH_TASK_DETAILS_ERROR = 'App/FETCH_TASK_DETAILS_ERROR';

export const FETCH_TASKS_FOR_YARD_PENDING = 'App/FETCH_TASKS_FOR_YARD_PENDING';
export const FETCH_TASKS_FOR_YARD_SUCCESS = 'App/FETCH_TASKS_FOR_YARD_SUCCESS';
export const FETCH_TASKS_FOR_YARD_ERROR = 'App/FETCH_TASKS_FOR_YARD_ERROR';

export const FETCH_TASKS_FOR_MEMBER_PENDING = 'App/FETCH_TASKS_FOR_MEMBER_PENDING';
export const FETCH_TASKS_FOR_MEMBER_SUCCESS = 'App/FETCH_TASKS_FOR_MEMBER_SUCCESS';
export const FETCH_TASKS_FOR_MEMBER_ERROR = 'App/FETCH_TASKS_FOR_MEMBER_ERROR';

export const FETCH_YARD_DETAILS_PENDING = 'App/FETCH_YARD_DETAILS_PENDING';
export const FETCH_YARD_DETAILS_SUCCESS = 'App/FETCH_YARD_DETAILS_SUCCESS';
export const FETCH_YARD_DETAILS_ERROR = 'App/FETCH_YARD_DETAILS_ERROR';

export const FETCH_MEMBERS_PENDING = 'App/FETCH_YARDS_PENDING';
export const FETCH_MEMBERS_SUCCESS = 'App/FETCH_YARDS_SUCCESS';
export const FETCH_MEMBERS_ERROR = 'App/FETCH_YARDS_ERROR';

export const FETCH_MEMBER_DETAILS_PENDING = 'App/FETCH_MEMBER_DETAILS_PENDING';
export const FETCH_MEMBER_DETAILS_SUCCESS = 'App/FETCH_MEMBER_DETAILS_SUCCESS';
export const FETCH_MEMBER_DETAILS_ERROR = 'App/FETCH_MEMBER_DETAILS_ERROR';

export const FETCH_CONTACT_DETAILS_PENDING = 'App/FETCH_CONTACT_DETAILS_PENDING';
export const FETCH_CONTACT_DETAILS_SUCCESS = 'App/FETCH_CONTACT_DETAILS_SUCCESS';
export const FETCH_CONTACT_DETAILS_ERROR = 'App/FETCH_CONTACT_DETAILS_ERROR';

export const FETCH_NONMEMBER_DETAILS_PENDING = 'App/FETCH_NONMEMBER_DETAILS_PENDING';
export const FETCH_NONMEMBER_DETAILS_SUCCESS = 'App/FETCH_NONMEMBER_DETAILS_SUCCESS';
export const FETCH_NONMEMBER_DETAILS_ERROR = 'App/FETCH_NONMEMBER_DETAILS_ERROR';

export const FETCH_CONTACTS_PENDING = 'App/FETCH_YARDS_PENDING';
export const FETCH_CONTACTS_SUCCESS = 'App/FETCH_YARDS_SUCCESS';
export const FETCH_CONTACTS_ERROR = 'App/FETCH_YARDS_ERROR';

export const ASSIGN_TASK_PENDING = 'App/ASSIGN_TASK_PENDING';
export const ASSIGN_TASK_SUCCESS = 'App/ASSIGN_TASK_SUCCESS';
export const ASSIGN_TASK_ERROR = 'App/ASSIGN_TASK_ERROR';

export const UPDATE_TASK_STATUS_PENDING = 'App/UPDATE_TASK_STATUS_PENDING';
export const UPDATE_TASK_STATUS_SUCCESS = 'App/UPDATE_TASK_STATUS_SUCCESS';
export const UPDATE_TASK_STATUS_ERROR = 'App/UPDATE_TASK_STATUS_ERROR';

export const UPDATE_TASK_DUE_PENDING = 'App/UPDATE_TASK_DUE_PENDING';
export const UPDATE_TASK_DUE_SUCCESS = 'App/UPDATE_TASK_DUE_SUCCESS';
export const UPDATE_TASK_DUE_ERROR = 'App/UPDATE_TASK_DUE_ERROR';

export const ADD_NOTE_PENDING = 'App/ADD_NOTE_PENDING';
export const ADD_NOTE_SUCCESS = 'App/ADD_NOTE_SUCCESS';
export const ADD_NOTE_ERROR = 'App/ADD_NOTE_ERROR';

export const VISIT_START = 'App/VISIT_START';
export const VISIT_STOP = 'App/VISIT_STOP';
export const VISIT_TICK = 'App/VISIT_TICK';
export const VISIT_RESUME = 'App/VISIT_RESUME';
export const VISIT_POSTPONE = 'App/VISIT_POSTPONE';

export const SET_ACTIVE_YARD = 'App/SET_ACTIVE_YARD';

export const SET_TITLE = 'App/SET_TITLE';

export const FORM_RESET = 'redux-form/RESET';

/**
 * Non-breaking space
 */
export const NBSP = String.fromCharCode(160);