import * as Constants from "../constants";
import * as moment from "moment";
import { addVisit, completeVisit } from "../api/visit";

export function resumeVisit(options) {
    return (dispatch, getState) => {
        const interval = window.setInterval(() => {
            dispatch({
                type: Constants.VISIT_TICK
            });
        }, 1000);
        dispatch({
            type: Constants.VISIT_RESUME,
            payload: {
                interval: interval
            }
        });
    };
}

export function startVisit(options) {
    return (dispatch, getState) => {
        const interval = window.setInterval(() => {
            dispatch({
                type: Constants.VISIT_TICK
            });
        }, 1000);
        const date = moment.utc();
        addVisit(options.yardIds, {
            startDate: date.format("YYYY-MM-DD"),
            startTime: date.format("HH:mm:ss"),
            startLocation: options.location
        }).then(visit => {
            dispatch({
                type: Constants.VISIT_START,
                payload: {
                    start: date.toISOString(),
                    interval: interval,
                    visitId: visit.id,
                    yardIds: options.yardIds
                }
            });
        });
    };
}

export function endVisit(options) {
    window.clearInterval(options.interval);
    return (dispatch, getState) => {
        let visitId;
        let date;
        if (options.visitId && options.endDate) { //This came from the incomplete visit screen
            date = moment.utc(options.endDate);
            visitId = options.visitId;
        } else { //It came from the visit tracker
            const visit = getState().visit;
            date = moment.utc(visit.end);
            visitId = visit.data.visitId;
        }
        completeVisit(visitId, {
            endDate: date.format("YYYY-MM-DD"),
            endTime: date.format("HH:mm:ss"),
            endLocation: options.location,
            visitNotes: options.visitNotes,
            twuSuperNotes: options.twuSuperNotes
        });
        dispatch({
            type: Constants.VISIT_STOP
        });
    };
}

export function postponeEndVisit(options) {
    window.clearInterval(options.interval);
    return {
        type: Constants.VISIT_POSTPONE,
        payload: {
            visitId: options
        }
    };
}