import * as React from "react";
import { Link } from "react-router-dom";
import { ContactCard } from "./cards";
import { IContact } from "../api/twu-contracts";
import { Icons } from "./icons";
import { AlertPanel, AlertPanelTheme, AlertStyleKind } from "./common";

export interface IPersonListProps {
    people?: IContact[];
    emptyMessage?: string;
    selectionRoute: (id: number) => string;
}

export const PersonList = (props: IPersonListProps) => {
    const { people, emptyMessage, selectionRoute } = props;

    if (people && people.length > 0) {
        return <div className='list-group'>
            {people.map((result) => {
                return (
                    <div key={result.id} className='list-group-item'>
                        <Link to={selectionRoute(result.id)}>
                            <ContactCard style="Basic" {...result} />
                        </Link>
                    </div>
                );
            })}
        </div>;
    } else {
        return <AlertPanel theme={AlertPanelTheme.Info} kind={AlertStyleKind.Bootstrap}>
            {Icons.INFO} {emptyMessage || `No people found`}
        </AlertPanel>;
    }
};