import * as React from "react";
import { Link } from "react-router-dom";
import { DataManager, getCountsAsync } from "../../api/data-manager";
import moment = require("moment");
import { Icons } from "../icons";
import { strIsNullOrEmpty } from "../../utils/string";

function since(utcDate: string | undefined) {
    if (strIsNullOrEmpty(utcDate)) {
        return "never";
    } else {
        return moment.utc(utcDate).fromNow();
    }
}

export class HomePageComponent extends React.Component<any, any> {
    private fnLogout: (e) => void;
    constructor(props) {
        super(props);
        this.fnLogout = this.onLogout.bind(this);
        this.state = {
            yardCount: null,
            taskCount: null,
            pendingCount: null,
            visitCount: null
        };
    }
    private onLogout(e) {
        this.props.onLogout();
    }
    componentDidMount() {
        getCountsAsync().then(c => {
            this.setState({
                yardCount: c.yards,
                taskCount: c.tasks,
                pendingCount: c.pending,
                visitCount: c.incompleteVisits
            });
        });
    }
    render(): JSX.Element {
        const { displayName, online } = this.props;
        const { taskCount, yardCount, pendingCount, visitCount, lastPushDate, lastPullDate, ready } = this.state;
        const arrow = <span className="pull-right">{Icons.CHEV_RIGHT}</span>;
        const yardBadge = yardCount != null ? <span className="badge">{yardCount}</span> : <span className="pull-right">{Icons.REFRESH_SPIN}</span>;
        const visitBadge = visitCount != null ? <span className="badge">{visitCount}</span> : <span className="pull-right">{Icons.REFRESH_SPIN}</span>;
        const countBadge = taskCount != null ? <span className="badge">{taskCount}</span> : <span className="pull-right">{Icons.REFRESH_SPIN}</span>;
        const pendingBadge = taskCount != null ? <span className="badge">{pendingCount}</span> : <span className="pull-right">{Icons.REFRESH_SPIN}</span>;
        const items: any[] = [];
        items.push({ icon: Icons.HOME, text: `Welcome ${displayName}!`, arrow: false, route: null, onlineRequired: false, onClick: null, extraClass: 'list-group-item-info' });
        items.push({ icon: Icons.LIST, text: 'Yards', arrow: true, badge: yardBadge, onlineRequired: false, route: '/yards' });
        items.push({ icon: Icons.TICK, text: 'Tasks', arrow: true, badge: countBadge, onlineRequired: false, route: '/tasks' });
        items.push({ icon: Icons.CALENDAR, text: 'Incomplete Visits', arrow: true, badge: visitBadge, onlineRequired: false, route: '/visits' });
        items.push({ icon: Icons.SEARCH, text: 'Member Search', arrow: true, onlineRequired: false, route: '/membersearch' });
        items.push({ icon: Icons.REFRESH, text: 'Synchronise Data', arrow: true, onlineRequired: true, route: '/sync' });
        items.push({ icon: Icons.UPLOAD, text: `Last Push: ${since(DataManager.getLastPushDate())}`, arrow: false, route: null, onlineRequired: false, extraClass: 'list-group-item-info' });
        items.push({ icon: Icons.DOWNLOAD, text: `Last Pull: ${since(DataManager.getLastPullDate())}`, arrow: false, route: null, onlineRequired: false, extraClass: 'list-group-item-info' });   
        items.push({ icon: Icons.UPLOAD, text: 'Pending Changes', badge: pendingBadge, arrow: true, route: '/pending', onlineRequired: false, onClick: null });
        items.push({ icon: Icons.TRASH, text: 'Clear Data', arrow: true, route: '/cleardata', onlineRequired: false, onClick: null });
        items.push({ icon: Icons.INFO, text: 'About', arrow: true, route: '/about', onlineRequired: false, onClick: null });
        items.push({ icon: Icons.POWER_OFF, text: 'Logout', arrow: true, route: null, onlineRequired: false, onClick: this.fnLogout, extraClass: 'link-like list-group-item-info' });

        return <div className="list-group"> 
            {items.map((item, index) => {
                if (!online && item.onlineRequired) {
                    return null;
                }
                if (item.route) {
                    return <Link key={index} to={item.route} className={`list-group-item ${item.extraClass}`}>
                        {item.icon} {item.text} {item.arrow ? arrow : null} {item.badge}
                    </Link>;
                } else {
                    return <div key={index} className={`list-group-item ${item.extraClass}`} onClick={item.onClick}>
                        <strong>{item.icon} {item.text}</strong>
                    </div>;
                }
            })}
        </div>; 
    }
}