import {
    IChangeAction,
    IImmutableObjectWithChanges} from "../api/twu-contracts";
import { savePendingChanges } from "../api/editable";
import * as ChangeUtils from "../api/change-utils";

export interface IEditableFieldComponent {
    hasChanged(): boolean;
    getPropertyName(): string | undefined;
    getChangedValue(arg: boolean): any;
}

export interface ISaveEditableOptions<T> {
    editableObject: T;
    editableFields: IEditableFieldComponent[];
    type: ChangeUtils.EditableObjectType;
    idSelector: ChangeUtils.IdSelector<T>;
}

function collectItemChanges<T extends (IImmutableObjectWithChanges & { id: string|number })>(args: ISaveEditableOptions<T>, callback: (result?: T, error?: Error) => void) {
    //Collect changes
    const changes: IChangeAction[] = [];
    for (const editable of args.editableFields) {
        const pn = editable.getPropertyName(); //Property name can be null if editing address
        if (editable.hasChanged()) {
            const change = ChangeUtils.collectObjectChange(args.editableObject, args.type, pn, args.idSelector, () => editable.getChangedValue(true));
            changes.push(change);
        }
    }
    savePendingChanges(args.editableObject, args.type, changes).then(res => {
        callback(res);
    }).catch(err => {
        callback(undefined, err);
    });
}

export function collectItemChangesAsync<T extends (IImmutableObjectWithChanges & { id: string|number })>(args: ISaveEditableOptions<T>): Promise<T> {
    return new Promise<T>((resolve, reject) => {
        collectItemChanges(args, (result, error) => {
            if (result != null) {
                resolve(result);
            } else {
                reject(error);
            }
        });
    });
}