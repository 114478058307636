import * as React from "react";

interface IDisplayFieldProps {
    /**
     * The icon class to use (omit the "icon-" prefix)
     */
    iconClass: string;
    label: string;
    value?: any;
    children?: any;
    iconKind?: string;
    /**
     * Determines whether to hide this component if value is empty
     */
    hideIfValueEmpty?: boolean;
    emptyValue?: any;

    /**
    * The bootstrap col-sm size
    */
    labelSize?: number;

    /**
    * The bootstrap col-xs size
    */
    labelSmallSize?: number;
}

interface IDisplayFieldState {
    hideIfValueEmpty: boolean;
    emptyValue: any;
}

export class DisplayField extends React.Component<IDisplayFieldProps, IDisplayFieldState> {
    constructor(props: IDisplayFieldProps) {
        super(props);
        this.state = {
            emptyValue: (props.emptyValue || "(not specified)"),
            hideIfValueEmpty: (props.hideIfValueEmpty == true)
        };
    }
    private format(str: string): JSX.Element {
        if (str == this.state.emptyValue) {
            return <span>{str}</span>;
        } else {
            //UGLY HACK: This component does not know the semantics of the field being displayed
            //we are only inferring by the icon used
            switch (this.props.iconClass) {
                case "phone":
                case "mobile":
                    return <a href={`tel:${str}`}>{str}</a>;
                case "mail":
                    return <a href={`mailto:${str}`}>{str}</a>;
                default:
                    return <span>{str}</span>;
            }
        }
    }
    render() {
        if (this.props.value == null && this.state.hideIfValueEmpty) {
            return null;
        }
        
        const value = this.props.value ? this.props.value : this.state.emptyValue;
        
            
        const labelSize = this.props.labelSize || 2;
        const fieldSize = 12 - labelSize;

        const labelSmallSize = this.props.labelSmallSize || 6;
        const fieldSmallSize = 12 - labelSmallSize;
        
        const body = 
            (<div className="form-group">
                <label className={`col-sm-${labelSize} col-xs-${labelSmallSize} control-label`}>
                    <i className={`fa ${this.props.iconKind || "fa"}-${this.props.iconClass}`}></i>&nbsp;{this.props.label}
                </label>
                <div className={`col-sm-${fieldSize} col-xs-${fieldSmallSize}`}>
                    <p className="form-control-static">{this.format(value)}{this.props.children}</p>
                </div>
            </div>);
        
        return body;
    }
}