import * as React from "react";
import { IYard, YardSortType, SortDirection } from "../../api/twu-contracts";
import { YardList } from "../yard-list";

interface IYardsPageComponentProps {
    yards: Readonly<IYard[]>;
    sort: Readonly<YardSortType[]>;
    sortDir: SortDirection;
    searchText: string;
    scroll: number;
    onSearchTextChanged: (text: string) => void;
    onScrollChanged: (scroll: number) => void;
}

export function YardsPageComponent({ yards, sort, sortDir, onSearchTextChanged, onScrollChanged, searchText, scroll }: IYardsPageComponentProps) {
    return <YardList yards={yards}
        sort={sort}
        searchText={searchText}
        scroll={scroll}
        onSearchTextChanged={onSearchTextChanged}
        onScrollChanged={onScrollChanged}
        sortDirection={sortDir} />;

}