import * as Constants from "../constants";
import { setupInitialState, buildReducerFunction, IReducerBranchStateImmutable, IReducerConfiguration } from "./utils";


type IContactImmutable = {};

const INITIAL_STATE: IReducerBranchStateImmutable<IContactImmutable> = setupInitialState<IContactImmutable>();

const config: IReducerConfiguration<IContactImmutable> = {
    initialState: INITIAL_STATE,
    pending: {
        key: Constants.FETCH_CONTACT_DETAILS_PENDING
    },
    error: {
        key: Constants.FETCH_CONTACT_DETAILS_ERROR
    },
    success: {
        key: Constants.FETCH_CONTACT_DETAILS_SUCCESS
    }
};

const contactReducer = buildReducerFunction(config);

export default contactReducer;