/**
 * twu-contracts.ts
 * 
 * Contains global API contracts for the TWU yard app
 */

export enum LoginMode {
    Regular,
    Volunteering,
    SingleUsePIN,
    VolunteeringPin
}

export interface IdNamePair  { 
    /**
     * The id of the object
     * System.Int32 NotNullable Primitive
     */
    Id: number;
    /**
     * The name of the object
     * System.String NotNullable Primitive
     */
    Name?: string; 
}

/**
 * A client authentication handle for mobile apps
 */
export interface IApiContext {
    token: string;
    officeID?: number;
    pin?: number;
}

export interface IProgress {
    msg?: string;
    processed: number;
    total: number;
}

export enum EditableObjectType {
    Yard,
    Contact,
    Member,
    Task
}

export interface IPersonCardProps extends IComponentProps {
    firstName: string;
    lastName: string;
    phone?: string;
    mobile?: string;
    email?: string;
    style?: string;
}

export interface IContactTitle {
    name: string;
}

export interface IComponentProps {
    className?: string;
}

export interface IComponentPersistentState {
    id: string;
    state: any;
}

export interface IContactCardProps extends IPersonCardProps, IComponentProps {
    title: string;
}

/**
 * Defines a geographic coordinate
 */
export interface IGeographicCoordinate {
    longitude: number;
    latitude: number;
}

/**
 * Defines an address
 */
export interface IAddress {
    address: string;
    suburb: string;
    state: string;
    postcode: string;
}

export function isAddress(addr: any): addr is IAddress {
    return addr != null
        && typeof addr.address !== "undefined"
        && typeof addr.suburb !== "undefined"
        && typeof addr.state !== "undefined"
        && typeof addr.postcode !== "undefined";
}

/**
 * Defines an entity that has an address (and optional geographic location)
 */
export interface IAddressable extends IAddress {
    location?: IGeographicCoordinate;
    distanceFrom?: number;
}

/**
 * Defines a syncable entity
 */
export interface ISyncable {
    /**
     * A globally unique id. This is generated client side
     */
    id: string;
    /**
     * A server-assigned unique id. Absence of this id indicates the entity has been created client-side, but it
     * has not been synced up with the server, nor do any other clients know about it
     */
    serverId?: number;
}

/**
 * A type of pending change action
 */
export enum ChangeActionType {
    /**
     * A new task is created
     */
    NewTask,
    /**
     * OBSOLETE - A new visit (for an existing yard) is created
     */
    NewVisit,
    /**
     * OBSOLETE - An existing visit (for an existing yard) has been completed
     */
    CompletedVisit,
    /**
     * A property for an existing object was changed
     */
    ObjectPropertyChange,
    /**
     * A new note (for an existing task) is created
     */
    NewTaskNote,
    AssignTaskToUser,
    /**
     * For starting a visit against *multiple* yards
     */
    NewMultiYardVisit,
    /**
     * For completing a multi-yard visit
     */
    CompletedMultiYardVisit,
    /**
     * 
     */
    ReScheduleTaskDueDate
}

/**
 * Models a change to a particular object
 */
export interface IChangeAction {
    /**
     * A globally unique identifier
     */
    id: string;
    /**
     * The id of the subject that the change applies to. For non-syncable objects, this is normally
     * the object's server-designated id. For syncable objects, it should generally refer to the
     * app-generated unique identifier
     */
    objectId: any;
    /**
     * The type of action change
     */
    type: ChangeActionType;
    /**
     * Data related to the change
     */
    data: any;
    /**
     * The date this change was performed on
     */
    performedOn: string;
    /**
     * Indicates if this change action is client-side generated. A change action that has been acknowledged server-side will
     * not have this property set.
     * 
     * When stashing new property changes, they should have isClientSide = true
     * When pushing up changes, only change actions where isClientSide === true should be sent. 
     */
    isClientSide?: boolean;
}

/**
 * Defines an immutable object, with an associated set of pending property changes that can be overlaid when presenting
 */
export interface IImmutableObjectWithChanges {

    /**
     * A set of pending property changes for a given object. DO NOT SET DIRECTLY
     */   
    changes: IChangeAction[];
}

export interface IYardDetailsUpdateRequest {
    yardId: number;
    details: string;
    imageBase64: string;
}

export interface IScheduleVisitResult {
    id: number;
}

export interface ICreateScheduledVisit {
    yardId: number;
    organiserId: number;
    message: string;
    scheduledDate: string;
}

/**
 * Defines a scheduled visit for a yards
 */
export interface IScheduledVisit {
    id: number;
    organiserId: number;
    message: string;
    scheduledDate: string;
}

/**
 * Defines the start of a yard visit
 */
export interface IVisitStart { 
    startDate: string;
    startTime: string;
    startLocation?: IGeographicCoordinate;
}

/**
 * Defines the end of a yard visit
 */
export interface IVisitEnd {
    endDate?: string;
    endTime?: string;
    endLocation?: IGeographicCoordinate;
    visitNotes?: string;
    twuSuperNotes?: string;
}

/**
 * Defines a yard visit
 */
export interface IVisit extends ISyncable, IVisitStart, IVisitEnd {
    yardIds: number[];
}

export enum TaskStatus {
    New,
    InProgress,
    Complete,
    NotComplete //This is a "virtual" status, and is simply a negation of the "Complete" status
}

export interface ITask extends ISyncable, IImmutableObjectWithChanges {
    forMember: number;
    forVisit?: string;
    assignedToUser?: string;
    title: string;
    description: string;
    status: TaskStatus;
    dueOn: string;
    notes: ITaskNote[];
}

export interface ITaskNote extends ISyncable { //TODO: Remove syncable traits. Notes belong to a task
    notes: string;
    date: string;
}

export interface ITaskDetails {
    id: string;
    serverId?: number;
    forVisit?: IVisit;
    assignedToUser?: number | string;
    assignedToMember: IMember;
    title: string;
    description: string;
    status: TaskStatus;
    dueOn: string;
}

export interface IYardInteraction {
    id: number;
    topic: string;
    details: string;
    link?: string;
    date: string;
}

export interface IYard extends IAddressable, IImmutableObjectWithChanges {
    id: number;
    name: string;
    yardCode: string;
    conditionsOfEntry: string[];
    visits?: IVisit[];
    totalEmployees: number;
    scheduledVisits: IScheduledVisit[];
    interactions: IYardInteraction[];
    details: string;
    postalAddress: string;
    phone: string;
    mobile: string;
    email: string;
    fax: string;
}

export function isPriorityVisit(yard: IYard) {
    //TODO: Clarify the semantics of priority visit.
    //Is it a priority that it must be visited by *any* organiser or for just this organiser?
    return yard.scheduledVisits != null
        && yard.scheduledVisits.length > 0;
}

export interface IYardDetails {
    yard: IYard;
    members: Array<IMember>;
    contacts: Array<IContact>;
    nonmembers: Array<IContact>;
}

export interface IPerson extends IAddressable {
    id: number;
    lastName: string;
    firstName: string;
    otherNames?: string;
    phone?: string;
    mobile?: string;
    email?: string;
}

export interface IMember extends IPerson, IImmutableObjectWithChanges {
    memberNumber: string;
    yardId: number;
    arrearsPrv?: number;
    arrears?: number;
    ytd?: number;
    debitCosts?: number;
    isFinancial?: boolean;
    rts: boolean;
    delegateLevel?: number;
}

export interface IContact extends IPerson, IImmutableObjectWithChanges {
    title: string;
    yardIds: number[];
}

export interface IUserInfo {
    userId: number;
    /**
     * If set, should be used over userId for the purpose of unique identification
     */
    sub?: string;
    username: string;
    fullName: string;
}

export interface ISyncPayload {
    yards: IYard[];
    members: IMember[];
    contacts: IContact[];
    nonMembers: IContact[];
    tasks: ITask[];
    contactTitles: string[];
    users: IUserInfo[];
}

export interface IYardQueryOptions {
    sortOn?: YardSortType[];
    sortDirection?: SortDirection;
}

export enum YardSortType {
    LastVisit,
    Priority,
    Postcode,
    Name,
    VisitCount
}

export enum SortDirection {
    Asc,
    Desc
}

/**
 * Common operation options
 */
export interface IOperationOptions {
    
}

/**
 * Pull operation options
 */
export interface IPullOperationOptions extends IOperationOptions {

}

/**
 * Push operation options
 */
export interface IPushOperationOptions extends IOperationOptions {
    Changes: any[];
}

/**
 * The result of a push operation
 */
export interface IPushResult {
    /**
     * The list of changed yards. This is the full object graph (including any pending, but not applied changes) and should
     * replace whatever local copies you might have.
     */
    yards: IYard[];
    /**
     * The list of changed members. This is the full object graph (including any pending, but not applied changes) and should
     * replace whatever local copies you might have.
     */
    members: IMember[];
    /**
     * The list of changed contacts. This is the full object graph (including any pending, but not applied changes) and should
     * replace whatever local copies you might have.
     */
    contacts: IContact[];
    /**
     * The list of changed non-members. This is the full object graph (including any pending, but not applied changes) and should
     * replace whatever local copies you might have.
     */
    nonMembers: IContact[];
    /**
     * The list of changed tasks. This is the full object graph (including any pending, but not applied changes) and should
     * replace whatever local copies you might have.
     */
    tasks: ITask[];
    users: IUserInfo[];
}

export interface IYardListModelImmutable {
    list: Readonly<IYard[]>
    
    sort: Readonly<YardSortType[]>;
    sortDir: Readonly<SortDirection>;
}

export interface ITaskListModelImmutable  {
    
    
}

export type PendingChangeDictionary = { [property: string]: IChangeAction; };