import { IApiContext } from "./twu-contracts";

/**
 * The Campaign Central endpoint URL
 */
declare const __BASEURL__: string;

export function getFullUrl(relPart: string): string {
  let baseUrl = "";
  if (
    typeof __BASEURL__ != "undefined" &&
    __BASEURL__ != null &&
    __BASEURL__ != ""
  ) {
    baseUrl = __BASEURL__;
  }
  return baseUrl + relPart;
}

/**
 * A helper function to invoke a web service API
 */
export async function callApi<T>(
  token: IApiContext,
  url: string,
  method: "GET" | "POST" | "PUT" | "DELETE",
  body?: any,
  extraHeaders?: any
): Promise<T> {
  if (typeof body !== "string") {
    body = JSON.stringify(body);
  }

  // ensure we are using proper URL
  url = getFullUrl(url);

  // the X-Requested-With is a hack to make the login failure return a 401 rather than a redirect
  const headers: any = {
    Accept: "application/json, *.*",
    //'Cookie': 'OfficeID='
    Authorization: "Bearer " + token.token,
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
    ...extraHeaders
  };
  if (token.officeID) {
    headers["X-CC-OFFICE-ID"] = token.officeID;
  }
  if (token.pin) {
    headers["X-CC-PIN"] = token.pin;
  }

  const r = await fetch(url, {
    method: method,
    body: body,
    headers: headers,
  });
  if (r.ok) {
    const resp = await r.json();
    return resp;
  } else {
    try {
      const err = await r.json();
      throw new Error(
        err.ExceptionMessage ||
          `Request to ${r.url} returned status of ${r.status}: ${r.statusText}`
      );
    } catch (e) {
      throw new Error(
        `Request to ${r.url} returned status of ${r.status}: ${r.statusText}`
      );
    }
  }
}
