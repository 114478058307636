import * as React from "react";
import * as moment from "moment";
import * as uuid from "node-uuid";
import * as DisplayUtils from "../utils/display";
import { ITaskNote } from "../api/twu-contracts";
import { invertComparatorResult } from "../api/comparators";
import { ReactNode } from 'react';
import { Icons } from "./icons";
import { AlertPanel, AlertPanelTheme, AlertStyleKind } from "./common";


interface ITaskNoteListProps {
    notes: ITaskNote[];
}

function normalizeNoteList(notes: ITaskNote[]): ITaskNote[] {
    const sortedNotes = notes.map(normalizeNote);
    sortedNotes.sort((a, b) => invertComparatorResult(a.date.localeCompare(b.date)));
    //Weed out duplicates
    const ids: string[] = [];
    return sortedNotes.filter(n => {
        const k = n.id;
        return ids.indexOf(k) >= 0 ? false : ids.push(k);
    });
}

function normalizeNote(n: ITaskNote): ITaskNote {
    const norm = { ...n };
    norm.date = DisplayUtils.getMomentUtc(n.date).format();
    return norm;
}

export class TaskNoteList extends React.Component<ITaskNoteListProps, any> {
    constructor(props: ITaskNoteListProps) {
        super(props);
    }
    render(): JSX.Element {
        let body;
        const sortedNotes = normalizeNoteList(this.props.notes);
        const noteItems: ReactNode[] = [];

        for (const note of sortedNotes) {
            const localStart = DisplayUtils.getMomentUtc(note.date).toDate();
            const vNoteDate = moment(localStart).format("MMM Do YYYY h:mm a");
            const noteId = uuid.v4();
            noteItems.push((
                <div key={noteId} className="list-group-item">
                    <h4 className="list-group-item-heading">{vNoteDate}</h4>
                    <p className="list-group-item-text">{note.notes}</p>
                </div>
            ));
        }
        
        if (noteItems.length > 0) {
            body = 
                (<div className="list-group">
                    {noteItems}
                </div>);
        } else {
            body = (<AlertPanel kind={AlertStyleKind.Bootstrap} theme={AlertPanelTheme.Info}>
                {Icons.INFO} This task has no notes
            </AlertPanel>);
        }
        return body;
    }
}