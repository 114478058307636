import * as React from "react";
import { connect } from "react-redux";
import { Icons } from "../components/icons";

declare var __BUILD_DATE__;

function mapStateToProps(state) {
    return { };
}

function mapDispatchToProps(dispatch) {
    return { };
}

@connect(mapStateToProps, mapDispatchToProps)
export default class AboutPage extends React.Component<any, any> {
    constructor(props) {
        super(props);
    }
    render(): JSX.Element {
        return <div className="container-fluid">
            <h3>About</h3>
            <div className="alert alert-info">
                <p>Developed by Magenta Linas Software</p>
                <p>{Icons.INFO} Build date: {__BUILD_DATE__}</p>
            </div>
            <h3>Diagnostic Information</h3>
            <div>User Agent: <pre>{navigator.userAgent}</pre></div>
        </div>;
    }
}