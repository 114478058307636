/// <reference path="./dev-types.d.ts"/>

import { createStore, applyMiddleware, compose } from 'redux';

import thunk from 'redux-thunk';
import promiseMiddleware from '../middleware/promise-middleware';
import logger from './logger';
import rootReducer from '../reducers';
import { setupStore } from './persistence';

const persistReduxLocal = require("redux-localstorage");
const persistState = __DEV__ ? persistReduxLocal : require('redux-sessionstorage');

function configureStore(initialState) {
    const store = (compose as any)(
        _getMiddleware(),
        ..._getEnhancers()
    )(createStore)(rootReducer, initialState);

    _enableHotLoader(store);
    return store;
}

function _getMiddleware() {
    let middleware = [
        promiseMiddleware,
        thunk,
    ];

    if (__DEV__) {
        middleware = [...middleware, logger];
    }

    return applyMiddleware(...middleware);
}

function _getEnhancers() {
    let enhancers = [
        persistState(['session'], _getStorageConfig()),
        persistReduxLocal(['visit'], _getVisitStorageConfig())
    ];

    if (__DEV__ && window.devToolsExtension) {
        enhancers = [...enhancers, window.devToolsExtension()];
    }

    return enhancers;
}

function _enableHotLoader(store) {
    if (__DEV__ && module.hot) {
        module.hot.accept('../reducers', () => {
            const nextRootReducer = require('../reducers');
            store.replaceReducer(nextRootReducer);
        });
    }
}

function _getVisitStorageConfig() {
    return {
        key: 'react-redux-seed-visit',
        serialize: (store) => {
            if (store && store.visit) {
                //return JSON.stringify(store.session);
                return JSON.stringify({
                    visit: (store.visit != null 
                        ? {...store.visit, interval: null } 
                        : {})
                });
            } else {
                return store;
            }
        },
        deserialize: (state) => {
            let session = {};
            let visit = {};
            if (state) {
                const data = JSON.parse(state);
                if (data.visit) {
                    visit = data.visit;
                }
            }
            return {
                visit
            };
        },
    };
}

function _getStorageConfig() {
    return {
        key: 'react-redux-seed',
        serialize: (store) => {
            // this state is serialised only in DEV mode
            if (store && store.session) {
                //return JSON.stringify(store.session);
                return JSON.stringify({
                    session: store.session,
                    visit: (store.visit != null 
                        ? {...store.visit, interval: null } 
                        : {})
                });
            } else {
                return store;
            }
        },
        deserialize: (state) => {
            let session = {};
            let visit = {};
            if (state) {
                const data = JSON.parse(state);
                // this is required, for backward compat don't restore if these are missing
                if (data.session) {
                    const sess = data.session;
                    if (sess?.user?.username && sess?.user?.key) {
                        setupStore(sess.user.username, sess.user.key);
                        session = sess;
                    }
                }
                if (data.visit) {
                    visit = data.visit;
                }
            }

            return {
                session: session,
                visit: visit
            };
        },
    };
}

export default configureStore;
