/**
 * comparators.ts
 * 
 * Common sort comparators
 */

import {
    IMember,
    IChangeAction,
    IYard,
    SortDirection,
    IVisit
} from "./twu-contracts";

/**
 * Inverts a sort comparator result
 */
export function invertComparatorResult(result: number): number {
    if (result > 0) {
        return -1;
    } else if (result < 0) {
        return 1;
    } else {
        return 0;
    }
}

export function booleanComparator(a: boolean, b: boolean): number {
    if (a !== b) {
        if (a === false) {
            return 1;
        } else {
            return -1;
        }
    } else {
        return 0;
    }
}

export function getMostRecentYardVisit(yard: IYard): IVisit | null {
    if (yard.visits != null) {
        const yardCount = yard.visits.length;
        if (yardCount == 1) {
            return yard.visits[0];
        } else if (yardCount > 0) {
            return (yard.visits || []).sort(buildVisitDateSortComparator(false))[0];
        }
    }
    return null;
}

export type Comparator<T> = (a: T, b: T) => number;

export function buildVisitDateSortComparator(ascending: boolean): Comparator<IVisit> {
    return (a: IVisit, b: IVisit) => {
        const astr = a.startDate + " " + a.startTime;
        const bstr = b.startDate + " " + b.startTime;
        const comp = astr.localeCompare(bstr);
        if (ascending) {
            return comp;
        } else {
            //Invert the result
            if (comp > 0) {
                return -1;
            } else if (comp < 0) {
                return 1;
            } else {
                return 0;
            }
        }
    };
} 

export function memberDefaultSortComparator(ma: IMember, mb: IMember): number {
    if (ma.isFinancial && !mb.isFinancial) {
        return 1;
    } else if (!ma.isFinancial && mb.isFinancial) {
        return -1;
    } else {
        const aName = `${ma.firstName} ${ma.lastName}`;
        const bName = `${mb.firstName} ${mb.lastName}`;
        return aName.localeCompare(bName);
    }
}

export function invertedChangeActionSortComparator(a: IChangeAction, b: IChangeAction): number {
    const res = a.performedOn.localeCompare(b.performedOn);
    if (res > 0) {
        return -1;
    } else if (res < 0) {
        return 1;
    } else {
        return 0;
    }
}

export function changeActionDescendingDateComparator(a: IChangeAction, b: IChangeAction) {
    if (a.performedOn > b.performedOn) {
        return -1;
    } else if (a.performedOn < b.performedOn) {
        return 1;
    } else {
        return 0;
    }
}

/**
 * Constructs a yard comparator that sorts by visit count
 */
export function buildYardVisitCountComparator(sortArgs: any[]): Comparator<IYard> {
    return (a: IYard, b: IYard) => {
        const aVisitCount = (a.visits || []).length;
        const bVisitCount = (b.visits || []).length;
        let ret = 0;
        if (aVisitCount < bVisitCount) {
            ret = -1;
        } else if (aVisitCount > bVisitCount) {
            ret = 1;
        } else {
            ret = 0;
        }
        //If desc, invert the result
        if (sortArgs[0][1] == SortDirection.Desc) {
            if (ret > 0) {
                ret = -1;
            } else if (ret < 0) {
                ret = 1;
            }
        }
        return ret;
    };
}

export function buildYardLastVisitComparator(sortArgs: any[]): Comparator<IYard> {
    return (a: IYard, b: IYard) => {
        let ret = 0;
        const aLastVisit = getMostRecentYardVisit(a);
        const bLastVisit = getMostRecentYardVisit(b);
        if (aLastVisit != null && bLastVisit == null) {
            ret = 1;
        } else if (aLastVisit == null && bLastVisit != null) {
            ret = -1;
        } else if (aLastVisit != null && bLastVisit != null) {
            const aDateStr = (aLastVisit.startDate + " " + aLastVisit.startTime);
            const bDateStr = (bLastVisit.startDate + " " + bLastVisit.startTime);
            ret = aDateStr.localeCompare(bDateStr);
        } else {
            ret = 0;
        }
        
        //If desc, invert the result
        if (sortArgs[0][1] == SortDirection.Desc) {
            if (ret > 0) {
                ret = -1;
            } else if (ret < 0) {
                ret = 1;
            }
        }
        return ret;
    };
}

export function immutableNoteSortComparator(a: { date: string }, b: { date: string }): number {
    const res = a.date.localeCompare(b.date);
    if (res > 0) {
        return -1;
    } else if (res < 0) {
        return 1;
    } else {
        return 0;
    }
}