export function getNames(e: any) {
    return Object.keys(e).filter(v => isNaN(parseInt(v, 10)));
}

export function getValues(e: any) {
    return Object.keys(e).map(v => parseInt(v, 10)).filter(v => !isNaN(v));
}

export function getNamesAndValues(e: any) {
    return getValues(e).map(v => { return { name: e[v] as string, value: v }; });
}