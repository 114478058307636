import { 
    IYard,
    IYardDetails,
    IMember,
    IYardQueryOptions,
    SortDirection,
    YardSortType,
    IScheduledVisit,
    IYardDetailsUpdateRequest,
    IApiContext
} from "./twu-contracts";
import { getDataManagerAsync } from "./data-manager";
import { 
    buildYardVisitCountComparator,
    buildYardLastVisitComparator,
    memberDefaultSortComparator
} from "./comparators";
import { callApi } from "./call";
import * as uuid from "node-uuid";
import * as Sentry from "@sentry/browser";

export function putLocalScheduledVisit(id: number, model: IScheduledVisit): Promise<boolean> {
    return getDataManagerAsync().then(manager => {
        const yard = manager.getYardById(id);
        if (!yard) throw new Error(`Yard not found with ID ${id}`)
        yard.scheduledVisits.push(model);
        manager.updateYard(yard);
        return true;
    });
}

export interface IMemberSearchResult extends IMember {
    yardDesc: string;
}

export async function findMembersAsync(searchText: string): Promise<IMemberSearchResult[]> {
    const manager = await getDataManagerAsync();
    const yards = manager.getYards();
    const yardsById = {};
    for (const y of yards) {
        yardsById[y.id] = y;
    }

    const name = searchText.toLowerCase();

    const members = manager.getMembers().filter(m => {
        return (m.firstName || "").toLowerCase().indexOf(name) >= 0 
            || (m.lastName || "").toLowerCase().indexOf(name) >= 0;
    }).map(m => {
        const yard = yardsById[m.yardId];
        let desc = "Unknown Yard";
        if (yard) {
            desc = yard.name;
            if (yard.yardCode) {
                desc += " (" + yard.yardCode + ")";
            }
        }
        return {
            ...m,
            yardDesc: desc
        };
    });
    return members;
}

export function getYardDetails(id): Promise<IYardDetails> {
    return getDataManagerAsync().then(manager => {
        const yard = manager.getYardById(id);
        if (!yard) throw new Error(`Yard not found with ID ${id}`)

        const yardMembers = manager.getMembers()
                                   .filter(a => a.yardId == yard.id);
                                    
        const yardContacts = manager.getContacts()
                                    .filter(con => con.yardIds.indexOf(yard.id) >= 0)
                                    ;

        const yardNonMembers = manager.getNonMembers()
                                      .filter(con => con.yardIds.indexOf(yard.id) >= 0)
                                      ;

        const details : IYardDetails = {
            yard: yard,
            members: yardMembers.sort(memberDefaultSortComparator),
            contacts: yardContacts,
            nonmembers: yardNonMembers
        };
        
        return details;
    });
}

export function getYards(options?: IYardQueryOptions): Promise<IYard[]> {
    return getDataManagerAsync().then(manager => {
        let results: IYard[];
        if (options != null && options.sortOn && options.sortOn.length > 0) {
            let sortDir = SortDirection.Asc;
            if (options.sortDirection != null) {
                sortDir = options.sortDirection;
            }
            const sortArgs: any[] = [];
            for (const prop of options.sortOn) {
                let propName: string|null = null;
                switch (prop) {
                    case YardSortType.LastVisit:
                        propName = "lastVisit";
                        break;
                    case YardSortType.Name:
                        propName = "name";
                        break;
                    case YardSortType.Postcode:
                        propName = "postcode";
                        break;
                    case YardSortType.Priority:
                        propName = "isPriorityVisit";
                        break;
                    case YardSortType.VisitCount:
                        propName = "visitCount";
                        break;
                }
                if (propName) {
                    sortArgs.push([ propName, (sortDir == SortDirection.Desc) ]);
                }
            }
            // todo fix sort

            results = manager.getYards();//.sort(sortArgs).data();
            if (sortArgs.length == 1 && sortArgs[0][0] == "visitCount") {
                results = results.sort(buildYardVisitCountComparator(sortArgs));
            } else if (sortArgs.length == 1 && sortArgs[0][0] == "lastVisit") {
                results = results.sort(buildYardLastVisitComparator(sortArgs));
            }
        } else {
            results = manager.getYards();
        }
        return results;
    });
}

export async function sendYardInfo(token: IApiContext, model: IYardDetailsUpdateRequest) {
    const syncId = uuid.v4();
    try {
        const res = await callApi<any>(token, "/api/sendyardinfo", "POST", model, {
            ["x-sync-id"]: syncId
        });
        return res;
    } catch (e) {
        Sentry.withScope(scope => {
            scope.setTag("Operation", "sendyardinfo");
            scope.setTag("SyncRequestId", syncId);
            Sentry.captureException(e);
        });
        throw e;
    }
}