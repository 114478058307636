/* tslint:disable:no-console */

export function logTimeStart(timerName?: string): void {
    if (__DEV__) {
        console.time(timerName);
    }
}

export function logTimeEnd(timerName?: string): void {
    if (__DEV__) {
        console.timeEnd(timerName);
    }
}

export function logInfo(msg?: any, ...optionalParams: any[]): void {
    if (__DEV__) {
        console.log(msg, ...optionalParams);
    }
}

export function logWarn(msg?: any, ...optionalParams: any[]): void {
    if (__DEV__) {
        console.warn(msg, ...optionalParams);
    }
}

export function logError(msg?: any, ...optionalParams: any[]): void {
    if (__DEV__) {
        console.error(msg, ...optionalParams);
    }
}