import * as React from "react";

interface ITitleBadgeProps {
    title: string;
    className?: string;
}

export const TitleBadge = (props: ITitleBadgeProps) => {
    const badgeProps = {
        className: "label"
    };
    switch (props.title) {
        case "State Manager":
            badgeProps.className += " label-primary " + props.className;
            break;
        case "Contract Manager":
            badgeProps.className += " label-success " + props.className;
            break;
        default:
            badgeProps.className += " label-default " + props.className;
    }
    return (<span {...badgeProps}>{props.title}</span>);
};