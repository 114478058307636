import * as Constants from "../constants";
import { getContactDetails, getNonMemberDetails } from "../api/person";
import { TaskStatus } from "../api/twu-contracts";

export function fetchNonMemberDetails(id) {
    return (dispatch, getState) => {
        return dispatch({
            types: [
                Constants.FETCH_NONMEMBER_DETAILS_PENDING,
                Constants.FETCH_NONMEMBER_DETAILS_SUCCESS,
                Constants.FETCH_NONMEMBER_DETAILS_ERROR
            ],
            payload: {
                promise: getNonMemberDetails(id).then(res => {
                    return {
                        nonmember: res
                    };
                })
            }
        });
    };
}

export function fetchContactDetails(id) {
    return (dispatch, getState) => {
        return dispatch({
            types: [
                Constants.FETCH_CONTACT_DETAILS_PENDING,
                Constants.FETCH_CONTACT_DETAILS_SUCCESS,
                Constants.FETCH_CONTACT_DETAILS_ERROR
            ],
            payload: {
                promise: getContactDetails(id).then(res => {
                    return {
                        contact: res[0],
                        contactTitles: res[1]
                    };
                })
            }
        });
    };
}