import * as React from "react";
import * as moment from "moment";
import * as uuid from "node-uuid";
import * as DisplayUtils from "../utils/display";
import { Combobox, NumberPicker } from "react-widgets";
import { DisplayField } from "./display-field";
import { IChangeAction, IImmutableObjectWithChanges, PendingChangeDictionary, IAddress, isAddress } from "../api/twu-contracts";
import { IEditableFieldComponent } from "../actions/editable";
import { TwuIcons } from "./twu-icons";
import { Icons } from "./icons";

interface IAddressEditorProps {
    value: IAddress;
    onChange: (newValue: IAddress) => void;
}

class AddressEditor extends React.Component<IAddressEditorProps, any> {
    fnAddressChanged: (e) => void;
    fnSuburbChanged: (e) => void;
    fnStateChanged: (e) => void;
    fnPostcodeChanged: (e) => void;
    fnTriggerChange: (e) => void;
    constructor(props: IAddressEditorProps) {
        super(props);
        const val: any = props.value || {};
        this.state = {
            address: val.address,
            suburb: val.suburb,
            state: val.state,
            postcode: val.postcode
        };
        this.fnAddressChanged = this.onAddressChanged.bind(this);
        this.fnSuburbChanged = this.onSuburbChanged.bind(this);
        this.fnStateChanged = this.onStateChanged.bind(this);
        this.fnPostcodeChanged = this.onPostcodeChanged.bind(this);
        this.fnTriggerChange = this.onChanged.bind(this);
    }
    private onChanged() {
        const val: IAddress = {
            address: this.state.address,
            suburb: this.state.suburb,
            state: this.state.state,
            postcode: this.state.postcode
        };
        this.props.onChange(val);
    }
    private onAddressChanged(e) {
        this.setState({ address: e.target.value });
    }
    private onSuburbChanged(e) {
        this.setState({ suburb: e.target.value });
    }
    private onStateChanged(e) {
        this.setState({ state: e.target.value });
    }
    private onPostcodeChanged(e) {
        this.setState({ postcode: e.target.value });
    }
    componentWillReceiveProps(nextProps: IAddressEditorProps) {
        if (this.props.value != nextProps.value && (
            this.props.value.address != nextProps.value.address ||
            this.props.value.postcode != nextProps.value.postcode ||
            this.props.value.state != nextProps.value.state ||
            this.props.value.suburb != nextProps.value.suburb)) {
            this.setState({
                address: nextProps.value.address,
                postcode: nextProps.value.postcode,
                state: nextProps.value.state,
                suburb: nextProps.value.suburb
            });
        }
    }
    render(): JSX.Element {
        const { address, suburb, state, postcode } = this.state; 
        return <div>
            <input type="text" className="form-control" placeholder="Address" value={address || ""} onChange={this.fnAddressChanged} onBlur={this.fnTriggerChange} />
            <input type="text" className="form-control" placeholder="Suburb" value={suburb || ""} onChange={this.fnSuburbChanged} onBlur={this.fnTriggerChange} />
            <input type="text" className="form-control" placeholder="State" value={state || ""} onChange={this.fnStateChanged} onBlur={this.fnTriggerChange} />
            <input type="text" className="form-control" placeholder="Postcode" value={postcode || ""} onChange={this.fnPostcodeChanged} onBlur={this.fnTriggerChange} />
        </div>;
    }
}

export enum EditDataType {
    String,
    Number,
    Date,
    List,
    Address
}

interface IEditStateChangedEvent {
    isEditing: boolean;
    sender: EditableField;
    isCancelling: boolean;
}

interface IEditableFieldProps {
    /**
     * Font awesome icon class name
     */
    iconClass: string;
    /**
     * The label for this field
     */
    label: string;
    /**
     * The property name for this field
     */
    propertyName?: string;
    /**
     * The field's initial value
     */
    initialValue?: any;
    hideIfValueEmpty?: boolean;
    /**
     * The change action to initialize the field with
     */
    change?: IChangeAction;
    /**
     * The type of field to render in edit mode
     */
    dataType?: EditDataType;
    /**
     * A list of options to use when using List edit mode
     */
    listOptions?: string[];
    /**
     * The size of the label on a 12-col responsive grid. Value must be between 1-11
     */
    labelSize?: number;
    /**
     * Sets whether the field should be initially rendered in editing mode
     */
    initiallyEditing?: boolean;
    /**
     * Called when the field enters/exits editing mode
     */
    onEditStateChanged?: (e: IEditStateChangedEvent) => void;
    /**
     * If a numeric field type, indicates the minimum allowed value
     */
    minValue?: number;
    iconKind?: string;
}

interface IEditableFieldState {
    initialValue?: any;
    editing: boolean;
    newValue?: any;
    editingValue?: any;
    editDomId?: string;
}

function AutoFocusElement(component: HTMLElement) {
    if (component != null) {
        component.focus();
    }
}

export class EditableField extends React.Component<IEditableFieldProps, any> implements IEditableFieldComponent {
    private _change?: IChangeAction;
    private fnNewValueChanged: (e) => void;
    private fnActivateEditMode: (e) => void;
    private fnSaveNewValue: (e) => void;
    private fnCancelEdit: (e) => void;
    constructor(props: IEditableFieldProps) {
        super(props);
        this.fnNewValueChanged = this.onNewValueChanged.bind(this);
        this.fnActivateEditMode = this.onActivateEditMode.bind(this);
        this.fnSaveNewValue = this.onSaveNewValue.bind(this);
        this.fnCancelEdit = this.onCancelEdit.bind(this);
        this._change = props.change;
        const newValue = (this._change != null ? this._change.data.value : props.initialValue);
        const editing = !!props.initiallyEditing;
        let editingValue;
        if (editing) {
            editingValue = newValue;
        }
        this.state = {
            initialValue: props.initialValue,
            editing: editing,
            newValue: newValue,
            editDomId: uuid.v4(),
            editingValue: editingValue
        };
    }
    getPropertyName(): string|undefined { return this.props.propertyName; }
    attachChange(change: IChangeAction) {
        this._change = change;
        const newValue = (this._change != null ? this._change.data.value : this.props.initialValue);
        this.setState({initialValue: newValue, newValue });
    }
    onSaveNewValue(e) {
        this.setState({ editing: false, newValue: this.state.editingValue }, () => {
            if (this.props.onEditStateChanged != null) {
                this.props.onEditStateChanged({ isEditing: false, sender: this, isCancelling: false });
            }
        });
    }
    onNewValueChanged(e) {
        let value;
        if (typeof e === 'string') {
            value = e;
        } else if (typeof e === 'number') {
            value = e;
        } else if (isAddress(e)) {
            //Is this a bug in TypeScript? Tooling shows e as "any"!
            value = e;
        } else {
            if (e != null) {
                value = e.target.value;
            } else {
                value = null;
            }
        }
        this.setState({ editing: true, editingValue: value });
    }
    onActivateEditMode(e) {
        this.setState({ editing: true, editingValue: this.state.newValue }, () => {
            if (this.props.onEditStateChanged != null) {
                this.props.onEditStateChanged({ isEditing: true, sender: this, isCancelling: false });
            }
        });
    }
    onCancelEdit(e) {
        this.setState({ editing: false }, () => {
            if (this.props.onEditStateChanged != null) {
                this.props.onEditStateChanged({ isEditing: false, sender: this, isCancelling: true });
            }
        });
    }
    public hasChanged(): boolean {
        if (this._change != null) {
            return this.state.newValue != this._change.data.value;
        } else {
            return this.state.newValue != this.state.initialValue;
        }
    }
    private cloneChangeWithNewValue(): IChangeAction {
        if (!this._change) throw new Error("Missing changeaction");

        return {
            id: this._change.id,
            objectId: this._change.objectId,
            type: this._change.type,
            data: {
                property: this._change.data.property,
                type: this._change.data.type,
                value: this.state.newValue
            },
            performedOn: moment.utc().format()
        };
    }
    /**
     * Returns the changed value if any changes were made, otherwise:
     *  - If useInitialValueIfUnchanged = true, then the initial value is returned
     *  - Otherwise, null is returned.
     * 
     * If the field was initialised from an IChangeAction, a cloned copy (with updated value and timestamp) is returned.
     */
    public getChangedValue(useInitialValueIfUnchanged: boolean): IChangeAction|any {
        if (this.hasChanged()) {
            if (this._change != null) {
                return this.cloneChangeWithNewValue();
            } else {
                if (this.props.dataType == EditDataType.Number) {
                    return parseInt(this.state.newValue, 10);
                } else {
                    return this.state.newValue;
                }
            }
        }
        return useInitialValueIfUnchanged ? this.state.initialValue : null;
    }
    render(): JSX.Element {
        let body;
        let saved;
        if (this.hasChanged()) {
            saved = (<span className="label label-success">Changed</span>);
        }
        
        const dataType = this.props.dataType || EditDataType.String;
        let editCtrl;
        const editProps: any = {
            ref: "edit",
            id: this.state.editDomId
        };
        switch (dataType) {
            case EditDataType.Date:
                editCtrl = (<input {...editProps} className="form-control" ref={AutoFocusElement} type="date" value={this.state.editingValue} onChange={this.fnNewValueChanged} />);
                break;
            case EditDataType.List:
                const options = [];
                const availableOpts = this.props.listOptions || [];
                /*
                for (const opt of availableOpts) {
                    options.push((<option key={`option-${opt}`} value={opt}>{opt}</option>));
                }
                editCtrl = (<select {...editProps} className="form-control" ref={AutoFocusElement} value={this.state.editingValue} onChange={this.fnNewValueChanged}>{options}</select>);
                */
                editCtrl = (<Combobox value={this.state.editingValue} onChange={this.fnNewValueChanged} data={availableOpts} />);
                break;
            case EditDataType.Number:
                //editCtrl = (<input {...editProps} className="form-control" ref={AutoFocusElement} type="number" value={this.state.editingValue} onChange={this.fnNewValueChanged} />);
                editCtrl = (<NumberPicker value={this.state.editingValue} min={this.props.minValue} onChange={this.fnNewValueChanged} />);
                break;
            case EditDataType.Address:
                editCtrl = <AddressEditor value={this.state.editingValue} onChange={this.fnNewValueChanged} />;
                break;
            default:
                editCtrl = (<input {...editProps} className="form-control" ref={AutoFocusElement} type="text" value={this.state.editingValue} onChange={this.fnNewValueChanged} />);
                break;
        }
        
        const labelSize = this.props.labelSize || 2;
        const fieldSize = 12 - labelSize;
        
        if (this.state.editing) {
            const editLabel = (<label htmlFor={this.state.editDomId} className={`col-sm-${labelSize} control-label`}><i className={`fa ${this.props.iconKind || "fa"}-${this.props.iconClass}`}></i>&nbsp;{this.props.label + ": "}</label>);
            body =
                (<div className="form-group">
                    {editLabel}
                    <div className={`col-sm-${fieldSize}`}>
                        <div className="input-group">
                            {editCtrl}
                            <span className="input-group-btn">
                                <button className="btn btn-primary" onClick={this.fnSaveNewValue}>
                                    {Icons.TICK}
                                </button>
                                <button className="btn btn-danger" onClick={this.fnCancelEdit}>
                                    {Icons.CLOSE}
                                </button>
                            </span>
                        </div>
                    </div>
                </div>);
        } else {
            const dispVal = dataType == EditDataType.Address ? DisplayUtils.getAddressString(this.state.newValue) : this.state.newValue;
            body = 
                (<DisplayField {...this.props} value={dispVal}>
                    &nbsp;
                    <button className="btn btn-primary btn-sm" onClick={this.fnActivateEditMode}>
                        {TwuIcons.PENCIL}
                    </button>
                    &nbsp;
                    {saved}
                </DisplayField>); 
        }
        return body;
    }
}

export const SPECIAL_PROP_ADDRESS = "address"; //This is a special virtual meta-property used to slot in an IAddress value
export const ADDRESS_PROPERTY_SET = [ "address", "suburb", "postcode", "state" ]; //DO NOT CHANGE THE ORDER OF THESE VALUES!

export function buildEditableField<T extends IImmutableObjectWithChanges>(obj: T, propertyName: string|string[], keyRef: string, iconKind: string, iconClass: string, label: string, changeDict: PendingChangeDictionary, dataType?: EditDataType, listOptions?: string[]) {
    if (propertyName instanceof Array) {
        if (dataType != EditDataType.Address) {
            throw new Error("An array of property names is only applicable when EditDataType is Address");
        } else {
            if (changeDict[SPECIAL_PROP_ADDRESS])  {
                return (<EditableField key={keyRef} ref={keyRef} iconKind={iconKind} iconClass={iconClass} label={label} change={changeDict["address"]} dataType={dataType} listOptions={listOptions} />);
            } else {
                const addr: IAddress = {
                    address: obj[ADDRESS_PROPERTY_SET[0]],
                    suburb: obj[ADDRESS_PROPERTY_SET[1]],
                    postcode: obj[ADDRESS_PROPERTY_SET[2]],
                    state: obj[ADDRESS_PROPERTY_SET[3]]
                };
                return (<EditableField key={keyRef} ref={keyRef} iconKind={iconKind} iconClass={iconClass} label={label} initialValue={addr} dataType={dataType} listOptions={listOptions} />);
            }
        }
    } else {
        if (dataType == EditDataType.Address) {
            throw new Error("An array of property names is required when EditDataType is Address");
        } else {
            if (changeDict[propertyName])  {
                return (<EditableField key={keyRef} ref={keyRef} iconKind={iconKind} iconClass={iconClass} label={label} change={changeDict[propertyName]} dataType={dataType} listOptions={listOptions} />);
            } else {
                return (<EditableField key={keyRef} ref={keyRef} iconKind={iconKind} iconClass={iconClass} label={label} propertyName={propertyName} initialValue={obj[propertyName]} dataType={dataType} listOptions={listOptions} />);
            }
        }
    }
}