import * as Constants from "../constants";
import { setupInitialState, IReducerBranchStateImmutable } from "./utils";
import { immutableNoteSortComparator } from "../api/comparators";
import { ITaskNote, ITask } from '../api/twu-contracts';

type ITaskDetailsImmutable = {
    notes: ITaskNote[];
    task: ITask
};

const INITIAL_STATE: IReducerBranchStateImmutable<ITaskDetailsImmutable> = setupInitialState<ITaskDetailsImmutable>();


export default function taskReducer(state = INITIAL_STATE, action: { type: string, payload?: any }) {
    switch (action.type) {
        case Constants.ADD_NOTE_PENDING:
        case Constants.FETCH_TASK_DETAILS_PENDING:
            return { ...state,
                hasError: false,
                isLoading: true
            };
        case Constants.ADD_NOTE_ERROR:
        case Constants.FETCH_TASK_DETAILS_ERROR:
            return { ...state,
                hasError: true,
                isLoading: false,
                errorDetails: action.payload
            };
        case Constants.ADD_NOTE_SUCCESS:
            {
                let currentModel = state.model
                if (currentModel == null) {
                    return state;
                }
                let notes = currentModel.notes;
                if (notes != null) {
                    const newNotes = [ action.payload.note, ...notes ];
                    currentModel = {...currentModel,
                        notes: newNotes.sort(immutableNoteSortComparator)
                    };
                }
                return { ...state,
                    model: currentModel,
                    hasError: false,
                    isLoading: false
                };
            }
        case Constants.FETCH_TASK_DETAILS_SUCCESS:
            {
                let currentModel = state.model
                if (currentModel == null) {
                    currentModel = action.payload;
                } else {
                    currentModel = {...currentModel, ...action.payload };
                    const notes = currentModel!.notes;
                    const task = currentModel!.task;
                    currentModel = {
                        task: task,
                        notes: notes.sort(immutableNoteSortComparator)
                    };
                }
                return { ...state,
                    model: currentModel,
                    hasError: false,
                    isLoading: false
                };
            }
        default:
            return state;
    }
}