import * as Constants from "../constants";
import { IReducerBranchState } from './utils';
interface IYardDetails {

}

type IYardDetailsImmutable = Readonly<IYardDetails>;

const INITIAL_STATE: IReducerBranchState<IYardDetailsImmutable> = {
    model: null,
    hasError: false,
    errorDetails: null,
    isLoading: false
};

export default function yardReducer(state = INITIAL_STATE, action: { type: string, payload?: any }) {
    switch (action.type) {
        case Constants.FETCH_TASKS_FOR_YARD_PENDING:
        case Constants.FETCH_YARD_DETAILS_PENDING:
            return { ...state,
                hasError: false,
                isLoading: true
            };
        case Constants.FETCH_TASKS_FOR_YARD_ERROR:
        case Constants.FETCH_YARD_DETAILS_ERROR:
            return { ...state,
                hasError: true,
                isLoading: false,
                errorDetails: action.payload
            };
        case Constants.FETCH_YARD_DETAILS_SUCCESS:
            {
                let currentModel = state.model
                if (currentModel == null) {
                    currentModel = action.payload;
                } else {
                    currentModel = { ...currentModel, ...action.payload };
                }
                return { ...state,
                    model: currentModel,
                    hasError: false,
                    isLoading: false
                };
            }
        case Constants.FETCH_TASKS_FOR_YARD_SUCCESS:
            {
                let currentModel = state.model
                if (currentModel == null) {
                    currentModel = action.payload;
                } else {
                    currentModel = { ...currentModel, ...action.payload };
                }
                return { ...state,
                    model: currentModel,
                    hasError: false,
                    isLoading: false
                };
            }
        default:
            return state;
    }
}