import * as Constants from "../constants";
import { setupInitialState, buildReducerFunction, IReducerBranchStateImmutable, IReducerConfiguration } from "./utils";


type INonMemberImmutable = {};

const INITIAL_STATE: IReducerBranchStateImmutable<INonMemberImmutable> = setupInitialState<INonMemberImmutable>();

const config: IReducerConfiguration<INonMemberImmutable> = {
    initialState: INITIAL_STATE,
    pending: {
        key: Constants.FETCH_NONMEMBER_DETAILS_PENDING
    },
    error: {
        key: Constants.FETCH_NONMEMBER_DETAILS_ERROR
    },
    success: {
        key: Constants.FETCH_NONMEMBER_DETAILS_SUCCESS
    }
};

const nonMemberReducer = buildReducerFunction(config);

export default nonMemberReducer;