import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as moment from 'moment';
import { Provider } from 'react-redux';
import { HashRouter as Router } from 'react-router-dom';
import routes from './store/routes';
import configureStore from './store/configure-store';
import * as numloc from 'react-widgets-simple-number';
import { StoreContext } from 'redux-react-hook';
import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: "https://e1e090d7ac394952b96cad2e868153f3@sentry.theredfox.group/48",
    integrations: [
        // TODO: There's react-router integration possibility
        // https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/#react-router-v4v5
        new Sentry.BrowserTracing(),
        //new Sentry.Replay()
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
});

function isIOSPrivateBrowsing(): boolean {
    const storageTestKey = 'ios_private_browsing_test';
    const storage = window.sessionStorage;

    try {
        storage.setItem(storageTestKey, 'test');
        storage.removeItem(storageTestKey);
    } catch (e) {
        if ((e as any).code === DOMException.QUOTA_EXCEEDED_ERR && storage.length === 0) {
            return /iPad|iPhone|iPod/.test(navigator.userAgent);
        }
    }

    return false;
}

// Global styles
require('./styles/index.less');
require("react-widgets/dist/css/react-widgets.css");

numloc();

//The code below is a "fixed" version of the moment localization adapter for react-widgets.
//
//The fix incorporates: https://github.com/jquense/react-widgets/issues/523
const configure = require("react-widgets/lib/configure");

const localField = typeof moment().locale === 'function' ? 'locale' : 'lang';
const hasLocaleData = !!moment.localeData;

if (!hasLocaleData) {
    throw new TypeError('The Moment localizer depends on the `localeData` api, please provide a moment object v2.2.0 or higher');
}

function getMoment(culture, value, format?) {
    return culture ? moment(value, format, true)[localField](culture) : moment(value, format, true);
}

function endOfDecade(date) {
    return moment(date).add(10, 'year').add(-1, 'millisecond').toDate();
}

function endOfCentury(date) {
    return moment(date).add(100, 'year').add(-1, 'millisecond').toDate();
}

const fixedMomentLocalizer = {
    formats: {
        date: 'L',
        time: 'LT',
        default: 'lll',
        header: 'MMMM YYYY',
        footer: 'LL',
        weekday: 'dd',
        dayOfMonth: 'DD',
        month: 'MMM',
        year: 'YYYY',
        decade(date, culture, localizer) {
            return localizer.format(date, 'YYYY', culture)
                + ' - ' + localizer.format(endOfDecade(date), 'YYYY', culture);
        },
        century(date, culture, localizer) {
            return localizer.format(date, 'YYYY', culture)
                + ' - ' + localizer.format(endOfCentury(date), 'YYYY', culture);
        }
    },
    firstOfWeek(culture) {
        return moment.localeData(culture).firstDayOfWeek();
    },
    parse(value, format, culture) {
        if (!value) {
            return null;
        }
        const m = getMoment(culture, value, format);
        if (m.isValid()) {
            return m.toDate();
        }
        return null;
    },
    format(value, format, culture) {
        return getMoment(culture, value).format(format);
    }
};
configure.setDateLocalizer(fixedMomentLocalizer);
// end fixed localiser

// const numberLocalizer = require("react-widgets/lib/localizers/simple-number");
// numberLocalizer();

if (isIOSPrivateBrowsing()) {
    ReactDOM.render(
        <div className="alert alert-error">
            <h3>Unsupported browser</h3>
            <hr />
            <p>It appears that you are using Mobile Safari on an iOS device in <strong>Private Browsing Mode</strong></p>
            <p>Using the app in this mode is <strong>not supported</strong></p>
            <p>Please reload this app in normal browsing mode</p>
        </div>,
        document.getElementById('app-container')
    );
} else {
    // AppCache auto-update checking
    //
    // NOTE: A full-refresh is still required before this kicks in, in dev mode. 
    // This is because the backend (ie. ASP.net) doesn't implement the webpack
    // live-reloading protocols and we don't use webpack-dev-server to run this
    // app in dev mode.

    if ('serviceWorker' in navigator) {
        window.addEventListener('load', () => {
          navigator.serviceWorker.register(`/service-worker.js`).then(registration => {
            console.log('SW registered: ', registration);
          }).catch(registrationError => {
            console.log('SW registration failed: ', registrationError);
          });
        });
      }

    const store = configureStore({});
    // const history = syncHistoryWithStore(h, store);
    /*
    const vendorTest = /.*\/vendor\.(.+?)\./;
    const appTest = /.*\/app\.(.+?)\./;
    for (let i = 0; i < document.scripts.length; i++) {
        const scr: any = document.scripts[i];
        if (scr.src) {
            const vendorMatch = scr.src.toString().match(vendorTest);
            const appMatch = scr.src.toString().match(appTest);
            if (vendorMatch && vendorMatch.length > 1) {
                setVendorHash(vendorMatch[1]);
            } else if (appMatch && appMatch.length > 1) {
                setAppHash(appMatch[1]);
            }
        }
    }
    */
    ReactDOM.render(
        <div style={{ height: "100%" }}>
            <Provider store={store}>
                <StoreContext.Provider value={store}>
                    <Router>
                        {routes}
                    </Router>
                </StoreContext.Provider>
            </Provider>
        </div>,
        document.getElementById('app-container')
    );
}