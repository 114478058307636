import * as React from "react";
import { DisplayField } from "../display-field";
import { EditableFieldContainer } from "../editable-field-container";
import { EditableField, EditDataType, buildEditableField } from "../editable-field";
import { VisitList } from "../visit-list";
import { TaskList } from "../task-list";
import { MemberList } from "../member-list";
import { PersonList } from "../person-list";
import * as ChangeUtils from "../../api/change-utils";
import * as DisplayUtils from "../../utils/display";
import * as moment from "moment";
import { TaskStatus, IYardDetails, ICreateScheduledVisit, IVisit, IYardDetailsUpdateRequest } from "../../api/twu-contracts";
import { push } from "../../api/auto-push";
import { DateTimePicker } from "react-widgets";
import SAlert from "react-s-alert";
import { sendYardInfo } from '../../api/yards';
import { BottomDrawer } from '../modal-common';
import { Icons } from "../icons";
import { BsLoadSpinner, ContentSection, ContentSectionDisplayStyle, ContentSectionPanelStyle } from "../common";

function contactRoute(id: number) { return `/contact/${id}`; }
function nonMemberRoute(id: number) { return `/nonmember/${id}`; }

const ModalContent = (props) => <div className="modal-content">{props.children}</div>;
const ModalHeader = (props) => <div className="modal-header">{props.children}</div>;
const ModalBody = (props) => <div className="modal-body">{props.children}</div>;
const ModalFooter = (props) => <div className="modal-footer">{props.children}</div>;

interface IYardDetailsPageComponentProps {
    model: any;
    session: string;
    userId: number;
    isOnline: boolean;
    onFilterStatusChange: (status: TaskStatus | null) => void;
    onSaveChanges: (editables: EditableField[], callback: (success: boolean, saveError?: Error) => void) => void;
    onScheduleVisit?: (model: ICreateScheduledVisit, callback: (success: boolean, saveError?: Error) => void) => void;
}

export class YardDetailsPageComponent extends React.Component<IYardDetailsPageComponentProps, any> {
    constructor(props) {
        super(props);
        this.state = {
            isScheduleOpen: false,
            model: {},
            activeVisitNote: null,
            activeNewYardDetails: null,
            submitting: false
        };
    }
    private onSendNewYardDetails = (e) => {
        const { session, model } = this.props;
        const { activeNewYardDetails } = this.state;
        const yardDetails: IYardDetails = model.yard;
        const payload: IYardDetailsUpdateRequest = {
            yardId: yardDetails.yard!.id,
            details: activeNewYardDetails.details,
            imageBase64: activeNewYardDetails.image.uri
        };
        this.setState({ submitting: true });
        SAlert.info("Sending yard details", DisplayUtils.DEFAULT_UI_NOTIFICATION_SETTINGS as any);
        sendYardInfo({ token: session }, payload).then(r => {
            SAlert.success("Yard details sent", DisplayUtils.DEFAULT_UI_NOTIFICATION_SETTINGS as any);
            this.setState({
                submitting: false,
                activeNewYardDetails: null
            });
        }).catch(r => {
            SAlert.error("Failed to send yard details", DisplayUtils.DEFAULT_UI_NOTIFICATION_SETTINGS as any);
            this.setState({ submitting: false });
        });
    }
    private onCancelActiveYardDetails = (e) => {
        this.setState({ activeNewYardDetails: null });
    }
    private onActiveNewYardDetailsChanged = (e) => {
        const ay = { ...this.state.activeNewYardDetails };
        ay.details = e.target.value;
        this.setState({ activeNewYardDetails: ay });
    }
    private handleImageChange = (e) => {
        e.preventDefault();

        const reader = new FileReader();
        const file = e.target.files[0];

        reader.onloadend = () => {
            const ay = { ...this.state.activeNewYardDetails };
            ay.image = {
                file: file,
                uri: reader.result
            };
            this.setState({ activeNewYardDetails: ay });
        };

        reader.readAsDataURL(file);
    }
    private onUpdateYardDetails = (e) => {
        this.setState({
            activeNewYardDetails: {
                details: "",
                image: {
                    file: "",
                    uri: ""
                }
            }
        });
    }
    private onScheduleVisit = (e) => {
        e.preventDefault();
        const yardDetails: IYardDetails = this.props.model.yard;

        const model: ICreateScheduledVisit = {
            organiserId: this.props.userId,
            yardId: yardDetails.yard!.id,
            scheduledDate: this.state.model.date,
            message: this.state.model.message
        };
        this.props.onScheduleVisit && this.props.onScheduleVisit(model, (success, saveError) => {
            if (success === true) {
                this.setState({ isScheduleOpen: false });
            } else {
                SAlert.error(saveError ? saveError.message : "Error", DisplayUtils.DEFAULT_UI_NOTIFICATION_SETTINGS as any);
            }
        });
        return false;
    }
    private onBeginScheduleVisit = (e) => {
        e.preventDefault();
        this.setState({ isScheduleOpen: true, model: {} });
        return false;
    }
    private onCancelScheduleVisit = (e) => {
        e.preventDefault();
        this.setState({ isScheduleOpen: false });
        return false;
    }
    private onRequestPush = () => {
        push({ token: this.props.session });
    }
    private onMessageChanged = (e) => {
        const model = this.state.model;
        model.message = e.target.value;
        this.setState({ model: model });
    }
    private onDueDateChanged = (e) => {
        const model = this.state.model;
        model.date = e;
        this.setState({ model: model });
    }
    private onVisitSelected = (visit: IVisit) => {
        const localStart = DisplayUtils.getMomentUtc(visit.startDate + " " + visit.startTime).toDate();
        const note = {
            title: `Visit Notes - ${moment(localStart).format("MMM Do YYYY")} ${moment(localStart).format("h:mm a")}`,
            visitNotes: visit.visitNotes,
            twuSuperNotes: visit.twuSuperNotes
        };
        const newState = { ...this.state, ...{ activeVisitNote: note } };
        this.setState(newState);
    }
    private onClearActiveNote = (e) => {
        const newState = { ...this.state, ...{ activeVisitNote: null } };
        this.setState(newState);
    }
    render(): JSX.Element {
        const { submitting } = this.state;
        const { model, onFilterStatusChange, onSaveChanges } = this.props;
        const { tasks, taskStatus } = model;
        const yardDetails: undefined | IYardDetails = model.yard;
        if (!yardDetails || !yardDetails.yard) return <>Yard not found</>;

        //Build our list of editable fields
        const changeDict = ChangeUtils.collectAttachedPendingChanges(yardDetails.yard);
        const totalEmployees = buildEditableField(yardDetails.yard, "totalEmployees", "editTotalEmployees", "icon", "users", "Total Employees", changeDict, EditDataType.Number);

        return (
            <div className="container-fluid">
                {(() => {
                    if (this.state.isScheduleOpen === true) {
                        const dateFmt = "dddd, MMMM Do YYYY";
                        const timeFmt = "h:mm:ss a";
                        const dtProps: any = {
                            format: `${dateFmt} ${timeFmt}`,
                            value: this.state.model.date,
                            onChange: this.onDueDateChanged,
                            time: true,
                            dropUp: false,
                            readOnlyField: true
                        };

                        return <BottomDrawer open={this.state.isScheduleOpen}>
                            <ModalContent>
                                <ModalHeader><strong>Schedule a visit to this yard</strong></ModalHeader>
                                <ModalBody>
                                    <form>
                                        <div className="form-group">
                                            <label htmlFor="due">Schedule For:</label>
                                            <DateTimePicker {...dtProps} />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="description">Message for yourself</label>
                                            <textarea className="form-control" id="description" placeholder="Message for yourself" value={this.state.model.message} onChange={this.onMessageChanged} />
                                        </div>
                                    </form>
                                </ModalBody>
                                <ModalFooter>
                                    <button type="button" className="btn btn-success" onClick={this.onScheduleVisit}>Schedule Visit</button>
                                    <button type="button" className="btn btn-danger" onClick={this.onCancelScheduleVisit}>{Icons.CLOSE} Cancel</button>
                                </ModalFooter>
                            </ModalContent>
                        </BottomDrawer>;
                    }
                })()}
                {(() => {
                    if (this.state.activeVisitNote) {

                        return <BottomDrawer open={true}>
                            <ModalContent>
                                <ModalHeader><strong>{this.state.activeVisitNote.title}</strong></ModalHeader>
                                <ModalBody>
                                    <form>
                                        <div className="form-group">
                                            <label htmlFor="due">Visit Notes</label>
                                            <textarea className="form-control" id="due" readOnly style={{ overflowY: "scroll", height: 100 }} value={this.state.activeVisitNote.visitNotes} />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="description">TWU Super Notes</label>
                                            <textarea className="form-control" id="description" readOnly style={{ overflowY: "scroll", height: 100 }} value={this.state.activeVisitNote.twuSuperNotes} />
                                        </div>
                                    </form>
                                </ModalBody>
                                <ModalFooter>
                                    <button type="button" className="btn btn-danger" onClick={this.onClearActiveNote}>{Icons.CLOSE} Close</button>
                                </ModalFooter>
                            </ModalContent>
                        </BottomDrawer>;
                    } else if (this.state.activeNewYardDetails) {

                        return <BottomDrawer open={true}>
                            <ModalContent>
                                <ModalHeader><strong>Update Yard Details</strong></ModalHeader>
                                <ModalBody>
                                    <form>
                                        <div className="form-group">
                                            <label htmlFor="yardDetails">Yard Details</label>
                                            <textarea className="form-control" id="yardDetails" disabled={submitting} style={{ overflowY: "scroll", height: 100 }} value={this.state.activeNewYardDetails.details} onChange={this.onActiveNewYardDetailsChanged} />
                                        </div>
                                        {(() => {
                                            if (this.state.activeNewYardDetails.image.uri) {
                                                return <div className="form-group" style={{ overflowY: "auto", maxHeight: 150 }}>
                                                    <p><strong>Image (Attached)</strong></p>
                                                    <img id="yardImage" src={this.state.activeNewYardDetails.image.uri} width={250} height={250} />
                                                </div>;
                                            } else {
                                                return <div className="form-group">
                                                    <label htmlFor="yardImage">Image</label>
                                                    <input id="yardImage" type="file" accept="image/*" onChange={this.handleImageChange} />
                                                </div>;
                                            }
                                        })()}
                                    </form>
                                </ModalBody>
                                <ModalFooter>
                                    <button type="button" className="btn btn-primary" onClick={this.onSendNewYardDetails} disabled={this.state.activeNewYardDetails.details == null || this.state.activeNewYardDetails.details.length == 0 || submitting}>Send</button>
                                    <button type="button" className="btn btn-danger" disabled={submitting} onClick={this.onCancelActiveYardDetails}>{Icons.CLOSE} Close</button>
                                </ModalFooter>
                            </ModalContent>
                        </BottomDrawer>;
                    }
                })()}
                <br />
                <ContentSection title={`${yardDetails.yard.name}${yardDetails.yard.yardCode ? " (" + yardDetails.yard.yardCode + ")" : ""}`} displayStyle={ContentSectionDisplayStyle.Fieldset} panelStyle={ContentSectionPanelStyle.BootstrapPrimary} iconKind="icon" iconClass="building">
                    <div className="well">
                        <div className="form-horizontal">
                            {(() => {
                                if (yardDetails.yard.details) {
                                    return <DisplayField iconKind="icon" iconClass="info" label="Details" value={yardDetails.yard.details} />;
                                }
                            })()}
                            <DisplayField iconKind="icon" iconClass="address" label="Address" value={DisplayUtils.getAddressString(yardDetails.yard)} />
                            {(() => {
                                if (yardDetails.yard.postalAddress) {
                                    return <DisplayField iconKind="icon" iconClass="address" label="Postal Address" value={yardDetails.yard.postalAddress} />;
                                }
                            })()}
                            {(() => {
                                if (yardDetails.yard.phone) {
                                    return <DisplayField iconKind="icon" iconClass="info" label="Phone" value={yardDetails.yard.phone} />;
                                }
                            })()}
                            {(() => {
                                if (yardDetails.yard.mobile) {
                                    return <DisplayField iconKind="icon" iconClass="info" label="Mobile" value={yardDetails.yard.mobile} />;
                                }
                            })()}
                            {(() => {
                                if (yardDetails.yard.email) {
                                    return <DisplayField iconKind="icon" iconClass="info" label="Email" value={yardDetails.yard.email} />;
                                }
                            })()}
                            {(() => {
                                if (yardDetails.yard.fax) {
                                    return <DisplayField iconKind="icon" iconClass="info" label="Fax" value={yardDetails.yard.fax} />;
                                }
                            })()}
                            <EditableFieldContainer onSaveChanges={onSaveChanges} autoCommit={true} requestPush={this.onRequestPush}>
                                {totalEmployees}
                            </EditableFieldContainer>
                            {(() => {
                                if (yardDetails.yard.conditionsOfEntry && yardDetails.yard.conditionsOfEntry.length > 0) {
                                    //Yes, that is a space for empty value!
                                    return <DisplayField iconKind="icon" iconClass="flag" label="Conditions of Entry" emptyValue=" ">
                                        {yardDetails.yard.conditionsOfEntry.map(c => <span key={c} style={{ marginRight: 2, marginLeft: 2 }} className="label label-danger">{c}</span>)}
                                    </DisplayField>;
                                }
                            })()}
                        </div>
                        <hr />
                        {(() => {
                            /*
                            if (this.props.isOnline === true) {
                                return <button type="button" className="btn btn-primary" onClick={this.onBeginScheduleVisit}>Schedule a Visit</button>;
                            }
                            */
                            return <></>;
                        })()}
                        {(() => {
                            if (this.props.isOnline === true) {
                                return <button type="button" className="btn btn-primary" onClick={this.onUpdateYardDetails}>Update Yard Details</button>;
                            }
                        })()}
                        {/* <Link to={`/yard/${yardDetails.yard.id}/interactions`} className="btn btn-primary">Interactions ({yardDetails.yard.interactions.length})</Link> */}
                    </div>
                </ContentSection>
                <ContentSection title={`Yard Contacts (${yardDetails.contacts.length})`} displayStyle={ContentSectionDisplayStyle.Fieldset} panelStyle={ContentSectionPanelStyle.BootstrapDefault} iconKind="icon" iconClass="users">
                    <PersonList people={yardDetails.contacts} selectionRoute={contactRoute} emptyMessage="No contacts found for this yard" />
                </ContentSection>
                <ContentSection title={`Yard Members/Delegates (${yardDetails.members.length})`} displayStyle={ContentSectionDisplayStyle.Fieldset} panelStyle={ContentSectionPanelStyle.BootstrapDefault} iconKind="icon" iconClass="users">
                    <MemberList members={yardDetails.members} emptyMessage="No members found for this yard" />
                </ContentSection>
                <ContentSection title={`Yard Non-Members (${yardDetails.nonmembers.length})`} displayStyle={ContentSectionDisplayStyle.Fieldset} panelStyle={ContentSectionPanelStyle.BootstrapDefault} iconKind="icon" iconClass="users">
                    <PersonList people={yardDetails.nonmembers} selectionRoute={nonMemberRoute} emptyMessage="No non-members found for this yard" />
                </ContentSection>
                {yardDetails.yard.visits && <ContentSection title={`Yard Visits (${yardDetails.yard.visits.length})`} displayStyle={ContentSectionDisplayStyle.Fieldset} panelStyle={ContentSectionPanelStyle.BootstrapPrimary} iconKind="icon" iconClass="clock">
                    <VisitList visits={yardDetails.yard.visits} onVisitSelected={this.onVisitSelected} />
                </ContentSection>}
                <ContentSection title="Outstanding Tasks" displayStyle={ContentSectionDisplayStyle.Fieldset} panelStyle={ContentSectionPanelStyle.BootstrapDefault} iconKind="icon" iconClass="ok">
                    {(() => {
                        if (tasks != null) {
                            return <TaskList tasks={tasks} showFilterUI={false} statusFilter={taskStatus} onFilterStatusChange={onFilterStatusChange} />;
                        } else {
                            return <BsLoadSpinner message="Loading related tasks" />;
                        }
                    })()}
                </ContentSection>
            </div>
        );
    }
}