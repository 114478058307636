import * as Constants from "../constants";
import { login } from "../api/login";
import { getDataManagerAsync } from "../api/data-manager";
import { SyncAgent } from "../api/syncagent";
import { formValueSelector } from 'redux-form';

function onEmptyState(manager, res, dispatch, getState) {
    const agent = new SyncAgent(manager);
    dispatch({
        type: Constants.LOGIN_PROGRESS_MESSAGE,
        payload: "Performing first-time download ..." 
    });
    agent.pull({ token: res.token }).then(() => {
        dispatch({
            type: Constants.FORM_RESET,
            form: 'login',
        });
        dispatch({
            type: Constants.LOGIN_USER_SUCCESS,
            payload: res
        });
    });
}

const formState = formValueSelector("login");

export function loginUser() {
    return (dispatch, getState) => {
        dispatch({
            type: Constants.LOGIN_USER_PENDING
        });
        const s = getState();
        login(formState(s,'username'), formState(s, 'password')).then(res => {
            getDataManagerAsync().then(manager => {
                if (manager.getYards().length == 0) {
                    onEmptyState(manager, res, dispatch, getState);
                } else {
                    dispatch({
                        type: Constants.FORM_RESET,
                        form: 'login',
                    });
                    dispatch({
                        type: Constants.LOGIN_USER_SUCCESS,
                        payload: res
                    });
                }
            });
        }).catch(err => {
            dispatch({
                type: Constants.LOGIN_USER_ERROR,
                payload: err
            });
        });
    };
}

export function logoutUser() {
    return {
        type: Constants.LOGOUT_USER,
    };
}

export function setOnline() {
    return { type: Constants.NAVIGATOR_ONLINE };
}

export function setOffline() {
    return { type: Constants.NAVIGATOR_OFFLINE };
}