import { SET_TITLE } from '../constants';

interface ITitleOptions {
    app: string;
    document: string;
}

export function setTitle(options: ITitleOptions) {
    return {
        type: SET_TITLE,
        payload: options
    };
}