import * as React from "react";
import Drawer from 'rc-drawer';
import { ReactNode, CSSProperties } from 'react';

// export const ModalContent = (props) => <div className="modal-content">{props.children}</div>;
// export const ModalHeader = (props) => <div className="modal-header">{props.children}</div>;
// export const ModalBody = (props) => <div className="modal-body" style={props.style}>{props.children}</div>;
// export const ModalFooter = (props) => <div className="modal-footer">{props.children}</div>;

export const ModalContent = ({ children }) => <div style={{padding:15}}>{children}</div>;
export const ModalHeader = ({ children }) => <h2>{children}</h2>;
export const ModalBody = ({ children, style }: { children: ReactNode, style?: CSSProperties}) => <div style={style}>{children}</div>;
export const ModalFooter = ({ children }) => children;

export const BottomDrawer = ({children, open}) => open ? <Drawer placement="bottom" handler={false} level={null} open={open}>
    {children}
</Drawer> : null;