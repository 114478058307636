import * as React from "react";
import { connect } from "react-redux";
import { TaskStatus } from "../api/twu-contracts";
import { setTitle } from '../actions/title';
import { TaskList } from "../components/task-list";
import { fetchTasks } from "../actions/tasks";
import { DelayLoadContainer } from "../components/delay-load-container";

function mapStateToProps(state) {
    return {
        tasks: state.tasks
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchTasks: (args) => dispatch(fetchTasks(args)),
        setTitle: (args) => dispatch(setTitle(args))
    };
}

interface ITasksPageProps {
    tasks: any;
    fetchTasks: (args) => void;
    setTitle: (args) => void;
}

@connect(mapStateToProps, mapDispatchToProps)
export default class TasksPage extends React.Component<ITasksPageProps, any> {
    fnRenderModel: (model) => JSX.Element;
    fnFilterStatusChange: (status) => void;
    constructor(props) {
        super(props);
        this.fnRenderModel = this.renderModel.bind(this);
        this.fnFilterStatusChange = this.onFilterStatusChange.bind(this);
    }
    onFilterStatusChange(status: TaskStatus) {
        this.props.fetchTasks({ statusFilter: status });
    }
    componentDidMount() {
        this.props.setTitle({ app: "Tasks" });
        this.props.fetchTasks({ statusFilter: TaskStatus.NotComplete });
    }
    renderModel(model: any): JSX.Element {
        const modelJS = model;
        const tasks = modelJS.list;
        const status = modelJS.statusFilter;
        return <TaskList tasks={tasks} showFilterUI={true} statusFilter={status} onFilterStatusChange={this.fnFilterStatusChange} />;
    }
    render(): JSX.Element {
        return <DelayLoadContainer 
                    name="Yard Details" 
                    branch={this.props.tasks}
                    onRenderModel={this.fnRenderModel} />;
    }
}