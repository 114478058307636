import { getDataManagerAsync } from "./data-manager";
import { IUserInfo, IVisit, ITask, ITaskDetails, TaskStatus, ITaskNote, ChangeActionType } from "./twu-contracts";
import * as ChangeUtil from "./change-utils";
import * as uuid from "node-uuid";
import * as moment from "moment";
import { logInfo } from "../utils/logger";
import * as DisplayUtils from "../utils/display";

export interface ITaskFetchOptions {
    statusFilter: TaskStatus;
}

export function getEffectiveStatus(task: ITask): TaskStatus {
    const status = ChangeUtil.getMostRecentPropertyChange(task, "status");
    return status || task.status;
}

export async function getTasksAsync(options: ITaskFetchOptions): Promise<ITask[]> {
    const manager = await getDataManagerAsync();
    if (options.statusFilter == null) {
        return manager.getTasks();
    } else if (options.statusFilter === TaskStatus.NotComplete) {
        return manager.getTasks()
                      .filter(t => getEffectiveStatus(t) != TaskStatus.Complete);
    } else {
        return manager.getTasks()
                      .filter(t => getEffectiveStatus(t) === options.statusFilter)
                      ;
    }
}

export async function getTasksForMemberAsync(options: { id: number, statusFilter: TaskStatus }): Promise<ITask[]> {
    const manager = await getDataManagerAsync();
    if (options.statusFilter == null) {
        return manager.getTasks()
                      .filter(t => t.forMember == options.id)
                      ;
    } else if (options.statusFilter == TaskStatus.NotComplete) {
        return manager.getTasks()
                      .filter(t => getEffectiveStatus(t) != TaskStatus.Complete && t.forMember == options.id)
                      ;
    } else {
        return manager.getTasks()
                      .filter(t => getEffectiveStatus(t) === options.statusFilter && t.forMember == options.id)
                      ;
    }
}

export async function getTasksForYardAsync(options: { id: number, statusFilter: TaskStatus }): Promise<ITask[]> {
    const manager = await getDataManagerAsync();
    const memberIds = manager.getMembers()
        .filter(m => m.yardId == options.id)
        
        .map(m => m.id);

    if (options.statusFilter == null) {
        return manager.getTasks()
                        .filter(t => memberIds.indexOf(t.forMember) >= 0)
                        ;
    } else {
        return manager.getTasks()
                        .filter(t => memberIds.indexOf(t.forMember) >= 0 && getEffectiveStatus(t) === options.statusFilter)
                        ;
    }
}

export async function assignTaskAsync(options): Promise<ITask> {
    const manager = await getDataManagerAsync();
    const task: ITask = options.task;
    if (task.id) {
        manager.updateTask(task);
    } else {
        task.id = uuid.v4();
        manager.addTask(task);
    }
    return task;
}

interface SetTaskStatusResult {
    id: string;
    status: TaskStatus;
    note?: ITaskNote;
}

export interface SetTaskStatusOptions {
    id: string;
    status: TaskStatus;
    note?: ITaskNote;
    onComplete?: Function;
}

export async function updateTaskDueOnAsync(options): Promise<ITask> {
    const manager = await getDataManagerAsync();
    const task = manager.getTaskById(options.id);
    const newDueOn: string = options.dueOn;
    if (task && task.serverId != null) {
        const change = ChangeUtil.collectNewTaskDueOn(task, newDueOn);
        const t = await manager.storePendingChangesAsync(task, ChangeUtil.EditableObjectType.Task, [ change ]);
        return task;
    } else if (task) {
        //As this is still only known locally, we can set new status directly
        task.dueOn = newDueOn;
        const t = await manager.updateTaskAsync(task);
        return t;
    }

    throw Error("Task not found");
}

export async function setTaskStatusAsync(options: SetTaskStatusOptions): Promise<SetTaskStatusResult> {
    let note;
    if (options.note) {
        note = await addTaskNoteAsync(options.id, options.note);
    }
    const manager = await getDataManagerAsync();
    const task = manager.getTaskById(options.id);
    if (!task) throw new Error("task not found");

    const newStatus: TaskStatus = options.status;
    if (task.serverId != null) {
        const change = ChangeUtil.collectObjectChange(task, ChangeUtil.EditableObjectType.Task, "status", obj => obj.serverId || obj.id, obj => newStatus);
        const [ st, t ] = await Promise.all([ newStatus, manager.storePendingChangesAsync(task, ChangeUtil.EditableObjectType.Task, [ change ]) ]);
        return {
            id: t.id,
            status: st,
            note: note
        };
    } else {
        //As this is still only known locally, we can set new status directly
        task.status = options.status;
        const [ st, t ] = await Promise.all([ newStatus, manager.updateTaskAsync(task) ]);
        return {
            id: t.id,
            status: st,
            note: note
        };
    }
}

export function createNewNote(noteText: string): ITaskNote {
    const note: ITaskNote = {
        id: uuid.v4(),
        date: moment.utc().format(DisplayUtils.DATE_FORMAT),
        notes: noteText
    };
    return note;
}

export async function addTaskNoteAsync(taskId: string, note: ITaskNote): Promise<ITaskNote> {
    const manager = await getDataManagerAsync();
    const task = manager.getTaskById(taskId);
    if (task != null) {
        logInfo("Found task");
        //Whether the task is synced or not, the note still must go into the outbound queue
        const newNote = ChangeUtil.collectNewTaskNote(task, note);
        const res = await manager.storePendingChangesAsync(task, ChangeUtil.EditableObjectType.Task, [ newNote ]);
        if (res != null) {
            logInfo("Stashed pending change");
            //If un-synced push the note to the local task as well
            if (task.serverId != null) {
                await manager.makeUnsyncedTaskChangeAsync(task, t => {
                    if (t.notes == null) {
                        t.notes = [];
                    }
                    t.notes.push(note);
                    logInfo("Added note to local task");
                });
            }
            return note;
        }
        throw new Error("Failed to add note to task");
    } else {
        throw new Error(`No such task with id: ${taskId}`);
    }
}

export async function getTaskNotesAsync(taskId): Promise<ITaskNote[]> {
    const manager = await getDataManagerAsync();
    let task = manager.getTaskById(taskId);
    
    if (!task) {
        task = manager.getTaskByServerId(taskId);
    }
    if (task) {
        //IMPORTANT: Pay attention to the slice(0)
        //
        //We want a *clone* of the task notes array not a reference to the
        //original. We don't want to inadverdently push pending notes to the
        //original task notes array below!
        const notes = (task.notes || []).slice(0);
        //Collect pending notes 
        const pendingNotes = manager.getNotesForTask(task);
        /*
        //If it has a server-id, notes would've been stored against that
        if (task.serverId != null) {
            pendingNotes = manager.getNotesForTask(task.serverId);
        } else {
            pendingNotes = manager.getNotesForTask(taskId);
        }
        */
        for (const pn of pendingNotes) {
            notes.push(pn.note);
        }
        return notes;
    } else {
        throw new Error(`No such task with id: ${taskId}`);
    }
}

export async function getAssignableUsersAsync(): Promise<IUserInfo[]> {
    const manager = await getDataManagerAsync();
    return manager.getUsers();
}

export async function getTaskDetailsAsync(id): Promise<ITaskDetails> {
    const manager = await getDataManagerAsync();
    let task = manager.getTaskById(id);
    if (task == null) {
        task = manager.getTaskByServerId(id);
    }
    if (task) {
        const member = manager.getMemberById(task.forMember);
        if (member != null) {
            let visit: IVisit[] | undefined;

            if (task.forVisit != null) {
                const forVisit = task.forVisit;
                const vFilter = (v: IVisit) => v.id == forVisit;
                visit = manager.getYards()
                               .filter(y => !!y.visits && y.visits.filter(vFilter).length == 1)
                               .map(y => y.visits!.filter(vFilter)[0]);
            }
            let status = manager.getTaskStatus(task.id);
            if (status == null) {
                status = ChangeUtil.getMostRecentPropertyChange(task, "status");
                if (status == null) {
                    let pt = manager.fetchTask(task.id);
                    if (pt != null) {
                        status = pt.status;
                    } else {
                        status = task.status;
                    }
                }
            }
            
            const taskDetails: ITaskDetails = {
                id: task.id,
                serverId: task.serverId,
                assignedToUser: task.assignedToUser,
                assignedToMember: member,
                forVisit: visit && visit[0],
                title: task.title,
                description: task.description,
                status: status,
                dueOn: task.dueOn
            };
            return taskDetails;
        }
    }
    throw new Error(`No such task found with id of: ${id}`);
}