import * as moment from "moment";
import * as React from "react";
import { EditableField } from "../components/editable-field";
import {
    IPerson,
    ISyncable,
    IVisit,
    IAddressable,
    IYard
} from "../api/twu-contracts";

export const DEFAULT_UI_NOTIFICATION_SETTINGS = {
    position: "top",
    effect: "stackslide"
};

export const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

export function utcNowAtTimeString(h: number, m: number): string {
    const now = moment().hour(h).minute(m).second(0).utc();
    return now.format(DATE_FORMAT);
}

export function utcNowString(): string {
    return moment.utc().format(DATE_FORMAT);
}

export function getFullName(person: IPerson): string {
    return `${person.firstName} ${person.lastName}`;
}

export function getVisitDateReferenceFromNow(visit: IVisit) : string {
    return moment.utc(visit.startDate + " " + visit.startTime).from(moment.utc());
}

export function getAddressString(address: IAddressable) : string {
    const parts = [address.address, address.suburb, address.postcode, address.state];
    const addr = parts.filter(p => p != null && p != "");
    return addr.join(", ");
}

export function isVisitCompleted(visit: IVisit): boolean {
    return visit.endDate != null 
        && visit.endTime != null;
}

export function getEditablesOfComponent(comp: React.Component<any, any>): EditableField[] {
    const editables: EditableField[] = [];
    for (const refc in comp.refs) {
        if (comp.refs.hasOwnProperty(refc)) {
            const editable = comp.refs[refc];
            if (editable instanceof EditableField) {
                editables.push(editable);
            }
        }
    }
    return editables;
}

export function getVisitDuration(visit: IVisit): string {
    const diff = moment.utc(visit.startDate + " " + visit.startTime, DATE_FORMAT)
                       .diff(moment.utc(visit.endDate + " " + visit.endTime, DATE_FORMAT));
    return moment.duration(diff).humanize();
}

export function getLocalDisplay(dateStr: string): string|null {
    const m = getMomentUtc(dateStr);
    return m ? m.local().format("DD MMM YYYY h:mm a") : null;
}

export function getMomentUtc(dateStr: string): moment.Moment {
    
        return moment.utc(dateStr, DATE_FORMAT);
    
}

export function isSyncPending(sync: ISyncable) : boolean {
    return sync.id != null && sync.serverId == null;
}

export function getVisitDurationElapsed(visit: IVisit): string {
    return getVisitDurationElapsedFrom(visit.startDate, visit.startTime);
}

export function getVisitDurationElapsedFrom(startDate: string, startTime: string, refDate?: moment.Moment): string {
    const start = moment.utc(`${startDate} ${startTime}`, DATE_FORMAT);
    if (refDate != null) {
        return formatElapsedTime(refDate.diff(start, "seconds"));
    } else {
        return formatElapsedTime(moment.utc().diff(start, "seconds"));
    }
}

export function formatElapsedTime(elapsedSeconds: number): string {
    const h = Math.floor(elapsedSeconds / 3600);
    const m = Math.floor(elapsedSeconds % 3600 / 60);
    const s = Math.floor(elapsedSeconds % 3600 % 60);
    return ((h > 0 ? h + ":" + (m < 10 ? "0" : "") : "") + m + ":" + (s < 10 ? "0" : "") + s);
}

export function getIncompleteVisits(yard: IYard): IVisit[] {
    return (yard.visits || []).filter((v) => !isVisitCompleted(v));
}

export function getDefaultBootstrapModalProps(): any {
    const bsModalProps = {
        className: "Modal__Bootstrap modal-dialog",
        style: {
            overlay : {
                position          : 'fixed',
                top               : 0,
                left              : 0,
                right             : 0,
                bottom            : 0,
                backgroundColor   : 'rgba(255, 255, 255, 0.75)'
            },
            content: {
                position                   : null,
                top                        : null,
                left                       : null,
                right                      : null,
                bottom                     : null,
                border                     : null,
                background                 : null,
                overflow                   : null,
                WebkitOverflowScrolling    : null,
                borderRadius               : null,
                outline                    : null,
                padding                    : null
            }
        }
    };
    return bsModalProps;
}