import * as React from "react";
import {
    DropdownButton,
    MenuItem,
    Collapse,
    FormGroup,
    InputGroup,
    FormControl,
    Button
} from "react-bootstrap";
import {
    invertComparatorResult,
    booleanComparator,
    buildYardVisitCountComparator,
    buildYardLastVisitComparator,
    getMostRecentYardVisit
} from "../api/comparators";
import { NBSP } from "../constants";
import { Link } from "react-router-dom";
import { YardSortType, SortDirection, IYard, isPriorityVisit } from "../api/twu-contracts";
import * as EnumUtil from "../utils/enum";
import * as DisplayUtils from "../utils/display";
import { TwuIcons } from "./twu-icons";
import debounce = require("lodash.debounce");
import orderBy = require("lodash.orderby");
import { AutoSizer, List } from 'react-virtualized';
import { ReactNode } from 'react';
import { Icons } from "./icons";

interface IYardListProps {
    yards: Readonly<IYard[]>;
    sort: Readonly<YardSortType[]>;
    sortDirection: SortDirection;
    searchText: string;
    scroll: number;
    onSearchTextChanged: (text: string) => void;
    onScrollChanged: (scroll: number) => void;
}

function sortYards(yards: Readonly<IYard[]>, sortOn: YardSortType[], sortDir: SortDirection): Readonly<IYard[]> {
    if (sortOn != null && sortOn.length > 0) {
        const sortProps = [] as string[];
        const sortDirs = [] as ('asc' | 'desc')[];
        const sortArgs = [] as [string, boolean][];
        for (const prop of sortOn) {
            let propName: string|null = null;
            switch (prop) {
                case YardSortType.LastVisit:
                    propName = "lastVisit";
                    break;
                case YardSortType.Name:
                    propName = "name";
                    break;
                case YardSortType.Postcode:
                    propName = "postcode";
                    break;
                case YardSortType.Priority:
                    propName = "isPriorityVisit";
                    break;
                case YardSortType.VisitCount:
                    propName = "visitCount";
                    break;
                default:
                    console.warn('Unknown sort prop', prop);
                    return yards;
            }
            sortProps.push(propName);
            sortDirs.push((sortDir == SortDirection.Desc) ? 'desc' : 'asc');
            sortArgs.push([propName, (sortDir == SortDirection.Desc)]);
        }
        if (sortArgs.length == 1 && sortArgs[0][0] == "visitCount") {
            return [...yards].sort(buildYardVisitCountComparator(sortArgs));
        } else if (sortArgs.length == 1 && sortArgs[0][0] == "lastVisit") {
            return [...yards].sort(buildYardLastVisitComparator(sortArgs));
        } else if (sortArgs.length == 1 && sortArgs[0][0] == "isPriorityVisit") {
            const sd = sortArgs[0][1];
            return [...yards].sort((a, b) => {
                const aPriority = isPriorityVisit(a);
                const bPriority = isPriorityVisit(b);
                const result = booleanComparator(aPriority, bPriority);
                if (sd === false) {
                    return invertComparatorResult(result);
                } else {
                    return result;
                }
            });
        } else {
            return orderBy(yards, sortProps, sortDirs);
        }
    } else {
        return yards;
    }
}

interface IYardListItemProps {
    yard: IYard;
    style: React.CSSProperties;
}

const YardListItem = (props: IYardListItemProps) => {
    //console.time(`YardListItem(${props.yard.id})::render`);
    const { yard, style } = props;
    const lastVisit = getMostRecentYardVisit(yard);
    let lastVisitComp;
    if (lastVisit != null) {
        const lastVisitHuman = DisplayUtils.getVisitDateReferenceFromNow(lastVisit);
        const lastVisitLocal = DisplayUtils.getMomentUtc(lastVisit.startDate + " " + lastVisit.startTime).local();
        lastVisitComp =
            (<span className="pull-right label label-primary" title={`Yard was last visited on: ${lastVisitLocal.format("dddd, MMM Do")} at ${lastVisitLocal.format("hh:mm a")}`}>
                {Icons.CLOCK} Last Visit: {lastVisitHuman}
            </span>);
    }
    let visitCount;
    if (yard.visits != null && yard.visits.length > 0) {
        visitCount =
            (<span className="pull-right label label-info" title={`Yard has been visited ${yard.visits.length} times`}>
                {Icons.CLOCK} {yard.visits.length} visits
        </span>);
    }
    let priorityFlag;
    if (isPriorityVisit(yard)) {
        priorityFlag =
            (<span className="pull-right label label-danger priority-visit-badge" title="This yard is marked high priority for a visit">
                {TwuIcons.FLAG} Priority Visit
        </span>);
    }
    //console.timeEnd(`YardListItem(${props.yard.id})::render`);
    return <Link to={`/yard/${yard.id}`} key={yard.id} style={style} className='list-group-item yard-list-item'>
        {visitCount}
        {lastVisitComp}
        {priorityFlag}
        <h4 className="list-group-item-heading">{TwuIcons.BUILDING}&nbsp;{yard.name}{yard.yardCode ? ` (${yard.yardCode})` : ""}</h4>
        <p className="list-group-item-text">{DisplayUtils.getAddressString(yard) || NBSP}</p>
    </Link>;
};

export class YardList extends React.Component<IYardListProps, any> {
    constructor(props: IYardListProps) {
        super(props);
        this.state = {
            isOptionsOpen: false,
            textFilter: props.searchText || "",
            sort: props.sort,
            sortDirection: props.sortDirection,
            yardList: null,
            initialScroll: props.scroll
        };
    }
    private mounted: boolean = false;
    private list: any;
    private onListMounted = (component: List) => {
        this.list = component;
    }
    private onClearTextFilter = (e) => {
        const { onSearchTextChanged } = this.props;
        const filtered = this.sortAndFilterYardList("");
        this.setState({ textFilter: "", yardList: filtered });
        if (onSearchTextChanged) {
            onSearchTextChanged("");
        }
    }
    private debouncedUpdateFilter = debounce((text: string) => {
        if (this.mounted) {
            const { onSearchTextChanged } = this.props;
            const filtered = this.sortAndFilterYardList(text);
            this.setState({ textFilter: text, yardList: filtered });
            if (onSearchTextChanged) {
                onSearchTextChanged(text);
            }
        }
    }, 250);
    private onUpdateTextFilter = (e) => {
        const text = e.target.value;
        this.setState({ textFilter: text });
        this.debouncedUpdateFilter(text);
    }
    private onToggleOptions = (e) => {
        e.preventDefault();
        this.setState({ isOptionsOpen: !this.state.isOptionsOpen });
        return false;
    }
    private onApplySort(type: YardSortType, dir: SortDirection): void {
        const textFilter = this.getTextFilter();
        const filtered = this.sortAndFilterYardListInternal(this.props.yards, textFilter, [type], dir);
        this.setState({ sort: [type], sortDirection: dir, yardList: filtered }, () => {
            if (this.list) {
                this.list.forceUpdateGrid();
            }
        });
    }
    private onRenderNoRows = () => {
        if (this.props.yards.length == 0) {
            return <div key="no-yards" className="alert alert-danger">
                <p>No yards found. Ensure that you have <Link to="/sync">synchronised</Link> your changes</p>
            </div>;
        } else {
            return <div key="no-yards" className="alert alert-danger">
                <p>No yards found based on your current filter</p>
            </div>;
        }
    }
    private onRowsRendered = debounce(({
        overscanStartIndex,
        overscanStopIndex,
        startIndex,
        stopIndex
    }) => {
        if (this.mounted) {
            this.setState({ initialScroll: null });
            const { onScrollChanged } = this.props;
            if (onScrollChanged) {
                onScrollChanged(stopIndex);
            }
        }
    }, 1);
    private onRenderYardListRow = ({
        key,         // Unique key within array of rows
        index,       // Index of row within collection
        isScrolling, // The List is currently being scrolled
        isVisible,   // This row is visible within the List (eg it is not an overscanned row)
        style        // Style object to be applied to row (to position it)
    }) => {
        const { yardList } = this.state;
        const yard: IYard = yardList[index];
        
        return <YardListItem key={key} style={style} yard={yard} />;
    }
    private sortAndFilterYardListInternal(origYardList: Readonly<IYard[]>, textFilter: string, sort, sortDirection): Readonly<IYard[]> {
        const sortOn = sort || [];
        const sortedYards = sortYards(origYardList, sortOn, sortDirection);
        if (textFilter.length == 0) {
            return sortedYards;
        }
        const lowerTextFilter = textFilter.toLowerCase();
        return sortedYards.filter(y => (y.yardCode || "").toLowerCase().indexOf(lowerTextFilter) >= 0
            || y.name.toLowerCase().indexOf(lowerTextFilter) >= 0);

    }
    private sortAndFilterYardList(textFilter: string): Readonly<IYard[]> {
        const { sort, sortDirection } = this.state;
        const { yards } = this.props;
        return this.sortAndFilterYardListInternal(yards, textFilter, sort, sortDirection);
    }
    private getTextFilter(): string {
        return (this.state.textFilter || "").toLowerCase();
    }
    componentDidMount() {
        this.mounted = true;
        const textFilter = this.getTextFilter();
        this.setState({
            yardList: this.sortAndFilterYardList(textFilter)
        });
    }
    componentWillUnmount() {
        this.mounted = false;
    }
    render(): JSX.Element {
        
        const sortOptions: ReactNode[] = [];
        const { sort, sortDirection } = this.state;
        const sortOn = sort || [];
        /*
        const { yards } = this.props;
        const sortedYards = sortYards(yards, sortOn, sortDirection);
        */
        const negatedSortDir = sortDirection == SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc;
        const sortTypes = EnumUtil.getNamesAndValues(YardSortType);
        for (const type of sortTypes) {
            const active = sortOn.indexOf(type.value) >= 0;
            let chevron: ReactNode = null;
            if (active) {
                switch (sortDirection) {
                    case SortDirection.Asc:
                        chevron = TwuIcons.CHEVRON_UP;
                        break;
                    case SortDirection.Desc:
                        chevron = TwuIcons.CHEVRON_DOWN;
                        break;
                }
            }
            const clickHandler = (e) => {
                e.preventDefault();
                this.onApplySort(type.value, negatedSortDir);
                return false;
            };
            sortOptions.push((<MenuItem key={type.name} eventKey={type.value} className={`${active ? "active" : ""}`} onClick={clickHandler}>{chevron} {type.name}</MenuItem>));
        }

        const textFilter = this.getTextFilter();
        const TB_HEIGHT = 40;
        return (<AutoSizer>
            {({ width, height }) => (<div>
                <div style={{ width: width, height: TB_HEIGHT }}>
                    <FormGroup>
                        <InputGroup>
                            <DropdownButton componentClass={InputGroup.Button} className="float-left" title={YardSortType[sort[0]] || "Sort:"} id="yard-list-sort">
                                {sortOptions}
                            </DropdownButton>
                            <FormControl type="text" placeholder="Filter yards by name/code ..." value={this.state.textFilter} onChange={this.onUpdateTextFilter} />
                            <InputGroup.Button>
                                <Button bsStyle="danger" disabled={textFilter.length == 0} onClick={this.onClearTextFilter}>{Icons.CLOSE}</Button>
                            </InputGroup.Button>
                        </InputGroup>
                    </FormGroup>

                </div>
                {/*
                <FormGroup>
                        <InputGroup>
                            <DropdownButton className="float-left" title={YardSortType[sort[0]] || "Sort:"} id="yard-list-sort">
                                {sortOptions}
                            </DropdownButton>
                            <FormControl type="text" placeholder="Filter yards by name or code ..." value={this.state.textFilter} onChange={this.onUpdateTextFilter} />
                            <InputGroup.Button>
                                <Button bsStyle="danger" disabled={textFilter.length == 0} onClick={this.onClearTextFilter}>{Icons.CLOSE}</Button>
                            </InputGroup.Button>
                        </InputGroup>
                    </FormGroup>
                */}
                {/*
                <div className="container">
                    <div style={{ width: "100%", height: TB_HEIGHT, padding: 8 }}>
                        <span className="float-left">
                            <strong>{TwuIcons.SORT} Sort: </strong>
                            <DropdownButton className="float-left" title={YardSortType[sort[0]] || "None"} id="yard-list-sort">
                                {sortOptions}
                            </DropdownButton> 
                        </span>
                        <MediaQuery maxWidth={640}>
                            <button type="button" className="float-right btn btn-default" onClick={this.onToggleOptions}>
                                {(() => {
                                    if (this.state.isOptionsOpen) {
                                        return TwuIcons.CHEVRON_UP;
                                    } else {
                                        return "..." as any;
                                    }
                                })()}
                            </button>
                            <div className="clearfix" />
                            <Collapse in={this.state.isOptionsOpen}>
                                <div>
                                    <hr />
                                    <FormGroup>
                                        <InputGroup>
                                            <FormControl type="text" placeholder="Filter yards by name or code ..." className="yard-text-filter-input flyout-filter" value={this.state.textFilter} onChange={this.onUpdateTextFilter} />
                                            <InputGroup.Button>
                                                <Button bsStyle="danger" disabled={textFilter.length == 0} onClick={this.onClearTextFilter}>{Icons.CLOSE}</Button>
                                            </InputGroup.Button>
                                        </InputGroup>
                                    </FormGroup>
                                </div>
                            </Collapse>
                        </MediaQuery>
                        <MediaQuery minWidth={640} className="float-right" component="span">
                            <InputGroup style={{ width: 400 }}>
                                <FormControl type="text" placeholder="Filter yards by name or code ..." className="yard-text-filter-input inline-filter"  value={this.state.textFilter} onChange={this.onUpdateTextFilter} />
                                <InputGroup.Button>
                                    <Button bsStyle="danger" disabled={textFilter.length == 0} onClick={this.onClearTextFilter}>{Icons.CLOSE}</Button>
                                </InputGroup.Button>
                            </InputGroup>
                        </MediaQuery>
                        <div className="clearfix" />
                    </div>
                </div>
                */}
                {(() => {
                    if (this.state.yardList) {
                        const extraProps: any = {};
                        if (this.state.initialScroll != null) {
                            extraProps.scrollToIndex = parseInt(this.state.initialScroll, 10);
                        }
                        //NOTE: Using older version of react-virtualized typings (package is latest) as latest version of typings breaks everything else
                        return <List ref={this.onListMounted} {...extraProps} width={width} height={height - TB_HEIGHT} rowHeight={100} rowCount={this.state.yardList.length} overscanRowCount={10} noRowsRenderer={this.onRenderNoRows} rowRenderer={this.onRenderYardListRow} onRowsRendered={this.onRowsRendered} />;
                    } else {
                        return <div className="alert alert-info">
                            Loading yard list ...
                        </div>;
                    }
                })()}
            </div>)}
        </AutoSizer>);
        
    }
}