import * as React from "react";
import { connect } from "react-redux";
import { EditableField } from "../components/editable-field";
import { ContactDetailsPageComponent } from "../components/pages/contact-details";
import { DelayLoadContainer } from '../components/delay-load-container';
import { setTitle } from '../actions/title';
import { fetchContactDetails } from '../actions/person';
import { EditableObjectType } from "../api/change-utils";
import { collectItemChangesAsync } from '../actions/editable';
import { IContact } from '../api/twu-contracts';

import { ISessionState } from '../reducers/session';
import useRouter from 'use-react-router';
import { useEffect } from 'react';
;

function mapStateToProps(state) {
    return {
        contactDetails: state.contactDetails,
        session: state.session
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setTitle: (args) => dispatch(setTitle(args)),
        fetchContactDetails: (args) => dispatch(fetchContactDetails(args))
    };
}

interface IContactDetailsPageProps {
    
    session?: ISessionState;
    contactDetails: any;
    setTitle: (args) => void;
    fetchContactDetails: (args) => void;
}


 function ContactDetailsPage({fetchContactDetails, contactDetails, setTitle, session}: IContactDetailsPageProps) {
    const router = useRouter<{id:string}>();

    const onSaveChanges = (editables: EditableField[], callback: (success: any, saveError?: Error) => void): void => {
        const contact: IContact = contactDetails.model.contact;
        collectItemChangesAsync<IContact>({
            editableObject: contact,
            editableFields: editables,
            type: EditableObjectType.Contact,
            idSelector: obj => obj.id
        }).then(res => {
            callback(res);
        }).catch(err => {
            callback(null, err);
        });
    }
    useEffect(() => {
        setTitle({ app: "Contact Details" });
        fetchContactDetails(router.match.params.id);
    }, [])
    
    const renderModel = (model: any): JSX.Element=> {
        const token = session && session.token;
        return <ContactDetailsPageComponent model={model} session={token} onSaveChanges={onSaveChanges} />;
    }
    
        return <DelayLoadContainer 
                    name="Contact Details" 
                    branch={contactDetails}
                    onRenderModel={renderModel} />;
    
}



export default connect(mapStateToProps, mapDispatchToProps)(ContactDetailsPage);