import forEach = require('lodash.foreach');
import omit = require('lodash.omit');

enum UpsertStatus {
    Inserted,
    Updated
}

//From: https://github.com/techfort/LokiJS/issues/158
function upsertOne<T extends object>(collection: T[], idField: string, record: T, recordUpdater?: (current: T, existing: T) => void): UpsertStatus {
    const existingRecord = collection.filter(a => a[idField] == record[idField])[0];
    if (existingRecord) {
        // The record exists. Do an update.
        const updatedRecord = existingRecord;
        // If a custom updated specified, farm it off to them
        if (recordUpdater != null) {
            recordUpdater(updatedRecord, existingRecord);
        } else {
            // Only update the fields contained in `record`. All fields not contained
            // in `record` remain unchanged.

            
            // this foreach *does* loop over the keys
            forEach(record, (value, key) => {
                updatedRecord[key] = value;
            });
        }
        return UpsertStatus.Updated;
    } else {
        // The record doesn't exist. Do an insert.
        collection.push(record);
        return UpsertStatus.Inserted;
    }
}

export interface IBatchUpsertResult {
    /**
     * The number of records inserted
     */
    inserted: number;
    /**
     * The number of records updated
     */
    updated: number;
}

/**
 * Performs an upsert (insert or update) of the given object (or array of objects) into the
 * specified LokiJS collection
 */
export function lokiUpsert<T extends object>(collection: T[], idField: string, objects: T | T[], recordUpdater?: (current: T, existing: T) => void): IBatchUpsertResult {
    const result: IBatchUpsertResult = {
        inserted: 0,
        updated: 0
    };
    if (objects instanceof Array) {
        forEach(objects, (value, index) => {
            const stat = upsertOne(collection, idField, value, recordUpdater);
            if (stat == UpsertStatus.Inserted) {
                result.inserted++;
            } else if (stat == UpsertStatus.Updated) {
                result.updated++;
            }
        });
    } else {
        const stat = upsertOne(collection, idField, objects, recordUpdater);
        if (stat == UpsertStatus.Inserted) {
            result.inserted++;
        } else if (stat == UpsertStatus.Updated) {
            result.updated++;
        }
    }
    return result;
}