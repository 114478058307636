import * as React from "react";
import { IContact } from "../../api/twu-contracts";
import { EditableFieldContainer, EditableContainerSaveFunc } from "../editable-field-container";
import { EditDataType, buildEditableField, ADDRESS_PROPERTY_SET } from "../editable-field";
import * as ChangeUtils from "../../api/change-utils";
import * as DisplayUtils from "../../utils/display";
import { push } from "../../api/auto-push";
import { ContentSection, ContentSectionDisplayStyle } from "../common";

interface IContactDetailsPageComponentProps {
    model: any;
    session?: string;
    onSaveChanges: EditableContainerSaveFunc;
}

export class ContactDetailsPageComponent extends React.Component<IContactDetailsPageComponentProps, any> {
    
    private fnRequestPush: () => void;
    constructor(props) {
        super(props);
        this.fnRequestPush = this.onRequestPush.bind(this);
    }
    private onRequestPush() {
        if (!this.props.session) {
            alert('Token is unavailable')
            return;
        }
        push({ token: this.props.session });
    }
    render(): JSX.Element {
        const { model, onSaveChanges } = this.props;
        const contact: IContact = model.contact;
        const contactTitles: string[] = model.contactTitles;
        //Build our list of editable fields
        const editableFields: React.ReactNode[] = [];
        const changeDict = ChangeUtils.collectAttachedPendingChanges(contact);
        editableFields.push(buildEditableField(contact, ADDRESS_PROPERTY_SET, "editAddress", "icon", "address", "Address", changeDict, EditDataType.Address));
        editableFields.push(buildEditableField(contact, "phone", "editPhone", "icon", "phone", "Phone", changeDict));
        editableFields.push(buildEditableField(contact, "mobile", "editMobile", "icon", "mobile", "Mobile", changeDict));
        editableFields.push(buildEditableField(contact, "email", "editEmail", "icon", "mail", "Email", changeDict));
        editableFields.push(buildEditableField(contact, "title", "editTitle", "icon", "tag", "Title", changeDict, EditDataType.List, contactTitles));

        return (
            <div className='container-fluid'>
                <br/>
                <ContentSection displayStyle={ContentSectionDisplayStyle.Fieldset} title={DisplayUtils.getFullName(contact)} iconKind="icon" iconClass="user">
                    <div className="well">
                        <div className="form-horizontal">
                            <EditableFieldContainer autoCommit={true} onSaveChanges={onSaveChanges} requestPush={this.fnRequestPush}>
                                {editableFields}
                            </EditableFieldContainer>
                        </div>
                    </div>
                </ContentSection>
            </div>
        );
    }
}