import * as Constants from "../constants";
import { setupInitialState, buildReducerFunction, IReducerBranchStateImmutable, IReducerConfiguration } from "./utils";
import { IYardListModelImmutable } from "../api/twu-contracts";

const INITIAL_STATE: IReducerBranchStateImmutable<IYardListModelImmutable> = setupInitialState<IYardListModelImmutable>();

const config: IReducerConfiguration<IYardListModelImmutable> = {
    initialState: INITIAL_STATE,
    pending: {
        key: Constants.FETCH_YARDS_PENDING
    },
    error: {
        key: Constants.FETCH_YARDS_ERROR
    },
    success: {
        key: Constants.FETCH_YARDS_SUCCESS
    }
};

const yardReducer = buildReducerFunction(config);

export default yardReducer;