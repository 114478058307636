import * as React from "react";
import { WrappedFieldProps } from 'redux-form';
import { IInputFieldProps, renderInputFieldCommon } from "./common";


export type IReduxInputFieldProps = IInputFieldProps & WrappedFieldProps;

/**
 * This represents a convenience wrapper for bootstrap form input controls
 */

const ReduxInputField = (field: IReduxInputFieldProps) => {  // Define stateless component to render input and errors
    const { meta } = field;
  return <div>
    <ReduxInputFieldCompat {...field} />
    {field.meta.touched &&
     field.meta.error &&
     <span className="error">{field.meta.error}</span>}
  </div>
}


/**
 * This represents a convenience wrapper for bootstrap form input controls
 */
const ReduxInputFieldCompat = (props: IReduxInputFieldProps) => {
    const extraProps: any = { ...props.extraInputProps, ...props.input };
    if (props.type == "textarea") {
        extraProps.componentClass = props.type;
    }
    return renderInputFieldCommon(props, extraProps);
};


export default ReduxInputField;