import * as React from "react";
import { connect } from "react-redux";
import { HomePageComponent } from "../components/pages/home";
import { logoutUser } from '../actions/session';
import { setTitle } from '../actions/title';
import { setActiveYard } from '../actions/context';
import { ISessionState } from '../reducers/session';

function mapStateToProps(state) {
    return {
        session: state.session
    };
}

function mapDispatchToProps(dispatch) {
    return {
        logout: () => dispatch(logoutUser()),
        setTitle: (args) => dispatch(setTitle(args)),
        setActiveYard: (args) => dispatch(setActiveYard(args))
    };
}

interface IHomePageProps {
    session: ISessionState;
    logout: () => void;
    setTitle: (args) => void;
    setActiveYard: (args) => void;
}

@connect(mapStateToProps, mapDispatchToProps)
export default class HomePage extends React.Component<IHomePageProps, any> {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        this.props.setActiveYard(null);
        this.props.setTitle({ app: "TWU YardApp" });
    }
    render(): JSX.Element {
        const { session, logout } = this.props;
        const user = session.user && session.user.username;
        const online = session.online;
        return <HomePageComponent onLogout={logout} displayName={user} online={online} />;
    }
} 