import { ensureCollectionUnique, LokiCollection } from "./persistence";
import {
    IYard,
    IContact,
    IMember,
    ITask,
    IChangeAction,
    IContactTitle,
    IUserInfo
} from "../api/twu-contracts";

class CollectionType {
    public static get YARDS() { return "YARDS"; }
    public static get CONTACTS() { return "CONTACTS"; }
    public static get NONMEMBERS() { return "NONMEMBERS"; }
    public static get MEMBERS() { return "MEMBERS"; }
    public static get TASKS() { return "TASKS"; }
    public static get CONTACT_TITLES() { return "CONTACT_TITLES"; }
    public static get CHANGES() { return "CHANGES"; }
    public static get USERS() { return "USERS"; }
}


export function ensureCollections() {
    getYardCollection();
    getContactCollection();
    getMemberCollection();
    getTaskCollection();
    getContactTitleCollection();
    // getChangesCollection();
    getUserCollection();
}

export function getYardCollection(): Promise<IYard[]> {
    return ensureCollectionUnique<IYard>(CollectionType.YARDS, "id");
}

export function getContactCollection(): Promise<IContact[]> {
    return ensureCollectionUnique<IContact>(CollectionType.CONTACTS, "id");
}

export function getNonMemberCollection(): Promise<IContact[]> {
    return ensureCollectionUnique<IContact>(CollectionType.NONMEMBERS, "id");
}

export function getMemberCollection(): Promise<IMember[]> {
    return ensureCollectionUnique<IMember>(CollectionType.MEMBERS, [ "id" ]);
}

export function getTaskCollection(): Promise<ITask[]> {
    return ensureCollectionUnique<ITask>(CollectionType.TASKS, ["id", "serverId"]);
}

export function getContactTitleCollection(): Promise<IContactTitle[]> {
    return ensureCollectionUnique<IContactTitle>(CollectionType.CONTACT_TITLES, "name");
}

// export function getChangesCollection(): Promise<IYard[]> {
//     return ensureCollectionUnique<IChangeAction>(CollectionType.CHANGES, "id");
// }

export function getUserCollection(): Promise<IUserInfo[]> {
    return ensureCollectionUnique<IUserInfo>(CollectionType.USERS, "userId");
}