import * as React from "react";
import { connect } from "react-redux";
import { DelayLoadContainer } from '../components/delay-load-container';
;
import { setTitle } from '../actions/title';
import { fetchNonMemberDetails } from '../actions/person';
import { NonMemberDetailsPageComponent } from "../components/pages/nonmember-details";
import { ISessionState } from '../reducers/session';
import useRouter from 'use-react-router';
import { useEffect } from 'react';

function mapStateToProps(state): Partial<INonMemberDetailsPageProps> {
    return {
        nonMemberDetails: state.nonMemberDetails,
        session: state.session
    };
}

function mapDispatchToProps(dispatch): Partial<INonMemberDetailsPageProps> {
    return {
        setTitle: (args) => dispatch(setTitle(args)),
        fetchNonMemberDetails: (args) => dispatch(fetchNonMemberDetails(args))
    };
}

interface INonMemberDetailsPageProps {

    session?: ISessionState;
    nonMemberDetails: any;
    setTitle: (args) => void;
    fetchNonMemberDetails: (args) => void;
}


function NonMemberDetailsPage({
    session, fetchNonMemberDetails, setTitle, nonMemberDetails
}: INonMemberDetailsPageProps) {
    const { match } = useRouter<{ id: string }>();
    const recordId = match.params.id;

    useEffect(() => {
        setTitle({ app: "Non-Member Details" });
        fetchNonMemberDetails(recordId);
    }, [recordId]);


    if (!session || !session.user || !session.token) return <>No session</>;

    const token = session.token;

    function renderModel(model: any): JSX.Element {
        return <NonMemberDetailsPageComponent model={model} session={token} />;
    }

    return <DelayLoadContainer
        name="Non-Member Details"
        branch={nonMemberDetails}
        onRenderModel={renderModel} />;
}


export default connect(mapStateToProps, mapDispatchToProps)(NonMemberDetailsPage);