import {
    IYard,
    IContact,
    IMember,
    ITask,
    IChangeAction,
    ISyncPayload,
    IPushResult,
    IContactTitle,
    TaskStatus,
    ChangeActionType,
    ITaskNote,
    IVisit,
    IVisitStart,
    IVisitEnd,
    IUserInfo,
    EditableObjectType
} from "../api/twu-contracts";
import * as ChangeUtils from "./change-utils";
import { lokiUpsert } from "../utils/loki-upsert";
import * as Logger from "../utils/logger";
import { save, savePromised } from "../store/persistence";
import {
    getYardCollection,
    getContactCollection,
    getMemberCollection,
    getTaskCollection,
    getContactTitleCollection,
    // getChangesCollection,
    getUserCollection,
    getNonMemberCollection
} from "../store/collections";
import * as uuid from "node-uuid";
import { getEffectiveStatus } from "./tasks";
import { isVisitCompleted } from '../utils/display';
import moment = require("moment");

function mapTitles(titles: string[]) : IContactTitle[] {
    return titles.map(t => { return { name: t }; });
}

function isSyncPayload(payload: ISyncPayload | IPushResult): payload is ISyncPayload {
    return (payload as any).contactTitles != undefined;
}

export function getYardIdsFromIncompleteVisit(visit: IIncompleteVisit): number[] {
    return visit.yards.map(y => y.yardId);
}

export interface IIncompleteVisit extends IVisit {
    yards: { yardDesc: string, yardId: number }[];
}

type LokiCollection<T extends object> = T[];
type LokiResultset<T extends object> = Readonly<T>[];


/** changes that are pending being linked to the Loki store */
const PENDING_CHANGES_KEY = "pending_changes";

/**changes that have been incorporated in the loki store.. do we need this? */
const CHANGES_KEY = "changes";

interface IAssociatedChange {
    changes: IChangeAction[]
    id: string | number
    type: EditableObjectType
}

// on system load we parse this, and always refer to the same array. we persist this each time
const cChanges: IChangeAction[] = JSON.parse(window.localStorage[CHANGES_KEY] || "[]");


/**
 * DataManager represents a DbContext-like class that manages all the underlying entities
 */
export class DataManager {
    private yards: LokiCollection<IYard>;
    private contacts: LokiCollection<IContact>;
    private nonmembers: LokiCollection<IContact>;
    private members: LokiCollection<IMember>;
    private tasks: LokiCollection<ITask>;
    private titles: LokiCollection<IContactTitle>;
    private pendingChanges: IChangeAction[];
    private users: LokiCollection<IUserInfo>;

    constructor(cYards: LokiCollection<IYard>,
                cContacts: LokiCollection<IContact>,
                cNonMembers: LokiCollection<IContact>,
                cMembers: LokiCollection<IMember>,
                cTasks: LokiCollection<ITask>,
                cContactTitles: LokiCollection<IContactTitle>,
                cUsers: LokiCollection<IUserInfo>) {
        this.yards = cYards;
        this.contacts = cContacts;
        this.nonmembers = cNonMembers;
        this.members = cMembers;
        this.tasks = cTasks;
        this.titles = cContactTitles;
        this.users = cUsers;

        
        this.pendingChanges = cChanges;

        this.processDataRecovery();
    }

    static getLastPullDate() { return window.localStorage["_LAST_PULL_DATE"]; }

    static getLastPushDate() { return window.localStorage["_LAST_PUSH_DATE"]; }

    public markNewPullDate() {
        window.localStorage["_LAST_PULL_DATE"] = moment.utc().format();
    }

    public markNewPushDate() {
        window.localStorage["_LAST_PUSH_DATE"] = moment.utc().format();
    }

    private processDataRecovery()  {
        // look through all yard visits on the date range in question and generate a new visit
        if (window.localStorage["_TEMP_FIX_"]) return;

        const targetRange = (v: { endDate?: string; }) => v.endDate == "2019-10-02" || v.endDate == "2019-10-01" || v.endDate == "2019-09-30";

        const possible = this.yards.filter(y => y.visits && y.visits.filter(targetRange).length > 0);
        console.log("filtered", possible);
        for (const yard of possible) {
            for (const visit of yard.visits!.filter(targetRange)) {
                console.log(yard, visit);
                this.completeMultiYardVisit(visit.id, visit);
            }
        } 
        window.localStorage["_TEMP_FIX_"] = "SORTED";
    }


    public getCounts(): ILocalCountSummary {
        const visitSets = this.yards.map(y => y.visits);
        const visitsByKey = {};
        for (const vs of visitSets) {
            const incomplete = vs!.filter(v => !isVisitCompleted(v));
            for (const inv of incomplete) {
                if (!visitsByKey[inv.id]) {
                    visitsByKey[inv.id] = 1;
                } else {
                    visitsByKey[inv.id]++;
                }
            }
        }
        return {
            yards: this.yards.length,
            tasks: this.getTasks().filter(t => getEffectiveStatus(t) != TaskStatus.Complete).length,
            pending: this.pendingChanges.length,
            incompleteVisits: Object.keys(visitsByKey).length
        };
    }
    public getIncompleteVisits(): IIncompleteVisit[] {
        const visitSets = this.yards.map(y => ({
            yardId: y.id,
            yardDesc: `${y.name} (${y.yardCode})`,
            visits: y.visits!.filter(v => !isVisitCompleted(v))
        }));
        const visitsByKey = {};
        for (const vs of visitSets) {
            for (const v of vs.visits) {
                //For multi-yard visits, the yards will all have a visit with the same details, but against a different yard id
                //So when we do encounter multi-yard visits, append the extra yards to the first encountered one
                if (!visitsByKey[v.id]) {
                    visitsByKey[v.id] = {
                        ...v,
                        yards: [
                            { yardId: vs.yardId, yardDesc: vs.yardDesc }
                        ]
                    };
                } else {
                    visitsByKey[v.id].yards.push({ yardId: vs.yardId, yardDesc: vs.yardDesc });
                }
            }
        }
        return Object.keys(visitsByKey).map(k => visitsByKey[k]);
    }

    public empty() {
        this.emptyCollection(this.yards);
        this.emptyCollection(this.contacts);
        this.emptyCollection(this.nonmembers);
        this.emptyCollection(this.members);
        this.emptyCollection(this.tasks);
        this.emptyCollection(this.titles);
        this.emptyCollection(this.users);
        //this.emptyCollection(this.changesCollection);
        delete window.localStorage["_LAST_PULL_DATE"];
    }

    private emptyCollection<T extends object>(collection: LokiCollection<T>): void {
        collection.length = 0;
        //HACK: I think this is a bug in LokiJS. Shouldn't these be reset when
        //I clear() a collection?
        //
        //ref: https://github.com/techfort/LokiJS/issues/328
        
    }

    // private setChangeTrackingStatus(enabled: boolean) {
    //     this.contacts.setChangesApi(enabled);
    //     this.nonmembers.setChangesApi(enabled);
    //     this.members.setChangesApi(enabled);
    //     this.yards.setChangesApi(enabled);
    //     this.tasks.setChangesApi(enabled);
    //     this.users.setChangesApi(enabled);
    //     if (!enabled) {
    //         this.contacts.flushChanges();
    //         this.nonmembers.flushChanges();
    //         this.members.flushChanges();
    //         this.yards.flushChanges();
    //         this.tasks.flushChanges();
    //         this.users.flushChanges();
    //     }
    // }

    public async savePendingChanges() {
        const changesJson = JSON.stringify(this.pendingChanges);
        console.log(changesJson);
        window.localStorage[CHANGES_KEY] = changesJson;
        return true;
    }

    public async clearPendingChanges(): Promise<boolean> {
        this.pendingChanges.length = 0;
        return this.savePendingChanges();
    }

    public clearAllButPendingChanges(): Promise<boolean> {
        this.empty();
        return savePromised();
    }

    public getYardById(id: number): IYard|undefined { return this.yards.filter(a => a.id == id)[0]; }
    public getTaskById(id: string): ITask|undefined { return this.tasks.filter(a => a.id == id)[0]; }
    public getTaskByServerId(id: number): ITask|undefined {
        const matches = this.getTasks().filter(t => t.serverId == id);
        if (matches.length == 1) {
            return matches[0];
        }
        return undefined;
    }
    public getContactById(id: number): IContact|undefined { return this.contacts.filter(a => a.id == id)[0]; }
    public getNonMemberById(id: number): IContact|undefined { return this.nonmembers.filter(a => a.id == id)[0]; }
    public getMemberById(id: number): IMember|undefined { return this.members.filter(a => a.id == id)[0]; }
    public getUserById(id: number): IUserInfo|undefined { return this.users.filter(a => a.userId == id)[0]; }
    public getUserBySub(sub: string): IUserInfo|undefined { return this.users.filter(a => a.sub == sub)[0]; }
    public getContactTitles(): LokiResultset<IContactTitle> { return this.titles; }
    public getYards(): LokiResultset<IYard> { return this.yards; }
    public getTasks(): LokiResultset<ITask> { return this.tasks; }
    public getContacts(): LokiResultset<IContact> { return this.contacts; }
    public getNonMembers(): LokiResultset<IContact> { return this.nonmembers; }
    public getMembers(): LokiResultset<IMember> { return this.members; }
    public getUsers(): LokiResultset<IUserInfo> { return this.users; }
    public getPendingChanges(): IChangeAction[] { return this.pendingChanges; }

    public collectChanges(): Promise<IChangeAction[]> {
        return Promise.resolve(this.pendingChanges.filter(chng => chng.isClientSide === true));
    }

    public update(payload: ISyncPayload | IPushResult, clearBeforeApplying: boolean = false): Promise<boolean> {
        
        if (clearBeforeApplying === true) {
            this.empty();
        }
         
    
        this.store(this.yards, payload.yards, "Yards");
        this.store(this.contacts, payload.contacts, "Contacts");
        this.store(this.nonmembers, payload.nonMembers, "NonMembers");
        this.store(this.members, payload.members, "Members");
        this.store(this.tasks, payload.tasks, "Tasks");
        this.store(this.users, payload.users, "Users", "userId");
        if (isSyncPayload(payload)) {
            this.store(this.titles, mapTitles(payload.contactTitles), "Contact Titles", "name");
        }
        
        
        return savePromised();
    }

    public makeUnsyncedTaskChangeAsync(task: ITask, applyCb: (t: ITask) => void): Promise<boolean> {
        const t = this.fetchTask(task.id);
        if (t != null) {
            applyCb(t);
        }
        if (task != t) {
            applyCb(task);
        }
        return savePromised();
    }

    public getNotesForTask(task: ITask): { id: string, note: ITaskNote }[] {
        const taskId: string|number = task.id;
        const results = this.pendingChanges
                            .filter(chng => chng.objectId == taskId && chng.type == ChangeActionType.NewTaskNote)
                            .map(chng => ({ id: chng.id, note: chng.data.note }));

        //Need to consider new note actions in the task changes object too
        const resultsAttached = task.changes
                                    .filter(chng => chng.type == ChangeActionType.NewTaskNote)
                                    .map(chng => ({ id: chng.id, note: chng.data.note }));
        const notes = [ ...results, ...resultsAttached ];
        return notes;
    }

    public fetchTask(guid: string): ITask|null {
        const results = this.pendingChanges.filter(c => c.type == ChangeActionType.NewTask && c.objectId == guid);
        if (results.length == 1) {
            return results[0].data.task;
        } else {
            //It may be an un-synced task that has been round-tripped back
            const rTasks = this.tasks.filter(t => t.id == guid);
            if (rTasks.length == 1) {
                return rTasks[0];
            }
            return null;
        }
    }

    public getTaskStatus(taskGuid: string): TaskStatus |null{
        const results = this.pendingChanges
                            .filter(chng => chng.objectId == taskGuid &&
                                           chng.type == ChangeActionType.ObjectPropertyChange &&
                                           chng.data.property == "status");
                            
        if (results.length > 0) {
            return results[0].data.value; //TODO: Hopefully this is the most recent one, if not ...
        } else {
            return null;
        }
    }

    private attachAndStoreChanges(obj: any, changes: IChangeAction[]) {
        if (obj.changes == null) {
            obj.changes = [];
        }
        obj.changes.push(...changes);

        for (const c of changes) this.pendingChanges.push(c);
        this.savePendingChanges();
        Logger.logInfo(`"Pending Changes" - Inserted: ${changes.length}, Now has: ${this.pendingChanges.length}`);
    }

    private updateLocalStorage(key: string, changes: IAssociatedChange) {
        // first get the current values
        const previous = JSON.parse(window.localStorage[key] || '[]');

        // tack on the new changes, individually we serialize them so it is easier to pick them out/compare as a string
        window.localStorage[key] = JSON.stringify([...previous, JSON.stringify(changes)]);
    }
    
    

    public storePendingChangesAsync(obj: { id: string|number}, type: ChangeUtils.EditableObjectType, changes: IChangeAction[]): Promise<any> {
        let ret: any = undefined;
        // immediately append this to local storage incase the user closes the window as this should be fast, not dealing with encryption
        this.updateLocalStorage(PENDING_CHANGES_KEY, { id: obj.id, type, changes});

        const strId = `${obj.id}`;
        const numId = Number(obj.id);

        //NOTE: We have to "re-fetch" the object to update as it is the source of truth
        //and not the object passed in, as it may be a re-projection from the redux copy
        switch (type) {
            case ChangeUtils.EditableObjectType.Contact:
                ret = this.getContactById(numId);
                this.attachAndStoreChanges(ret, changes);
                return this.updateContactAsync(ret);
            case ChangeUtils.EditableObjectType.Member:
                ret = this.getMemberById(numId);
                this.attachAndStoreChanges(ret, changes);
                return this.updateMemberAsync(ret);
            case ChangeUtils.EditableObjectType.Task:
                ret = this.getTaskById(strId);
                this.attachAndStoreChanges(ret, changes);
                return this.updateTaskAsync(ret);
            case ChangeUtils.EditableObjectType.Yard:
                ret = this.getYardById(numId);
                this.attachAndStoreChanges(ret, changes);
                return this.updateYardAsync(ret);
        }
        throw new Error(`Unsupported editable object type: ${type}`);
    }

    public updateContact(contact: IContact) {
        
        lokiUpsert(this.contacts, "id", contact);
        save();
    }

    public updateMember(member: IMember) {
        
        lokiUpsert(this.members, "id", member);
        save();
    }

    public updateTask(task: ITask) {
        
        lokiUpsert(this.tasks, "id", task);
        save();
    }

    public updateYard(yard: IYard) {
        lokiUpsert(this.yards, "id", yard);
        save();
    }

    public updateContactAsync(contact: IContact): Promise<IContact> {
        
        lokiUpsert(this.contacts, "id", contact);
        return savePromised().then(res => {
            if (res === true) {
                return contact;
            } else {
                throw new Error("Failed to save contact");
            }
        });
    }

    public updateMemberAsync(member: IMember): Promise<IMember> {
        lokiUpsert(this.members, "id", member);
        return savePromised().then(res => {
            if (res === true) {
                return member;
            } else {
                throw new Error("Failed to save member");
            }
        });
    }

    public updateTaskAsync(task: ITask): Promise<ITask> {
        lokiUpsert(this.tasks, "id", task);
        
        return savePromised().then(res => {
            if (res === true) {
                return task;
            } else {
                throw new Error("Failed to save task");
            }
        });
    }

    public updateYardAsync(yard: IYard): Promise<IYard> {
        lokiUpsert(this.yards, "id", yard);
        return savePromised().then(res => {
            if (res === true) {
                return yard;
            } else {
                throw new Error("Failed to save yard");
            }
        });
    }

    private store<T extends object>(collection: LokiCollection<T>, items: T[], collectionName: string, collectionKey: string = "id") {
        const stat = lokiUpsert(collection, collectionKey, items);
        Logger.logInfo(`${collectionName} - Inserted: ${stat.inserted}, Updated: ${stat.updated}`);
    }

    public addTask(task: ITask): ITask {
        const change = ChangeUtils.collectNewTask(task);
        change.data = { task: task };
        this.pendingChanges.push(change);
        this.savePendingChanges();
        this.tasks.push(task);
        save();
        return task;
    }

    private applyYardPendingChange(yards: IYard[], visit: IVisit, doUpdate: boolean) {
        
        for (const yard of yards) {
            if (yard.visits != null) {
                yard.visits.push(visit);
            } else {
                yard.visits = [ visit ];
            }
            if (doUpdate) {
                lokiUpsert(this.yards, "id", yard);
            }
        }
    }

    private getYardsByIds(yardIds: number[]) {
        return this.yards.filter(y => yardIds.indexOf(y.id) >= 0);
    }

    public addMultiYardVisit(yardIds: number[], model: IVisitStart): IVisit {

        const newVisit =  { 
            id: uuid.v4(),
            startDate: model.startDate,
            startTime: model.startTime,
            startLocation: model.startLocation,
            yardIds: [...yardIds]
        };
        this.applyYardPendingChange(this.getYardsByIds(yardIds), newVisit, true);

        const change = ChangeUtils.collectNewMultiYardVisit(yardIds, newVisit);

        this.pendingChanges.push(change);
        this.savePendingChanges();

        // this might be interrupted here so
        save();
        return newVisit;
    }

    public completeMultiYardVisit(visitId: string, model: IVisitEnd): IVisit {
        // Get yards that have a visit with this id
        let yards = this.yards.filter(y => (y.visits || []).filter(v => v.id == visitId).length > 0);
        
        if (yards.length == 0) {
            // we should look through pending changes to see if this exists still
            
            const changes = this.getPendingChanges().filter(a=>a.type == ChangeActionType.NewMultiYardVisit && a.objectId == visitId);
            if (changes.length == 1) {
                const change = changes[0];
                const start = change.data.visit as IVisit;
                yards = this.getYardsByIds(start.yardIds);
                this.applyYardPendingChange(yards, start, false);
            }
        }

        

        const updated: IVisit[] = [];
        for (const yard of yards) {
            const matches = yard.visits!.filter(v => v.id == visitId);
            for (const match of matches) {
                console.log("MATCH", match)
                match.endDate = model.endDate;
                match.endTime = model.endTime;
                match.endLocation = model.endLocation;
                match.visitNotes = model.visitNotes;
                match.twuSuperNotes = model.twuSuperNotes;
                //HACK: This cropped up during testing, so ensure it is assigned
                if (match.yardIds == null) {
                    match.yardIds = [ yard.id ];
                }
                updated.push(match);
            }
            console.log("YARD", yard);
            
            lokiUpsert(this.yards, "id", yard);
            // debugging
            const test = this.yards.filter(a=>a.id == yard.id)[0];
            console.log("YARD EQUALS", yard == test,test);
        }
        const change = ChangeUtils.collectCompletedMultiYardVisit(updated[0]);
        this.pendingChanges.push(change);
        this.savePendingChanges();
        save();
        return { ...updated[0] };
    }

   
}

export function getDataManagerAsync(): Promise<DataManager> {
    return Promise.all<any>([
        getYardCollection(),            //res[0]
        getContactCollection(),         //res[1]
        getNonMemberCollection(),       //res[2]
        getMemberCollection(),          //res[3]
        getTaskCollection(),            //res[4]
        getContactTitleCollection(),    //res[5]
        // getChangesCollection(),         //res[6]
        getUserCollection()             //res[7]
    ]).then(res => { 
        return new DataManager(res[0], res[1], res[2], res[3], res[4], res[5], res[6]); 
    });
}

export interface ILocalCountSummary {
    yards: number;
    tasks: number;
    pending: number;
    incompleteVisits: number;
}

export async function getCountsAsync(): Promise<ILocalCountSummary> {
    const manager = await getDataManagerAsync();
    return manager.getCounts();
}