import * as React from 'react';
import { setOnline, setOffline } from '../actions/session';
import Alert from 'react-s-alert';
import * as Sentry from '@sentry/browser';
import { VisitTracker } from '../components/visit-tracker';
import { getDataManagerAsync } from '../api/data-manager';
import * as Logger from '../utils/logger';
import LoginPage from './login-page';
import { ReactNode, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import useRouter from 'use-react-router';
import { useDispatch, useMappedState } from 'redux-react-hook';
import { IReducerRoot } from '../reducers';
import { Icons } from '../components/icons';
import { strIsNullOrEmpty } from '../utils/string';

const AppTitle = (props) => {
	return (
		<div className="navbar-header">
			<a className="navbar-brand centered-navbar-item" href="#">
				{props.title}
			</a>
		</div>
	);
};

function base64urlDecode(str) {
	return Buffer.from(base64urlUnescape(str), 'base64').toString();
}

function base64urlUnescape(str) {
	str += new Array(5 - (str.length % 4)).join('=');
	return str.replace(/\-/g, '+').replace(/_/g, '/');
}

function isExpired(token: string | undefined) {
	// Copying out the part of jwt-simple that does the expiry check

	// check token
	if (!token) {
		return true;
	}
	// check segments
	const segments = token.split('.');
	if (segments.length !== 3) {
		return true;
	}

	// All segment should be base64
	const headerSeg = segments[0];
	const payloadSeg = segments[1];
	const signatureSeg = segments[2];

	// base64 decode and parse JSON
	const payload = JSON.parse(base64urlDecode(payloadSeg));

	if (payload.exp && Date.now() > payload.exp * 1000) {
		return true;
	}

	return false;
}

interface IAppProps {
	children?: ReactNode;
}

const navbarHeight = 50;

function App(props: IAppProps) {
	const { children } = props;
	const { history, location } = useRouter();

	const { session, title } = useMappedState((root: IReducerRoot) => ({
		session: root.session,
		title: root.title,
	}));
	const dispatch = useDispatch();

	const firstTimeOnlineCheck = useRef(true);

	useEffect(() => {
		function updateOnlineStatus() {
			if (navigator.onLine) {
				dispatch(setOnline());
				if (firstTimeOnlineCheck.current) {
					firstTimeOnlineCheck.current = false;
					return;
				}
				Alert.info("We're back online!", {
					position: 'bottom',
					effect: 'stackslide',
				});
			} else {
				dispatch(setOffline());
				Alert.info("Gone offline. Certain functionality won't be available", {
					position: 'bottom',
					effect: 'stackslide',
				});
			}
		}

		updateOnlineStatus();

		window.addEventListener('online', updateOnlineStatus);
		window.addEventListener('offline', updateOnlineStatus);

		return () => {
			window.removeEventListener('online', updateOnlineStatus);
			window.removeEventListener('offline', updateOnlineStatus);
		};
	}, []);

	useEffect(() => {
		if (__DEV__) {
			const win: any = window;
			win.setOnline = () => dispatch(setOnline());
			win.setOffline = () => dispatch(setOffline());
			Logger.logInfo('Attached setOnline and setOffline to browser window for easy invocation');
			getDataManagerAsync().then((manager) => {
				win.dataManager = manager;
				Logger.logInfo('Attached DataManager to browser window as dataManager');
			});
		}

		if (session.user) {
            console.log('Re-establishing user for Sentry tracking', session.user);
			Sentry.setUser({
				name: session.user.username,
				email: (session.user.details as any)?.email,
			});
		} else {
            console.log('Set null user for Sentry tracking');
			Sentry.setUser(null);
		}
	}, []);

	const token = session && session.token;
	const isLoggedIn = token && token !== null && typeof token !== 'undefined';

	const isTokenExpired = !strIsNullOrEmpty(token) && isExpired(token);

	if (!isLoggedIn || isTokenExpired) return <LoginPage isTokenExpired={isTokenExpired} />;

	const showButtons = isLoggedIn && location.pathname != '/';

	return (
		<div className="modal-container" style={{ height: '100%' }}>
			<Alert />
			<nav className="navbar navbar-default navbar-inverse" role="navigation">
				{showButtons && (
					<div className="navbar-header navbar-left">
						<ul className="nav navbar-nav">
							<li>
								<a className="navbar-item-content" onClick={history.goBack}>
									{Icons.BACK} Back
								</a>
							</li>
						</ul>
					</div>
				)}
				{title.app && <AppTitle title={title.app} />}
				{showButtons && (
					<div className="navbar-header navbar-right">
						<ul className="nav navbar-nav">
							<li>
								<Link className="navbar-item-content" to={`/home`}>
									{Icons.HOME} Home
								</Link>
							</li>
						</ul>
					</div>
				)}
			</nav>
			<div
				key={location.pathname}
				style={{ position: 'absolute', overflowY: 'auto', overflowX: 'hidden', left: 0, right: 0, top: navbarHeight, bottom: 0 }}
			>
				{children}
				{isLoggedIn && <VisitTracker />}
			</div>
		</div>
	);
}

export default App;
