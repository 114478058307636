import * as React from "react";
import * as moment from "moment";
import { Link } from "react-router-dom";
import { DropdownButton, MenuItem } from "react-bootstrap";
import * as EnumUtil from "../utils/enum";
import * as DisplayUtils from "../utils/display";
import { ITask, TaskStatus } from "../api/twu-contracts";
import { TwuIcons } from "./twu-icons";
import { ReactNode } from 'react';
import { Icons } from "./icons";

interface ITaskListProps {
    tasks: ITask[];
    statusFilter: TaskStatus;
    onFilterStatusChange: (status: TaskStatus|null) => void;
    showFilterUI: boolean;
}

/**
 * Displays a list of tasks
 */
export const TaskList = (props: ITaskListProps) => {
    const taskElements: ReactNode[] = [];
    if (props.tasks.length > 0) {
        for (const task of props.tasks) {
            let pending: ReactNode = null;
            if (DisplayUtils.isSyncPending(task)) {
                pending = (<span className="pull-right label label-info" title="This task has not been synced up to the server">{Icons.UPLOAD} Pending</span>);
            }
            let overdue: ReactNode = null;
            let due = moment.utc(task.dueOn);
            let now = moment.utc();
            if (now.isAfter(due)) {
                overdue = (<span className="label label-danger pull-right" title="This task has not been completed by it's due date">{Icons.CLOCK} Overdue</span>);
            } else {
                overdue = (<span className="label label-warning pull-right">{Icons.CLOCK} Due: {now.to(due)}</span>);
            }
            
            let isVisit: ReactNode = null;
            if (task.forVisit != null) {
                isVisit = (<span className="label label-info pull-right" title="This task is related to a yard visit">{Icons.CLOCK} Yard Visit</span>);
            }
            
            taskElements.push((
                <Link key={task.id} to={`/task/${task.id}`} className="list-group-item">
                    {overdue}
                    {pending}
                    {isVisit}
                    <h4 className="list-group-item-heading">{task.title}</h4>
                    <p className="list-group-item-text">{task.description || "(no description provided)"}</p>
                </Link>
            ));
        }
    } else {
        taskElements.push((
            <div key="no-tasks" className="alert alert-info">
                <p>{Icons.INFO} No tasks assigned</p>
            </div>
        ));
    }
    return (
        <div className="list-group">
            {(() => {
                if (props.showFilterUI === true) {
                    const taskStats = EnumUtil.getNamesAndValues(TaskStatus);
                    const statButtons: ReactNode[] = [];
                    statButtons.push((<MenuItem key={"all"} eventKey={"all"} onClick={() => props.onFilterStatusChange(null)} className={`${(props.statusFilter == null) ? "active" : ""}`}>All</MenuItem>));
                    for (const stat of taskStats) {
                        statButtons.push((<MenuItem key={stat.name} eventKey={stat.value} onClick={() => props.onFilterStatusChange(stat.value)} className={`${(props.statusFilter == stat.value) ? "active" : ""}`}>{stat.name}</MenuItem>));
                    }
                    return <div className="list-group-item">
                        <span className="pull-left">
                            <strong>{TwuIcons.FILTER} Filter: </strong>
                            <DropdownButton className="pull-left" title={TaskStatus[props.statusFilter] || "All"} id="task-list-status">
                                {statButtons}
                            </DropdownButton> 
                        </span>
                        <div className="clearfix"></div>
                    </div>;
                }
            })()}
            {taskElements}
        </div>
    );
};