import * as React from "react";
import { connect } from "react-redux";
import * as ChangeUtils from "../api/change-utils";
import { collectItemChangesAsync } from "../actions/editable";
import { setTitle } from '../actions/title';
import { setActiveYard } from '../actions/context';
import { fetchYardDetails, fetchTasksForYard } from '../actions/yards';
import { DelayLoadContainer } from '../components/delay-load-container';
import { TaskStatus } from "../api/twu-contracts";
import { EditableField } from "../components/editable-field";
import { IYard, IYardDetails } from "../api/twu-contracts";

import * as moment from "moment";

function mapStateToProps(state) {
    return { 
        yardDetails: state.yardDetails,
        session: state.session
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setTitle: (args) => dispatch(setTitle(args)),
        fetchYardDetails: (id) => dispatch(fetchYardDetails(id)),
        fetchTasksForYard: (args) => dispatch(fetchTasksForYard(args)),
        setActiveYard: (args) => dispatch(setActiveYard(args))
    };
}

interface IYardInteractionsPageProps {
    routeParams?: any;
    session?: any;
    yardDetails: any;
    setTitle: (args) => void;
    fetchYardDetails: (id) => void;
    setActiveYard: (args) => void;
}

@connect(mapStateToProps, mapDispatchToProps)
export default class YardInteractionsPage extends React.Component<IYardInteractionsPageProps, any> {
    private fnRenderModel: (model) => JSX.Element;
    private fnRenderInteractionItem: (interaction) => JSX.Element;

    constructor(props) {
        super(props);
        this.fnRenderModel = this.renderModel.bind(this);
        this.fnRenderInteractionItem = this.onRenderInteractionItem.bind(this);
    }
    private onFilterStatus(status: TaskStatus) {
        const { routeParams } = this.props;
        fetchTasksForYard({ id: routeParams.id, statusFilter: status });
    }
    private onRenderInteractionItem(interaction): JSX.Element {
        return <div>
            <h4 className="list-group-item-heading">{moment.utc(interaction.Date).format("MMM Do YY")}: {interaction.Topic}</h4>
            <p className="list-group-item-text">{interaction.Details}</p>
            {(() => {
                if (interaction.Link != null) {
                    return <span className="list-group-item-text">Link: <a href={interaction.Link} target="_blank">{interaction.Link}</a></span>;
                }
            })()}
        </div>;
    }
    private onSaveChanges(editables: EditableField[], callback: (success: boolean, saveError?: Error) => void) {
        const model = this.props.yardDetails.model;
        const details: IYardDetails = model.yard;

        collectItemChangesAsync<IYard>({
            editableObject: details.yard,
            editableFields: editables,
            type: ChangeUtils.EditableObjectType.Yard,
            idSelector: obj => obj.id
        }).then(res => {
            callback(true);
        }).catch(err => {
            callback(false, err);
        });
    }
    componentDidMount() {
        const { setTitle, routeParams, fetchYardDetails, setActiveYard } = this.props;
        setTitle({ app: "Yard Interactions" });
        fetchYardDetails(routeParams.id);
    }
    private renderModel(model: any): JSX.Element {
        const { session } = this.props;
        //const token = session.token;
        //const isOnline = session.online;
        //const userid = session.getIn(["user", "details", "userid"]);
        const interactions = model.yard.yard.interactions.map((v, k, iter) => {
            const inter = v;
            return {
                Id: inter.id,
                Topic: inter.topic,
                Details: inter.details,
                Link: inter.link,
                Date: inter.date
            };
        });

        return <>todo fix this</>/*<NonVirtualizedInteractionPanel 
                    width="100%"
                    itemClassName="list-group-item"
                    rootClassName="list-group"
                    toolbarPlacement="none"
                    interactionItemRenderer={this.fnRenderInteractionItem}
                    hasMoreInteractions={false}
                    interactions={interactions} />;*/
    }
    render(): JSX.Element {
        return <DelayLoadContainer 
                    name="Yard Details" 
                    branch={this.props.yardDetails}
                    onRenderModel={this.fnRenderModel} />;
    }
}