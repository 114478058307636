import * as React from "react";
import * as ReducerUtils from "../reducers/utils";
import { BsLoadSpinner, ErrorDisplay } from "./common";

export interface IDelayLoadContainerProps {
    name: string;
    branch: ReducerUtils.IReducerBranchStateImmutable<any>;
    onRenderModel: (model: any) => JSX.Element;
}

export const DelayLoadContainer = (props: IDelayLoadContainerProps) => {
    if (!!props.branch.isLoading) {
        return <BsLoadSpinner message={`Loading ${props.name}`} />;
    } else if (!!props.branch.hasError) {
        return <ErrorDisplay error={props.branch.errorDetails} />;
    } else {
        const model = props.branch.model;
        if (model != null) {
            return props.onRenderModel(model);
        } else {
            return <BsLoadSpinner message={`Loading ${props.name}`} />;
        }
    }
};