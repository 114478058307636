import * as Constants from "../constants";
import { IYardQueryOptions, SortDirection, TaskStatus } from "../api/twu-contracts";
import { getYards, getYardDetails } from "../api/yards";
import { getTasksForYardAsync } from "../api/tasks";
import { setActiveYard } from "./context";

export function fetchYards(options?: IYardQueryOptions) {
    const opts: IYardQueryOptions = options || {
        sortOn: [],
        sortDirection: SortDirection.Asc
    };
    return (dispatch, getState) => {
        return dispatch({
            types: [
                Constants.FETCH_YARDS_PENDING,
                Constants.FETCH_YARDS_SUCCESS,
                Constants.FETCH_YARDS_ERROR
            ],
            payload: {
                promise: getYards(opts).then(list => {
                    return {
                        list: list,
                        sort: opts.sortOn,
                        sortDir: opts.sortDirection 
                    };
                })
            }
        });
    };
}

export function fetchYardDetails(id) {
    return (dispatch, getState) => {
        return dispatch({
            types: [
                Constants.FETCH_YARD_DETAILS_PENDING,
                Constants.FETCH_YARD_DETAILS_SUCCESS,
                Constants.FETCH_YARD_DETAILS_ERROR
            ],
            payload: {
                promise: getYardDetails(id).then(res => {
                    // we are changing case here
                    dispatch(setActiveYard({ Id: res.yard.id, Name: res.yard.name }));
                    return {
                        yard: res
                    };
                })
            }
        });
    };
}

export function fetchTasksForYard(options: { id: number, statusFilter: TaskStatus }) {
    return (dispatch, getState) => {
        return dispatch({
            types: [
                Constants.FETCH_TASKS_FOR_YARD_PENDING,
                Constants.FETCH_TASKS_FOR_YARD_SUCCESS,
                Constants.FETCH_TASKS_FOR_YARD_ERROR
            ],
            payload: {
                promise: getTasksForYardAsync(options).then(res => {
                    return {
                        tasks: res,
                        taskStatus: options.statusFilter
                    };
                })
            }
        });
    };
}