import * as React from "react";

export const TwuIcons = {
    PLAY: <i className='icon-play' />,
    STOP: <i className='icon-stop' />,
    SAVE: <i className='icon-floppy' />,
    EMAIL: <i className='icon-mail' />,
    PENCIL: <i className='icon-pencil' />,
    FILTER: <i className='icon-filter' />,
    FLAG: <i className='icon-flag' />,
    CHEVRON_UP: <i className='icon-angle-up' />,
    CHEVRON_DOWN: <i className='icon-angle-down' />,
    BUILDING: <i className='icon-building' />,
    SORT: <i className='icon-sort' />,
    ADDRESS: <i className='icon-address' />
};