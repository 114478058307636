import * as React from "react";

import { IYard } from "../api/twu-contracts";
import { TwuIcons } from "./twu-icons";

import Select, { Option } from 'rc-select';
import "rc-select/assets/index.css";
import "./select.css"
import { useMemo } from 'react';

interface IYardPickListProps {
    /**
     * Raised when a yard is selected
     */
    onYardsSelect: (yard: number[]) => void;
    /**
     * The list of yards available to pick
     */
    yards: IYard[];
    /**
     * The id of the yard to select
     */
    selectedYardIds?: number[];
}

const YardPickListItem = (props) => {
    return <span>
        <strong>{props.item.yard.name} ({props.item.yard.yardCode || "Unknown Code"})</strong>
        {(() => {
            if (props.item.isPriorityVisit) {
                return <span className="label label-danger priority-visit-badge" title="This yard is marked high priority for a visit">
                    {TwuIcons.FLAG} Priority Visit
                </span>;
            }
        })()}
    </span>;
};

export function YardPickList(props: IYardPickListProps) {

    const onYardsChange = (values) => {
        if (values) {
            if (Array.isArray(values)) {
                props.onYardsSelect(values);
            } else {
                props.onYardsSelect([values.yard]);
            }
        } else {
            props.onYardsSelect([]);
        }
    }
    const filterOptions = (a: any, b: any) => {
        const atl = a.toLocaleLowerCase();
        return b.key.indexOf(atl) >= 0
    }

    const { yards, selectedYardIds } = props;
    // const items: IdNamePair[] = yards.map(y => {
    //     return { yard: y, Name: `${y.name} (${y.yardCode || "Unknown Code"})`, Id: y.id, isPriorityVisit: isPriorityVisit(y) };
    // }).sort((a, b) => a.Name.localeCompare(b.Name));

    const options = useMemo(() => yards.sort((a, b) => a.name.localeCompare(b.name)).map(y => (<Option key={`${y.name.toLocaleLowerCase()}_${y.id}`} value={y.id}>{y.name}</Option>)), [yards]);

    return <Select value={selectedYardIds} multiple={true} style={{ width: "100%" }} placeholder="Select Yards"
        optionLabelProp="children" onChange={onYardsChange} showArrow={true}
        filterOption={filterOptions}>
        {options}
    </Select>;

    // return <Multiselect data={items}
    //                     textField="Name"
    //                     dropUp={true}
    //                     valueField="Id"
    //                     value={selectedYardIds}
    //                     itemComponent={YardPickListItem}
    //                     onChange={this.fnChange} />;

}