import * as React from "react";
import { getIncompleteVisits } from "../api/visit";
import { setTitle } from '../actions/title';
import { connect } from 'react-redux';
import * as DisplayUtils from "../utils/display";
import { ModalHeader, ModalContent, ModalBody, ModalFooter, BottomDrawer } from '../components/modal-common';
import { VisitSummary, IVisitSummary } from '../components/visit-tracker';
import { DateTimePicker } from 'react-widgets';
import * as moment from "moment";
import { push } from '../api/auto-push';
import { getCurrentLocation } from '../components/geolocation-helper';
import { endVisit } from '../actions/visit';
import { ISessionState } from '../reducers/session';
import { IVisitState } from '../reducers/visit';
import { notifySuccess } from '../utils/notify';
import { IIncompleteVisit } from '../api/data-manager';
import { Icons } from "../components/icons";

function mapStateToProps(state) {
    return { 
        visitBranch: state.visit,
        session: state.session
    };
}

function mapDispatchToProps(dispatch) {
    return {
        endVisit: (options) => dispatch(endVisit(options)),
        setTitle: (args) => dispatch(setTitle(args))
    };
}

export interface IIncompleteVisitsPageProps {

}

export interface IIncompleteVisitConnectedState {
    visitBranch: IVisitState;
    session: ISessionState;
}

export interface IIncompleteVisitDispatch {
    setTitle: (args) => void;
    endVisit: (options) => void;
}

export type IncompleteVisitsPageProps = Partial<IIncompleteVisitsPageProps> & Partial<IIncompleteVisitConnectedState> & Partial<IIncompleteVisitDispatch>;

export interface IIncompleteVisitPageState {
    visitToMarkOff: IIncompleteVisit | null,
    visits: IIncompleteVisit[],
    visitSummary: IVisitSummary,
    completing: boolean
}

const DEFAULT_VISIT_SUMMARY = { notes: '', superNotes: ''}
@connect(mapStateToProps, mapDispatchToProps)
export default class IncompleteVisitsPage extends React.Component<IncompleteVisitsPageProps, IIncompleteVisitPageState> {
    constructor(props: IncompleteVisitsPageProps) {
        super(props);
        this.state = {
            visits: [],
            visitSummary: DEFAULT_VISIT_SUMMARY,
            visitToMarkOff: null,
            completing: false
        };
    }
    private onVisitEndDateChanged = (e) => {
        const model = this.state.visitToMarkOff;
        if (!model) { alert('Visit not selected?');return;}

        model.endDate = moment.utc(e).format(DisplayUtils.DATE_FORMAT);
        this.setState({ visitToMarkOff: model });
    }
    private onSummaryChanged = (vs: IVisitSummary) => {
        this.setState({
            visitSummary: vs
        });
    }
    private onCompleteVisit = (e) => {
        this.setState({ completing: true });
        const { endVisit, visitBranch, session } = this.props;
        const { visitSummary, visitToMarkOff } = this.state;
        const handler = (pos?: GeolocationPosition) => {
            const iv = visitBranch && visitBranch.interval;
            if (!visitToMarkOff) {
                alert('Visit not selected');
                return; 
            }
            if (pos != null) {
                endVisit && endVisit({ visitId: visitToMarkOff.id, endDate: visitToMarkOff.endDate, interval: iv, visitNotes: visitSummary.notes, twuSuperNotes: visitSummary.superNotes, location: { longitude: pos.coords.longitude, latitude: pos.coords.latitude } });
            } else {
                endVisit && endVisit({ visitId: visitToMarkOff.id, endDate: visitToMarkOff.endDate, interval: iv, visitNotes: visitSummary.notes, twuSuperNotes: visitSummary.superNotes });
            }
            notifySuccess("Complete Visit", "Visit completed", DisplayUtils.DEFAULT_UI_NOTIFICATION_SETTINGS);
            this.setState({ completing: false, visitToMarkOff: null, visitSummary: DEFAULT_VISIT_SUMMARY }, () => {
                if (!session || !session.token) {
                    alert('Session token missing');
                    return;
                }
                const t = session.token;
                push({ token: t });
                this.loadIncompleteVisits();
            });
        };
        getCurrentLocation(handler, (err) => handler());
    }
    private onCancelCompletion = (e) => {
        this.setState({ visitToMarkOff: null });
    }
    private onMarkOffVisit = (e) => {
        const id = e.currentTarget.getAttribute("data-visit-id");
        const visit = this.state.visits.filter(v => v.id == id)[0];
        if (visit) {
            this.setState({ 
                visitToMarkOff: {
                    ...visit
                },
                visitSummary: DEFAULT_VISIT_SUMMARY
            });
        }
    }
    private loadIncompleteVisits() {
        getIncompleteVisits().then(visits => {
            this.setState({ 
                visits: visits.map(v => ({
                    ...v,
                    //Pre-init to now for easy marking off
                    endDate: DisplayUtils.utcNowString()
                }))
            });
        });
    }
    componentDidMount() {
        this.props.setTitle && this.props.setTitle({ app: "Incomplete Visits" });
        this.loadIncompleteVisits();
    }
    render(): JSX.Element {
        const { visits, visitToMarkOff, completing } = this.state;
        if (visits == null) {
            return <div className="container-fluid">
                <br/>
                <div className="alert alert-info">
                    Checking incomplete visits
                </div>
            </div>;
        } else {
            if (visits.length === 0) {
                return <div className="container-fluid">
                    <br/>
                    <div className="alert alert-success">
                    {Icons.TICK} You have no incomplete visits. Everything's good.
                    </div>
                </div>;
            } else {
                return <div className="container-fluid">
                    <br/>
                    <div className="alert alert-info">
                        <p>You have <strong>{visits.length}</strong> incomplete visit(s)</p>
                        <p>Select a visit to mark it off as completed</p>
                    </div>
                    <div className="list-group">
                        {visits.map(v => {
                            return <div key={v.id} className="list-group-item" data-visit-id={v.id} onClick={this.onMarkOffVisit}>
                                {v.yards.map(y => <h4 key={`title-${y.yardId}`} className="list-group-item-heading">{y.yardDesc}</h4>)}
                                <p className="list-group-item-text">Started: {DisplayUtils.getLocalDisplay(v.startDate + " " + v.startTime)}</p>
                            </div>;
                        })}
                    </div>
                    {(() => {
                        if (visitToMarkOff) {
                            let dt;
                            if (visitToMarkOff.endDate) {
                                dt = DisplayUtils.getMomentUtc(visitToMarkOff.endDate).toDate();
                            }
                            const dateFmt = "dddd, MMMM Do YYYY";
                            const timeFmt = "h:mm:ss a";
                            const dtProps: any = {
                                format: `${dateFmt} ${timeFmt}`,
                                value: dt,
                                onChange: this.onVisitEndDateChanged,
                                time: true
                            };
                            return <BottomDrawer open={true}>
                                <ModalContent>
                                    <ModalHeader>Complete Visit</ModalHeader>
                                    <ModalBody style={{ maxHeight: 430, overflowY: "auto" }}>
                                        <p>You are about to complete a visit for</p>
                                        <ul>
                                            {visitToMarkOff.yards.map(y => <li key={`s-${y.yardId}`}><strong>{y.yardDesc}</strong></li>)}
                                        </ul>
                                        <p>which started on <strong>{DisplayUtils.getLocalDisplay(visitToMarkOff.startDate + " " + visitToMarkOff.startTime)}</strong></p>
                                        <div className="form-group">
                                            <label>Finished On:</label>
                                            <DateTimePicker {...dtProps} />
                                        </div>
                                        <VisitSummary onSummaryChanged={this.onSummaryChanged} />
                                        <div className="clearfix" />
                                    </ModalBody>
                                    <ModalFooter>
                                        <button type="button" className="btn btn-success" onClick={this.onCompleteVisit} disabled={completing}>{Icons.TICK} {completing ? "Completing..." : "Complete"}</button>
                                        <button type="button" className="btn btn-danger" onClick={this.onCancelCompletion} disabled={completing}>{Icons.CLOSE} Cancel</button>
                                    </ModalFooter>
                                </ModalContent>
                            </BottomDrawer>;
                        }
                    })()}
                </div>;
            }
        }
    }
}