import * as React from "react";
import { connect } from "react-redux";
import { logoutUser } from '../actions/session';
import { setTitle } from '../actions/title';
import { removeEncryptionKey } from "../store/persistence";
import { getDataManagerAsync, DataManager } from '../api/data-manager';
import * as DisplayUtils from "../utils/display";
import { notifySuccess } from '../utils/notify';
import { BsLoadSpinner } from "../components/common";

function mapStateToProps(state) {
    return { 
        session: state.session
    };
}

function mapDispatchToProps(dispatch) {
    return {
        logout: () => dispatch(logoutUser()),
        setTitle: (args) => dispatch(setTitle(args))
    };
}

interface IClearDataPageProps {
    session: any;
    logout: () => void;
    setTitle: (args) => void;
}

@connect(mapStateToProps, mapDispatchToProps)
export default class ClearData extends React.Component<IClearDataPageProps, any> {
    private fnClearAllPendingChanges: (e) => void;
    private fnClearAllButPendingChanges: (e) => void;
    private fnClearEncryptionKey: (e) => void;
    private manager?: DataManager;
    constructor(props) {
        super(props);
        this.fnClearAllPendingChanges = this.onClearAllPendingChanges.bind(this);
        this.fnClearAllButPendingChanges = this.onClearAllButPendingChanges.bind(this);
        this.fnClearEncryptionKey = this.onClearEncryptionKey.bind(this);
        this.state = {
            hasManager: false,
            clearing: false
        };
    }
    private onClearAllPendingChanges(e) {
        this.setState({ clearing: true });
        this.manager && this.manager.clearPendingChanges().then(res => {
            this.setState({ clearing: false });
            notifySuccess("Clear Pending Changes", "Pending changes cleared", DisplayUtils.DEFAULT_UI_NOTIFICATION_SETTINGS);
        });
    }
    private onClearAllButPendingChanges(e) {
        this.setState({ clearing: true });
        this.manager && this.manager.clearAllButPendingChanges().then(res => {
            this.setState({ clearing: false });
            notifySuccess("Clear Data", "Data cleared. Any existing pending changes are still intact", DisplayUtils.DEFAULT_UI_NOTIFICATION_SETTINGS);
        });
    }
    private onClearEncryptionKey(e) {
        const { session } = this.props;
        if (!session || !session.user) {
            alert('User not available');
            return
        }
        const username = session.user.username;
        removeEncryptionKey(username);
        alert("Cleared encryption key");
        this.props.logout();
    }
    componentDidMount() {
        this.props.setTitle({ app: "Clear Data" });
        getDataManagerAsync().then(mgr => {
            this.manager = mgr;
            this.setState({ hasManager: true });
        });
    }
    render(): JSX.Element {
        return <div>
            {(() => {
                if (this.state.hasManager === true) {
                    if (this.state.clearing === true) {
                        return <BsLoadSpinner message="Clearing ..." />;
                    } else {
                        return <div className="container-fluid">
                            <br/>
                            <p>You are about to remove the following items:</p>
                            <ul>
                                <li><strong>{this.manager && this.manager.getYards().length}</strong> Yards</li>
                                <li><strong>{this.manager && this.manager.getContacts().length}</strong> Contact</li>
                                <li><strong>{this.manager && this.manager.getMembers().length}</strong> Members</li>
                                <li><strong>{this.manager && this.manager.getTasks().length}</strong> Tasks</li>
                            </ul>
                            <p>You also have locally: <strong>{this.manager && this.manager.getPendingChanges().length}</strong> Pending Changes</p>
                            <div className="alert alert-info">
                                <p>Hit the <strong>Clear Data </strong> button below to remove this data (except for pending changes). You can re-download this information in the <strong>Synchronise Data</strong> screen</p>
                                <p>Hit the <strong>Clear Changes</strong> button below to remove all pending changes. Once removed, these changes cannot be recovered</p>
                            </div>
                            <div className="btn-group btn-group-justified">
                                <div className="btn-group">
                                    <button type="button" className="btn btn-primary" onClick={this.fnClearAllButPendingChanges}>Clear Data</button>
                                </div>
                                <div className="btn-group">
                                    <button type="button" className="btn btn-primary" onClick={this.fnClearAllPendingChanges}>Clear Changes</button>
                                </div>
                            </div>
                            <hr />
                            <div className="alert alert-info">
                                <p>If required, you can clear your local encryption key by hitting the <strong>Clear encryption key</strong> button below</p>
                                <p>You will be logged out and require an online connection to log back in</p>
                            </div>
                            <div className="btn-group btn-group-justified">
                                <div className="btn-group">
                                    <button type="button" className="btn btn-primary" onClick={this.fnClearEncryptionKey}>Clear encryption key</button>
                                </div>
                            </div>
                        </div>;
                    }
                } else {
                    return <BsLoadSpinner message="Waiting for data manager ..." />;
                }
            })()}
        </div>;
    }
}