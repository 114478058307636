import * as React from 'react';
import * as ReduxForm from 'redux-form';
import { Form, Alert, Button, ButtonGroup } from 'react-bootstrap';
import ReduxInputField from '../redux-input-field';
import { ReactNode } from 'react';
import { Field, InjectedFormProps } from 'redux-form';
import { ILoginPageState } from '../../containers/login-page';
import { connect } from 'react-redux';
import { Icons } from '../icons';
import { LoginMode } from '../../api/twu-contracts';
import { BsLoadSpinner } from '../common';

interface ILoginFormProps {
    isLoading: boolean;
    hasError: boolean;
    progressMessage?: string;
    errorDetails?: any | Error;
};

// Making this a class-based component until redux-form typings support 
// stateless functional components.

function mapStateToProps(state): ILoginPageState {
    return {
        session: state.session
    };
}

@connect(mapStateToProps)
class LoginForm extends React.Component<InjectedFormProps & { session: ILoginFormProps}, any> {
    constructor(props) {
        super(props);
    }
    render() {
        const {
            handleSubmit,
            session: {
                progressMessage,
                hasError,
                errorDetails,
                isLoading
            },
            reset,
        } = this.props;

        let loadingAlert: ReactNode = null;
        if (isLoading) {
            if (progressMessage != null) {
                loadingAlert = <BsLoadSpinner message={progressMessage} />;
            } else {
                loadingAlert = <BsLoadSpinner />;
            }
        }
        let errorAlert: ReactNode = null;
        if (hasError) {
            let msg = "Invalid username and/or password";
            if (errorDetails instanceof Error) {
                msg = errorDetails.message;
            }
            errorAlert = <Alert bsStyle="danger"><strong>{Icons.EXCLAMATION} {msg}</strong></Alert>;
        }
        const title = <h3>{Icons.USER} Log In</h3>;
        
        // The mode field is special because we can't initialise it to a value. This can either be null (should be treated as Regular user)
        // or the selected value.
        
        return <div className="container">
            <br/>
            <Form onSubmit={handleSubmit}>
                <fieldset>
                    {loadingAlert}
                    {errorAlert}
                    <Field name="username" 
                        id="username" 
                        type="text"
                        component={ReduxInputField}
                        addonBefore={{ element: Icons.USER, generateAddonWrapper: true }}
                            extraInputProps={{
                                autoCapitalize: "none",
                                autoCorrect: "off",
                                autoComplete: "off"
                            }} />

                    <Field name="password" id="password" type="password"
                        component={ReduxInputField}
                        addonBefore={{ element: Icons.PASSWORD, generateAddonWrapper: true }}
                            extraInputProps={{
                                autoCapitalize: "none",
                                autoCorrect: "off",
                                autoComplete: "off"
                            }} />      
                   
                    <ButtonGroup justified>
                        <ButtonGroup>
                            <Button bsStyle="primary" type="submit" disabled={isLoading}>{Icons.USER} Login</Button>
                        </ButtonGroup>
                        <ButtonGroup>
                            <Button bsStyle="danger" onClick={ reset } disabled={isLoading}>{Icons.CLOSE} Clear</Button>
                        </ButtonGroup>
                    </ButtonGroup>
                    <br />
                    <p style={{ textAlign: 'center' }}>{Icons.INFO} Build date: {__BUILD_DATE__}</p>
                </fieldset>
            </Form>
        </div>;
    }
    static validate(values) {
        const errors = { username: '', password: '' };
        if (values.mode == LoginMode[LoginMode.Regular]) {
            if (!values.username) {
                errors.username = 'Username is required.';
            }

            if (!values.password) {
                errors.password = 'Password is required.';
            }
        }
        return errors;
    }
}

export default ReduxForm.reduxForm({
    form: 'login',
    validate: LoginForm.validate,
})(LoginForm);
