import * as React from 'react';
import FormGroup = require("react-bootstrap/lib/FormGroup");
import ControlLabel = require("react-bootstrap/lib/ControlLabel");
import FormControl = require("react-bootstrap/lib/FormControl");
import HelpBlock = require("react-bootstrap/lib/HelpBlock");
import InputGroup = require("react-bootstrap/lib/InputGroup");
import InputGroupAddon = require("react-bootstrap/lib/InputGroupAddon");
import Col = require("react-bootstrap/lib/Col");

export function isPositionError(arg: any): arg is GeolocationPositionError {
    return arg
        && typeof (arg.PERMISSION_DENIED) != 'undefined'
        && typeof (arg.POSITION_UNAVAILABLE) != 'undefined'
        && typeof (arg.TIMEOUT) != 'undefined';
}

export enum ContentSectionDisplayStyle {
    Fieldset,
    Panel
    //CollapsiblePanel
}

export enum ContentSectionPanelStyle {
    Kendo,
    BootstrapDefault,
    BootstrapPrimary,
    BootstrapSuccess,
    BootstrapWarning,
    BootstrapDanger
}

export interface IContentSectionProps extends React.Props<any> {
    /**
     * The title of the section
     */
    title: string;
    /**
     * The type of section to render
     */
    displayStyle: ContentSectionDisplayStyle;
    /**
     * If rendering a panel, the style of panel to render
     */
    panelStyle?: ContentSectionPanelStyle;
    /**
     * If specified, the name of the icon to render alongside the title
     */
    iconClass?: string;
    /**
     * The kind of icon.
     *
     *   fa - font awesome
     *   icon - our custom fontello icon set
     */
    iconKind?: "fa" | "icon" | string;
}

/**
 * The Section component provides a display container abstraction over a block of content 
 */
export class ContentSection extends React.Component<IContentSectionProps, any> {
    constructor(props: IContentSectionProps) {
        super(props);
        this.state = {};
    }
    render(): JSX.Element {
        var title;
        if (this.props.iconClass != null) {
            title = (<span><i className={`fa ${this.props.iconKind || "fa"}-${this.props.iconClass}`}></i> {this.props.title}</span>);
        } else {
            title = this.props.title;
        }

        if (this.props.displayStyle == ContentSectionDisplayStyle.Fieldset) {
            return (
                <fieldset>
                    <legend>{title}</legend>
                    {this.props.children}
                </fieldset>
            );
        } else {
            if (this.props.panelStyle == ContentSectionPanelStyle.Kendo) {
                return <div className="k-block">
                    <div className="k-header">{title}</div>
                    {this.props.children}
                </div>;
            } else {
                let panelStyle = "default";
                switch (this.props.panelStyle) {
                    case ContentSectionPanelStyle.BootstrapPrimary:
                        panelStyle = "primary";
                        break;
                    case ContentSectionPanelStyle.BootstrapSuccess:
                        panelStyle = "success";
                        break;
                    case ContentSectionPanelStyle.BootstrapWarning:
                        panelStyle = "warning";
                        break;
                    case ContentSectionPanelStyle.BootstrapDanger:
                        panelStyle = "danger";
                        break;
                }
                return <div className={`panel panel-${panelStyle}`}>
                    <div className="panel-heading">
                        <h3 className="panel-title">{title}</h3>
                    </div>
                    <div className="panel-body">
                        {this.props.children}
                    </div>
                </div>;
            }
        }
    }
}

/**
 * Valid HTML input field types
 */
export type InputFieldType = "text" | "number" | "email" | "password" | "textarea";

/**
 * Valid feedback styles
 */
export type ValidationFeedbackType = "warning" | "error" | "success";

export type BsInputSize =  "xsmall" | "small" | "large" | "lg" | "sm" | "xs";

export interface IAddonSpec {
    generateAddonWrapper: boolean;
    element: JSX.Element;
}

function isAddonSpecArray(obj: IAddonSpec|IAddonSpec[]): obj is IAddonSpec[] {
    return obj instanceof Array;
}

export interface IInputFieldProps {
    /**
     * The field control id
     */
    id: string;
    /**
     * The input field type
     */
    type?: InputFieldType;
    /**
     * The field value
     */
    value?: string;
    /**
     * The label for this field
     */
    label?: string;
    /**
     * Content to prepend the field with
     */
    addonBefore?: IAddonSpec|IAddonSpec[];
    /**
     * Content to append the field with
     */
    addonAfter?: IAddonSpec|IAddonSpec[];
    /**
     * Feedback content to append to the field with
     */
    feedback?: any;
    /**
     * Help text for this field
     */
    helpText?: string;
    /**
     * The field placeholder text
     */
    placeholder?: string;
    /**
     * Control size
     */
    size?: BsInputSize;
    /**
     * Raised when the field's value changes
     */
    onChange?: (value: string) => void;
    /**
     * Raised when the field loses focus
     */
    onBlur?: (value: string) => void;
    /**
     * Any extra input attributes to transfer to the underlying HTML input field
     */
    extraInputProps?: any;
    /**
     * The feedback style to apply for the input field. Leave empty for no styling
     */
    validationState?: ValidationFeedbackType;
    /**
     * Indicates if this field is read-only. If true, any change handlers will have no effect and this field
     * will be rendered out as a static content control
     */
    isReadOnly?: boolean;
    /**
     * If true, will put the label on the same "row" as the field. Must wrap this component with a <Form> component with horizontal=true. Default is false
     */
    columnLayout?: boolean;
    /**
     * The grid column size of the label "column". Must be between 1 - 11. Has no effect if columnLayout is false or not specified
     */
    labelColumnSize?: number;
}

const BS_MAX_GRID_LENGTH = 12;

export function renderInputFieldCommon(props: IInputFieldProps, extraInputProps?: any) {
    const onChange = props.onChange ? (e: any) => props.onChange!(e.target.value) : null;
    const onBlur = props.onBlur ? (e: any) => props.onBlur!(e.target.value) : null;
    const extraProps: any = extraInputProps ? extraInputProps : {};
    //const label = props.label ? <ControlLabel>{props.label}</ControlLabel> : null;
    const help = props.helpText ? <HelpBlock>{props.helpText}</HelpBlock> : null;
    const field = props.isReadOnly === true ? (<FormControl.Static>{props.value}</FormControl.Static>) : (
        <FormControl type={props.type}
                     value={props.value}
                     placeholder={props.placeholder}
                     onChange={onChange}
                     onBlur={onBlur}
                     {...extraProps} />); 
    let fieldContainer = field;
    if (props.addonBefore || props.addonAfter) {
        let addonsBefore: any = null;
        let addonsAfter: any = null;
        if (props.addonBefore) {
            const before = props.addonBefore;
            if (isAddonSpecArray(before)) {
                addonsBefore = before.map((v, i) => {
                    if (v.generateAddonWrapper) {
                        return <InputGroupAddon key={i}>{v.element}</InputGroupAddon>;
                    } else {
                        return v.element;
                    }
                });
            } else {
                if (before.generateAddonWrapper) {
                    addonsBefore = <InputGroupAddon>{before.element}</InputGroupAddon>;
                } else {
                    addonsBefore = before.element;
                }
            }
        }
        if (props.addonAfter) {
            const after = props.addonAfter;
            if (isAddonSpecArray(after)) {
                addonsAfter = after.map((v, i) => {
                    if (v.generateAddonWrapper) {
                        return <InputGroupAddon key={i}>{v.element}</InputGroupAddon>;
                    } else {
                        return v.element;
                    }
                });
            } else {
                if (after.generateAddonWrapper) {
                    addonsAfter = <InputGroupAddon>{after.element}</InputGroupAddon>;
                } else {
                    addonsAfter = after.element;
                }
            }
        }
        fieldContainer = <InputGroup>
            {addonsBefore}
            {field}
            {addonsAfter}
        </InputGroup>;
    } 
    return <FormGroup controlId={props.id} validationState={props.validationState} bsSize={props.size}>
        {(() => {
            if (props.label) {
                if (props.columnLayout == true && props.labelColumnSize! > 0 && props.labelColumnSize! < BS_MAX_GRID_LENGTH) {
                    return <Col componentClass={ControlLabel} sm={props.labelColumnSize}>{props.label}</Col>;
                } else {
                    return <ControlLabel>{props.label}</ControlLabel>;
                }
            } else {
                return null;
            }
        })()}
        {(() => {
            if (props.columnLayout == true && props.labelColumnSize! > 0 && props.labelColumnSize! < BS_MAX_GRID_LENGTH) {
                return <Col sm={BS_MAX_GRID_LENGTH - props.labelColumnSize!}>{fieldContainer}</Col>;
            } else {
                return fieldContainer;
            }
        })()}
        <FormControl.Feedback />
        {help}
    </FormGroup>;
}

export interface ISpinnerIconProps {
    iconClass?: string;
    iconAnimation?: string;
}

export const SpinnerIcon = (props: ISpinnerIconProps) => {
    const iconClass = props.iconClass || "arrows-cw";
    const iconAnimation = props.iconAnimation || "spin";
    return <i className={`animate-${iconAnimation} icon-${iconClass} `} />;
}

export interface IBsLoadSpinnerProps extends ISpinnerIconProps {
    message?: string;
    bold?: boolean;
    spinnerStyle?: string;
    style?: React.CSSProperties;
}

export const BsLoadSpinner = (props: IBsLoadSpinnerProps) => {
    const bold = !!props.bold;
    const message = props.message || "Loading ...";
    const iconClass = props.iconClass || "arrows-cw";
    const iconAnimation = props.iconAnimation || "spin";
    const spinnerStyle = props.spinnerStyle || "info";
    const style = props.style;
    if (bold) {
        return <div className={`alert alert-${spinnerStyle}`} style={style}><strong><SpinnerIcon iconClass={iconClass} iconAnimation={iconAnimation} /> {message}</strong></div>;
    } else {
        return <div className={`alert alert-${spinnerStyle}`} style={style}><SpinnerIcon iconClass={iconClass} iconAnimation={iconAnimation} /> {message}</div>;
    }
};

export enum AlertStyleKind {
    Kendo,
    Bootstrap
}

export enum AlertPanelTheme {
    Info,
    Success,
    Error,
    Plain
}

export interface IAlertPanelProps {
    kind?: AlertStyleKind;
    theme: AlertPanelTheme;
    children?: any;
}

export const AlertPanel = (props: IAlertPanelProps) => {
    const kind = props.kind != null ? props.kind : AlertStyleKind.Kendo;
    const theme = props.theme;
    let style: any = null;
    const classNames: string[] = [];
    if (kind === AlertStyleKind.Kendo) {
        style = {
            padding: "15px",
            marginBottom: "20px",
            borderRadius: "5px"
        };
        classNames.push("k-block");
        switch (theme) {
            case AlertPanelTheme.Info:
                classNames.push("k-info-colored");
                break;
            case AlertPanelTheme.Error:
                classNames.push("k-error-colored");
                break;
            case AlertPanelTheme.Success:
                classNames.push("k-success-colored");
                break;
        }
    } else {
        if (theme == AlertPanelTheme.Plain) {
            classNames.push("well");
        } else {
            classNames.push("alert");
        }
        switch (theme) {
            case AlertPanelTheme.Info:
                classNames.push("alert-info");
                break;
            case AlertPanelTheme.Error:
                classNames.push("alert-danger");
                break;
            case AlertPanelTheme.Success:
                classNames.push("alert-success");
                break;
        }
    }
    return <div className={classNames.join(" ")} style={style}>
        {props.children}
    </div>;
}

export interface IErrorDisplayProps {
    displayStyle?: AlertStyleKind;
    error: any;
}

export interface IErrorDisplayState {
    id_head?: string;
    id_body?: string;
    error?: string;
    displayStyle?: AlertStyleKind;
    expanded?: boolean;
}

export class ErrorDisplay extends React.Component<IErrorDisplayProps, IErrorDisplayState> {
    constructor(props: any) {
        super(props);
        var now = (new Date()).getTime();
        this.state = {
            id_head: "error_head_" + now,
            id_body: "error_body_" + now,
            error: this.stringifyError(props.error),
            expanded: false,
            displayStyle: (this.props.displayStyle != null) ? this.props.displayStyle : AlertStyleKind.Kendo
        }
    }
    private onExpandDetails = (e: any) => {
        e.preventDefault();
        this.setState({ expanded: !this.state.expanded });
        return false;
    }
    private stringifyError(e: any): string {
        if (e instanceof Error) {
            return JSON.stringify({ message: e.message, name: e.name, stack: e.stack }, null, 4);
        } else if (isPositionError(e)) {
            return JSON.stringify({ message: e.message, name: "PositionError" }, null, 4);
        } else if (typeof (e) == "object") {
            return JSON.stringify(e, null, 4);
        }
        return e + "";
    }
    render(): JSX.Element {
        var body;
        if (this.state.displayStyle == AlertStyleKind.Bootstrap) {
            body = (
                <div className="alert alert-danger">
                    <h4><i className="fa fa-warning"></i> An error occurred in this component</h4>
                    <div className="panel panel-danger">
                        <div className="panel-heading" role="tab" id={this.state.id_head}>
                            <h4 className="panel-title">
                                <a onClick={this.onExpandDetails} className="collapsed" role="button" data-toggle="collapse" href={`#${this.state.id_body}`} aria-expanded="false" aria-controls={this.state.id_body}>
                                    Error Details (click to expand)
                                </a>
                            </h4>
                        </div>
                        {this.state.expanded === true && <div id={this.state.id_body} className="panel-body" aria-labelledby={this.state.id_head}>
                                    <pre className="pre-scrollable">
                                        {this.state.error}
                                    </pre>
                                </div>
                            }
                    </div>
                </div>
            );
        } else { //Kendo
            body = <AlertPanel theme={AlertPanelTheme.Error} kind={this.state.displayStyle}>
                <h3>An error occurred in this component</h3>
                <pre>{this.state.error}</pre>
            </AlertPanel>;
        }
        return body;
    }
}