import * as React from "react";
import { TitleBadge } from "./title-badge";
import { IPersonCardProps, IContactCardProps } from "../api/twu-contracts";
import { TwuIcons } from "./twu-icons";
import { Icons } from "./icons";

export const MemberCard = (props) => {
    const badgeProps = {
        className: 'pull-right label'
    };
    let bNeedsDetails = false;
    if (props.phone == null || props.mobile == null || props.email == null) {
        bNeedsDetails = true;
    }

    let rts;
    if (props.rts === true) {
        rts = <span className={`${badgeProps.className} label-danger`}>RTS</span>;
    }
    let delegateLevel;
    if (props.delegateLevel != null && props.delegateLevel != 0) {
        delegateLevel = <span className={`${badgeProps.className} label-primary`}>Delegate Level: {props.delegateLevel}</span>;
    }
    
    let phone;
    //if (props.phone != null)
    //    phone = (<span className="pull-right label label-default">{Icons.PHONE} {props.phone}</span>);
    let mobile;
    //if (props.mobile != null)
    //    mobile = (<span className="pull-right label label-default">{Icons.MOBILE} {props.mobile}</span>);
    let email;
    //if (props.email != null)
    //    email = (<span className="pull-right label label-default">{TwuIcons.EMAIL} {props.email}</span>);
    
    return (
        <div>
            <span className={`${badgeProps.className} label-success`}>{props.isFinancial ? "FINANCIAL" : "UNFINANCIAL"}</span>
            {rts}
            {delegateLevel}
            <span>{Icons.USER} {props.firstName} {props.lastName}</span>
            {phone}
            {mobile}
            {email}
            <span className='clearfix'></span>
        </div>
    );
};

export const PersonCard = (props: IPersonCardProps) => {
    let name;
    let phone;
    let mobile;
    let email;
    
    if (props.style == null || props.style == "Basic") {
        name = (<div>{Icons.USER}&nbsp;{props.firstName} {props.lastName}</div>);
    } else if (props.style == "ListItem") {
        name = (<h4 className='list-group-item-heading'>{Icons.USER}&nbsp;{props.firstName} {props.lastName}</h4>);
    }
    
    const childProps: any = {};
    if (props.style == "ListItem") {
        childProps.className = 'list-group-item-text card-details-field';
    } else {
        childProps.className = 'card-details-field';
    }
    
    return (
        <div className={props.className}>
            {name}
            {props.phone && <div {...childProps}>{Icons.PHONE}&nbsp;{props.phone}</div>}
            {props.mobile && <div {...childProps}>{Icons.MOBILE}&nbsp;{props.mobile}</div>}
            {props.email && <div {...childProps}>{TwuIcons.EMAIL}&nbsp;{props.email}</div>}
        </div>
    );
};

export const ContactCard = (props: IContactCardProps) => {
    let phone;
    //if (props.phone != null)
    //    phone = (<span className="pull-right label label-default">{Icons.PHONE} {props.phone}</span>);
    let mobile;
    //if (props.mobile != null)
    //    mobile = (<span className="pull-right label label-default">{Icons.MOBILE} {props.mobile}</span>);
    let email;
    //if (props.email != null)
    //    email = (<span className="pull-right label label-default">{TwuIcons.EMAIL} {props.email}</span>);
    
    return (
        <div>
            <span>{Icons.USER} {props.firstName} {props.lastName}</span>
            <TitleBadge title={props.title} className="pull-right" />
            {phone}
            {mobile}
            {email}
            <span className='clearfix'></span>
        </div>
    );
};