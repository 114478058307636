import * as Constants from "../constants";
import { getMemberDetails } from "../api/member";
import { getTasksForMemberAsync } from "../api/tasks";
import { TaskStatus } from "../api/twu-contracts";

export function fetchMemberDetails(id) {
    return (dispatch, getState) => {
        return dispatch({
            types: [
                Constants.FETCH_MEMBER_DETAILS_PENDING,
                Constants.FETCH_MEMBER_DETAILS_SUCCESS,
                Constants.FETCH_MEMBER_DETAILS_ERROR
            ],
            payload: {
                promise: getMemberDetails(id).then(res => {
                    return {
                        member: res
                    };
                })
            }
        });
    };
}

export function fetchTasksForMember(options: { id: number, statusFilter: TaskStatus }) {
    return (dispatch, getState) => {
        return dispatch({
            types: [
                Constants.FETCH_TASKS_FOR_MEMBER_PENDING,
                Constants.FETCH_TASKS_FOR_MEMBER_SUCCESS,
                Constants.FETCH_TASKS_FOR_MEMBER_ERROR
            ],
            payload: {
                promise: getTasksForMemberAsync(options).then(res => {
                    return {
                        tasks: res,
                        taskStatus: options.statusFilter
                    };
                })
            }
        });
    };
}