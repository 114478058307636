import * as React from "react";
import { TwuIcons } from "./twu-icons";
import { ITaskNote } from "../api/twu-contracts";
import { createNewNote } from "../api/tasks";
import { Icons } from "./icons";

interface IAddTaskNoteProps {
    onAddNote: (note: ITaskNote) => void;
}

interface IAddTaskNoteState {
    isAdding: boolean;
    error?: any;
    noteText?: string;
}

export class AddTaskNote extends React.Component<IAddTaskNoteProps, IAddTaskNoteState> {
    private fnAddNewTask: (e) => void;
    private fnCancelNewTask: (e) => void;
    private fnAddingNewTask: (e) => void;
    private fnNoteTextChanged: (e) => void;
    constructor(props: IAddTaskNoteProps) {
        super(props);
        this.state = {
            isAdding: false,
            error: null,
            noteText: ""
        };
        this.fnAddNewTask = this.addNewTask.bind(this);
        this.fnAddingNewTask = this.addingNewTask.bind(this);
        this.fnCancelNewTask = this.cancelNewTask.bind(this);
        this.fnNoteTextChanged = this.onNoteTextChanged.bind(this);
    }
    private addNewTask(e) {
        if (!this.state.noteText) {
            alert('Note text missing');
            return;
        }
        const note = createNewNote(this.state.noteText);
        const { onAddNote } = this.props;
        onAddNote(note);
        this.setState({ isAdding: false });
    }
    private addingNewTask(e) {
        this.setState({ isAdding: true });
    }
    private cancelNewTask(e) {
        this.setState({ isAdding: false });
    }
    private onNoteTextChanged(e) {
        this.setState({ isAdding: true, error: null, noteText: e.target.value });
    }
    render(): JSX.Element {
        let body;
        
        if (this.state.isAdding) {
            
            body = 
                (<div className="well">
                    <textarea className="form-control" rows={3} placeholder="Enter your notes" maxLength={250} value={this.state.noteText} onChange={this.fnNoteTextChanged}>
                    </textarea>
                    <br />
                    <div className="btn-group">
                        <button className="btn btn-primary" onClick={this.fnAddNewTask}>{TwuIcons.SAVE} Save</button>
                        <button className="btn btn-danger" onClick={this.fnCancelNewTask}>{Icons.CLOSE} Cancel</button>
                    </div>
                </div>);
        } else {
            body = (
                <div className="well">
                    <button className="btn btn-success" onClick={this.fnAddingNewTask}>{Icons.PLUS} Add Note</button>
                </div>
            );
        }
        
        return body;
    }
}