import * as React from "react";
import { SyncAgent } from "../api/syncagent";
import { getDataManagerAsync } from "../api/data-manager";
import { Icons } from "./icons";
import { ContentSection, ContentSectionDisplayStyle, ErrorDisplay } from "./common";

export enum SyncAction {
    None,
    Push,
    Pull
}

//TODO: Is there a generic way for a parent to listen to a component's state change?
//If so, this interface below is redundant

export interface ISyncProps {
    online: boolean;
    session: string;
}

export interface ISyncState {
    action: SyncAction;
    msg?: string;
    processed?: number;
    total?: number;
    error?: string;
    hasChanges?: boolean;
}

export class SyncPanel extends React.Component<ISyncProps, ISyncState> {
    constructor(props) {
        super(props);
        this.state = {
            action: SyncAction.None,
           
            hasChanges: false
        };
    }
    componentDidMount() {
        this.setState({ action: SyncAction.None });
        getDataManagerAsync().then(manager => {
            return manager.collectChanges();
        }).then(changes => {
            this.setState({ action: SyncAction.None, hasChanges: changes.length > 0 });
        }).catch(err => {
            this.setState({ action: SyncAction.None, error: err });
        });
    }
    componentWillUnmount() {
        
    }
    handlePush(e) {
        this.performPush();
    }
    handlePull(e) {
        this.performPull();
    }
    private performPush(): void {
        this.setState({ action: SyncAction.Push, msg: "Pushing changes" });
        getDataManagerAsync().then(manager => {
            const agent = new SyncAgent(manager);
            agent.push({ token: this.props.session }).then(() => {
                this.setState({
                    action: SyncAction.None,
                    msg: "Push complete",
                    processed: undefined,
                    total: undefined,
                    error: undefined,
                    hasChanges: manager.getPendingChanges().length > 0
                });
            }).catch((err) => {
                this.setState({ action: SyncAction.None, error: err });
            });
        });
    }
    private performPull(): void {
        this.setState({ action: SyncAction.Pull, msg: "Pulling changes" });
        getDataManagerAsync().then(manager => {
            const agent = new SyncAgent(manager);
            agent.pull({ token: this.props.session }).then(() => {
                this.setState({
                    action: SyncAction.None,
                    msg: "Pull complete",
                    processed: undefined,
                    total: undefined,
                    error: undefined
                });
            }).catch((err) => {
                this.setState({ action: SyncAction.None, error: err });
            });
        });
    }/*
    private blowUp(): void {
        throw new Error('BOOM!');
    }*/
    render(): JSX.Element {
        let root;
        let body;
        
        if (this.props.online === false) {
            body = 
                (<div className="alert alert-warning">
                    {Icons.EXCLAMATION} You are currently offline. Sync is not available
                </div>);
        } else {
            let syncLabel: string|null = null;
            let pc = 100;
            if (this.state.processed != null && this.state.total != null) {
                pc = ((this.state.processed / this.state.total) * 100);
                syncLabel = pc.toFixed(2) + "% complete";
            } else {
                syncLabel = "Processing...";
            }
            let pcStyle = {
                width: pc.toFixed(2) + "%"
            };
            if (this.state.action == SyncAction.Push) {
                body = 
                    (<div>
                        <div className="alert alert-info">
                            <p>Pushing changes up{this.state.msg != null ? (": " + this.state.msg) : ""}</p>
                        </div>
                        <div className="progress">
                            <div className="progress-bar progress-bar-striped active" role="progressbar" style={pcStyle}>
                                <span className="sr-only">{syncLabel}</span>
                            </div>
                        </div>
                    </div>);
            } else if (this.state.action == SyncAction.Pull) {
                body = 
                    (<div>
                        <div className="alert alert-info">
                            <p>Pulling down changes: {this.state.msg}</p>
                        </div>
                        <div className="progress">
                            <div className="progress-bar progress-bar-striped active" role="progressbar" style={pcStyle}>
                                <span className="sr-only">{syncLabel}</span>
                            </div>
                        </div>
                    </div>
                );
            } else {
                
                let pull;
                let push;
                
                pull = (<div className="btn-group" role="group">
                            <button type="button" className="btn btn-default" onClick={this.handlePull.bind(this)}>{Icons.DOWNLOAD} Pull Changes</button>
                        </div>);
                            
                if (this.state.hasChanges === true) {
                    push = (<div className="btn-group" role="group">
                                <button type="button" className="btn btn-default" onClick={this.handlePush.bind(this)}>{Icons.UPLOAD} Push Changes</button>
                            </div>);
                }
                
                let err;
                if (this.state.error) {
                    err = <ErrorDisplay error={this.state.error} />;
                }
                
                body = 
                    (<div>
                        <div className="well">
                            <p>{this.state.msg != null ? this.state.msg : "Choose a synchronisation action to perform"}</p>
                        </div>
                        <div>
                            {err}
                        </div>
                        <div className="btn-group btn-group-justified" role="group">
                            {pull}
                            {push}
                        </div>
                    </div>
                );
            }
        }
        
        return <div>
            <ContentSection displayStyle={ContentSectionDisplayStyle.Fieldset} title={this.state.msg != null ? this.state.msg : "Synchronise Changes"}>
                {body}
            </ContentSection>
            {/*<button onClick={() => this.blowUp()}>Break the world</button>*/}
        </div>;
    }
}