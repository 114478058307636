import * as React from "react";
import * as moment from "moment";
import * as DisplayUtils from "../utils/display";
import { Link } from "react-router-dom";
import { ITaskDetails, IUserInfo, TaskStatus } from "../api/twu-contracts";
import { TwuIcons } from "./twu-icons";
import { DateTimePicker, Combobox } from "react-widgets";
import { getAssignableUsersAsync } from "../api/tasks";
import { ModalBody, ModalContent, ModalFooter, ModalHeader, BottomDrawer } from "./modal-common";
import { ReactNode } from 'react';
import { Icons } from "./icons";
import { strIsNullOrEmpty, trim } from "../utils/string";
import { BsLoadSpinner, ContentSection, ContentSectionDisplayStyle } from "./common";

interface ITaskDetailsProps {
    model: ITaskDetails;
    currentUserId: number;
    onTaskStatusChange?: (newStatus: TaskStatus, note: string) => void;
    onReScheduleTask?: (dueOn: string) => void;
    onSaveNew?: (task: ITaskDetails) => void;
    onCancel: () => void;
    /**
     * If model property is set, determines whether to show the assignee as a link to the member details
     */
    linkifyAssignee?: boolean;
    viewOnly?: boolean;
    enableActions: boolean;
}

interface ITaskDetailsState {
    model: ITaskDetails;
    assignableUsers: { id: number | string, name: string }[];
    statusChanging: boolean;
    statusChangeNotePrompt: boolean;
    statusChangeNotes: string;
    newStatus: TaskStatus;
    originalDueOn: string;
    localDueOn: string;
}

function convertUserInfo(u: IUserInfo) {
    if (u.sub) {
        return {
            id: u.sub,
            name: u.fullName
        };
    } else {
        return {
            id: u.userId,
            name: `${u.fullName} (${u.username})`
        };
    }
}

export class TaskDetails extends React.Component<ITaskDetailsProps, Partial<ITaskDetailsState>> {
    private isComponentMounted: boolean;
    constructor(props: ITaskDetailsProps) {
        super(props);
        this.isComponentMounted = false;
        this.state = {
            model: props.model,
            statusChangeNotePrompt: false,
            statusChanging: false,
            originalDueOn: props.model.dueOn,
            localDueOn: props.model.dueOn
        };
    }
    private onBeginTransition = (e) => {
        e.preventDefault();
        this.setState({ statusChanging: true });
        const { onTaskStatusChange } = this.props;
        const { newStatus, statusChangeNotes } = this.state;
        const n = (statusChangeNotes || "").trim();
        onTaskStatusChange && newStatus && onTaskStatusChange(newStatus, n);
        this.setState({ statusChanging: false,
             statusChangeNotePrompt: false, 
            newStatus: undefined });
        return false;
    }
    private onCancelTransition = (e) => {
        e.preventDefault();
        this.setState({ statusChanging: false,
             statusChangeNotePrompt: false,
             newStatus: undefined });
        return false;
    }
    private onStartProgress = (e) => {
        e.preventDefault();
        this.setState({ statusChangeNotePrompt: true, newStatus: TaskStatus.InProgress });
        return false;
    }
    private onChangeTaskDueDate = (e) => {
        e.preventDefault();
        if (confirm("Are you sure you want to change the due date of this task?")) {
            const { onReScheduleTask } = this.props;
            if (onReScheduleTask) {
                onReScheduleTask(this.state.localDueOn!);
            }
        }
    }
    private onComplete = (e) => {
        e.preventDefault();
        this.setState({ statusChangeNotePrompt: true, newStatus: TaskStatus.Complete });
        return false;
    }
    private onSave = (e) => {
        e.preventDefault();
        if (!this.state.model) return false;

        const descT = trim(this.state.model.description ?? "");
        if (strIsNullOrEmpty(descT)) {
            alert("Please provide a description for this task");
            return false;
        }

        const task: ITaskDetails = { ...this.state.model, dueOn: this.state.localDueOn! };
        this.props.onSaveNew && this.props.onSaveNew(task);
        return false;
    }
    private onCancel = (e) => {
        e.preventDefault();
        this.props.onCancel();
        return false;
    }
    private onDueDateChanged = (e) => {
        const dueOn = moment.utc(e).format(DisplayUtils.DATE_FORMAT);
        this.setState({ localDueOn: dueOn });
    }
    private onTitleChanged = (e) => {
        const model = this.state.model;
        model!.title = e.target.value;
        this.setState({ model: model });
    }
    private onDescriptionChanged = (e) => {
        const model = this.state.model;
        model!.description = e.target.value;
        this.setState({ model: model });
    }
    private onAssignedUserChanged = (value: { id: number, name: string }) => {
        const model = this.state.model;
        model!.assignedToUser = value.id;
        this.setState({ model: model });
    }
    private onStatusNotesChanged = (e) => {
        this.setState({ statusChangeNotes: e.target.value });
    }
    private getAvailableEditActions(): ReactNode[] {
        const actions: ReactNode[] = [];
        const { model } = this.state;
        if (!model) return [];

        if (model.status != TaskStatus.Complete) {
            if (model.status != TaskStatus.InProgress) {
                actions.push((<button key="taskstart" className="btn btn-primary" onClick={this.onStartProgress}>{TwuIcons.PLAY} Start Progress</button>));
            }
            actions.push((<button key="taskreschedule" className="btn btn-primary" disabled={this.state.originalDueOn == this.state.localDueOn} onClick={this.onChangeTaskDueDate}>{Icons.CLOCK} Change due date</button>));
            actions.push((<button key="taskcomplete" className="btn btn-primary" onClick={this.onComplete}>{Icons.TICK} Complete</button>));
        }
        return actions;
    }
    componentDidMount() {
        this.isComponentMounted = true;
        getAssignableUsersAsync().then(users => {
            if (this.isComponentMounted) {
                this.setState({
                    assignableUsers: (users || []).map(u => convertUserInfo(u))
                });
            }
        });
    }
    componentWillUnmount() {
        this.isComponentMounted = false;
    }
    render(): JSX.Element {
        let body;
        let notAssignedToMe: ReactNode = null;
        let syncpending: ReactNode = null;
        let actions: ReactNode = null;
        let title = "Task Details";

        const { enableActions } = this.props;
        const { model, statusChangeNotePrompt, statusChangeNotes, newStatus, statusChanging, localDueOn } = this.state;


        if (!model) return <>Loading...</>;

        if (model.id != null) {
            actions = [];
            let buttons: ReactNode[] = [];
            if (model.serverId == null) {
                syncpending = (<div className="alert alert-warning">{Icons.INFO} This task has not been synced yet.</div>);
            }
            if (model.assignedToUser != null && model.assignedToUser != this.props.currentUserId) {
                notAssignedToMe = <div className="alert alert-info">{Icons.INFO} You created this task, but it is not assigned to you</div>;
            }

            if (enableActions) {
                let statuses = this.getAvailableEditActions();
                if (statuses && statuses.length > 0) {
                    for (const stat of statuses) {
                        if (stat) buttons.push(stat);
                    }
                }
                
                buttons.push((<button key="taskcancel" className="btn btn-danger" onClick={this.onCancel}>{Icons.CLOSE} Cancel</button>));
                actions = 
                    (<div className="btn-group">
                        {buttons}
                    </div>);
            }
        } else {
            title = "New Task";
            actions = 
                (<div className="btn-group">
                    <button className="btn btn-primary" onClick={this.onSave}>{TwuIcons.SAVE} Save</button>
                    <button className="btn btn-danger" onClick={this.onCancel}>{Icons.CLOSE} Cancel</button>
                </div>);
        }

        let memberInfo;
        if (this.props.linkifyAssignee) {
            memberInfo = (<Link to={`/member/${model.assignedToMember.id}`}>{Icons.USER} {model.assignedToMember.firstName + " " + model.assignedToMember.lastName}</Link>);
        } else {
            memberInfo = (<p>{Icons.USER} {model.assignedToMember.firstName + " " + model.assignedToMember.lastName}</p>);
        }
        let dueOn;
        if (localDueOn) {
            dueOn = DisplayUtils.getMomentUtc(localDueOn).local();
            if (dueOn == null) {
                dueOn = moment.utc().local();
            }
        } else {
            dueOn = moment.utc().local();
        }
        const dateFmt = "dddd, MMMM Do YYYY";
        const timeFmt = "h:mm:ss a";
        
        //HACK: Workaround dropUp not being in d.ts
        const dtProps: any = {
            format: `${dateFmt} ${timeFmt}`,
            value: dueOn.toDate(),
            onChange: this.onDueDateChanged,
            time: true,
            dropUp: true,
            readOnlyField: false
        };
        if (this.props.viewOnly) {
            dtProps.readOnly = !enableActions;
        }
        body = (
            <ContentSection title={title} displayStyle={ContentSectionDisplayStyle.Fieldset} iconKind="icon" iconClass="ok">
                {notAssignedToMe}
                {syncpending}
                <form>
                    <div className="form-group">
                        <label htmlFor="title">Title</label>
                        <input type="text" className="form-control" id="title" placeholder="Task Name" value={model.title} onChange={this.onTitleChanged} readOnly />
                    </div>
                    <div className="form-group">
                        <label htmlFor="description">Description</label>
                        <textarea className="form-control" id="description" placeholder="Task Description" value={model.description} onChange={this.onDescriptionChanged} readOnly={this.props.viewOnly} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="status">Status:</label>
                        <div id="status" className="form-control">
                            <p>{TaskStatus[model.status]}</p>
                        </div>
                    </div>
                    {(() => {
                        if (this.state.assignableUsers) {
                            if (this.state.assignableUsers.length > 0) {
                                return <div className="form-group">
                                    <label htmlFor="assignedUser">Assign to:</label>
                                    <div id="assignedUser">
                                        <Combobox readOnly={!!this.props.viewOnly} value={model.assignedToUser} data={this.state.assignableUsers} textField="name" valueField="id" onChange={this.onAssignedUserChanged} />
                                    </div>
                                </div>;
                            } else {
                                return <div className="alert alert-info">There are no users to assign this task to. Please make sure to pull down the latest data from the <Link to="/sync">Synchronise Data screen</Link></div>;
                            }
                        } else {
                            return <BsLoadSpinner message="Checking available assignees" />;
                        }
                    })()}
                    <div className="form-group">
                        <label htmlFor="assigned">Relates to:</label>
                        <div id="assigned" className="form-control">
                            {memberInfo}
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="due">Due On:</label>
                        <DateTimePicker {...dtProps} />
                    </div>
                    <div className="form-actions">
                        {actions}
                    </div>
                </form>
                <BottomDrawer open={statusChangeNotePrompt}>
                    <ModalContent>
                        <ModalHeader>Task Status Change</ModalHeader>
                        <ModalBody>
                            <div className="form-group">
                                <label className="control-label">
                                    <p>You are about to transition this task to: {TaskStatus[newStatus!]}.</p>
                                    <p>Is there any notes you'd like to add before proceeding?</p>
                                </label>
                                <textarea className="form-control" disabled={statusChanging} rows={3} placeholder="Notes to add" value={statusChangeNotes || ""} onChange={this.onStatusNotesChanged} />
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <button type="button" className="btn btn-success" onClick={this.onBeginTransition} disabled={statusChanging}>{TwuIcons.PLAY} Proceed</button>
                            <button type="button" className="btn btn-danger" onClick={this.onCancelTransition} disabled={statusChanging}>{Icons.CLOSE} Cancel</button>
                        </ModalFooter>
                    </ModalContent>
                </BottomDrawer>
            </ContentSection>
        );
        return body;
    }
}